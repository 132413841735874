import React, { Component } from "react";
import { Grid, Typography, Button } from "@material-ui/core";
import { ReactComponent as Gmail } from "./../assets/images/Gmail.svg";
import { ReactComponent as Outlook } from "./../assets/images/Outlook.svg";
import Link from "@mui/material/Link";
import { withStyles } from "@material-ui/core/styles";
import i18n from "./../locale/index";
//import MenuLanguageComponent from "../components/MenuLanguage";
import { Box } from "@mui/material";

import Cookies from "universal-cookie";
import { VerificationForm } from "../components/VerificationForm";


const IMAGE_LOGIN_PATH = "login_bg.png";

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    height: "100vh",
    backgroundColor: "#FFFFFF",
  },
  grid1: {
    backgroundColor: "#FFFFFF",
    alignContent: "center",
  },
  googleLogin: {
    marginBottom: 10,
    alignContent: "center",
  },
  textVerification: {
    marginBottom: 20,
    alignContent: "center",
  },
  alignMails: {
    justifyContent: "center",
    marginTop: theme.spacing(1),
    marginBottom: 10,
    alignContent: "center",
  },
  registerColorFont: {
    fontFamily: "Work Sans, sans-serif",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "18.77px",
    alignItems: "center",
    textAlign: "left",
    color: "#00A490",
  },
  mailColor: {
    marginLeft: theme.spacing(2),
    fontFamily: "Work Sans, sans-serif",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "14px",
    lineHeight: "18.77px",
    alignItems: "center",
    textAlign: "left",
    color: "#131313",
  },
  BaseGrid: {
    margin: 10,
  },
  topdiv: {
    position: "absolute",
    top: 20,
    right: 30,
  },
  recoverTex: {
    textAlign: "left",
    color: "#131313",
    fontFamily: "Work Sans, sans-serif",
    fontSize: "32px",
    fontWeight: 600,
  },
  labelText: {
    color: "#131313",
    fontFamily: "Work Sans, sans-serif",
    fontSize: "15px",
    fontWeight: 400,
    lineHeight: 1.5,
    textAlign: "left",
  },

  labelText3: {
    color: "#131313",
    fontFamily: "Work Sans, sans-serif",
    fontSize: "14px",
    fontWeight: 600,
    lineHeight: "18px",
    textAlign: "left",
  },
  labels: {
    color: "#131313",
    fontFamily: "Work Sans, sans-serif",
    fontSize: "15px",
  },
  button: {
    margin: "10px",
    color: "#131313",
    fontFamily: "Work Sans, sans-serif",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "18px",
    textAlign: "left",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "white",
      color: "#00A490",
    },
  },
  button2: {
    margin: 0,
    fontFamily: "Work Sans, sans-serif",
    fontSize: "14px",
    fontWeight: 600,
    lineHeight: 1.2,
    textAlign: "left",
    textTransform: "none",
    alignItems: "center",
    color: "#007868 !important",
    "&:hover": {
      backgroundColor: "white",
      textDecoration: "underline",
    },
  },
  wrapLabel: {
    verticalAlign: "middle",
    display: "flex",
  },
  backgroundImg: {
    objectFit: "cover",
    zIndex: 0,
    width: "100%",
    height: "100%",
    overFlowY: "hidden",
  },
  imgContainer: {
    // position: "fixed",
    top: 0,
    right: 0,
    height: "100vh",
  },
});

class MailVerificationView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      windowWidth: window.innerWidth,
      completed: false,
      //cambia mensaje ingresando desde el banner
      banner: false,
      //cambia mensaje de bloqueo pasadas 24 hs
      blocked: false,
      //Obitiene el corrreo de la cookie
      correo: "",
    };
  }

  desencripJwt = (jwt) => {
    let base64Url = jwt.split(".")[1];
    let base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    let jsonPayload = decodeURIComponent(
      atob(base64)
        .split("")
        .map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );
    return jsonPayload;
  };

  compareDate = (now, exp) => {
    return now > new Date(exp * 1000);
  };

  handleResize = (e) => {
    this.setState({ windowWidth: window.innerWidth });
  };
  sendCode = (bearerJWT) => {
    fetch(process.env.REACT_APP_RESEND_CODE, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${bearerJWT}`,
      },
    }).catch((err) => {
      console.log(err);
    });
  };
  componentDidMount() {
    const cookies = new Cookies();
    const jwtCookie = cookies.get("Secure-1CBL");
    localStorage.setItem("jwt",jwtCookie)
    let jsonParse = JSON.parse(this.desencripJwt(jwtCookie));
    this.setState({ correo:localStorage.getItem("email") });
    const expired = this.compareDate(
      Date.now(),
      jsonParse.data.verification_exp
    );
    if (expired === true && jsonParse.data.verified === false) {
      this.setState({ blocked: true });
    }
    this.sendCode(jwtCookie);
    window.addEventListener("resize", this.handleResize);
    console.clear();
  }

  render() {
    function getWindowDimensions() {
      const { innerWidth: width } = window;
      return {
        width,
      };
    }

    const { classes } = this.props;
    const { width } = getWindowDimensions();

    return (
      <div style={{ backgroundColor: "#E5E5E5" }}>
        <Grid
          container
          className={classes.root}
          component="main"
          maxWidth="sm"
          justifyContent="center"
          alignContent="middle"
        >
          <Grid
            container
            item
            className={classes.grid1}
            xs={width >= 600 ? 6 : 12}
            md={width >= 600 ? 6 : 12}
            lg={width >= 600 ? 6 : 12}
            maxWidth="md"
          >
            <Grid
              container
              item
              xs={12}
              md={12}
              lg={12}
              justifyContent="center"
              mb={1}
            >
              <Grid className={classes.googleLogin}>
                <Box
                  sx={{
                    width: "90vw",
                    maxWidth: "380px",
                    margin: "0",
                    "@media (min-width: 780px)": {
                      maxWidth: "380px",
                    },
                  }}
                >
                  <Typography className={classes.recoverTex}>
                    {i18n.t("emailVerification")}
                  </Typography>
                </Box>
              </Grid>
            </Grid>
            <Grid
              container
              xs={12}
              md={12}
              lg={12}
              mb={1}
              justifyContent="center"
              direction="row"
              className={classes.textVerification}
            >
              {this.state.blocked ? (
                <Box
                  sx={{
                    width: "90vw",
                    maxWidth: "380px",
                    margin: "0",
                    "@media (min-width: 780px)": {
                      maxWidth: "380px",
                    },
                  }}
                >
                  <Typography
                    className={classes.labelText}
                    xs={width >= 600 ? 6 : 12}
                    md={width >= 600 ? 6 : 12}
                    lg={width >= 600 ? 6 : 12}
                    maxWidth="md"
                  >
                    {i18n.t("disableAccount")}{" "}
                    <span style={{ fontWeight: "bold" }}>
                      {this.state.correo}
                      {". "}
                    </span>
                    {i18n.t("pleaseEnterCode")}
                  </Typography>
                </Box>
              ) : (
                <Box
                  sx={{
                    width: "90vw",
                    maxWidth: "380px",
                    margin: "0",
                    "@media (min-width: 780px)": {
                      maxWidth: "380px",
                    },
                  }}
                >
                  <Typography
                    className={classes.labelText}
                    xs={width >= 600 ? 6 : 12}
                    md={width >= 600 ? 6 : 12}
                    lg={width >= 600 ? 6 : 12}
                    maxWidth="md"
                  >
                    {i18n.t("subEmailVerification")}{" "}
                    <span style={{ fontWeight: "bold" }}>
                      {this.state.correo}
                    </span>{" "}
                    {i18n.t("subEmailVerification2")}
                  </Typography>
                </Box>
              )}
            </Grid>

            <VerificationForm />
            {this.state.blocked ? null : (
              <Grid container item justifyContent="center">
                <Button href={process.env.REACT_APP_LINK} className={classes.button}>
                  {" "}
                  {i18n.t("enterLater")}{" "}
                </Button>
              </Grid>
            )}

            <Grid
              container
              item
              className={classes.alignMails}
              direction="row"
              spacing={2}
              sx={{
                width: "90vw",
                maxWidth: "320px",
                margin: "0",
                "@media (min-width: 780px)": {
                  maxWidth: "380px",
                },
              }}
            >
              <Grid item>
                <Link
                  underline="none"
                  href="https://accounts.google.com/signin"
                  target="_blank"
                  className={classes.openMail}
                >
                  <Grid
                    container
                    item
                    direction="row"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Gmail />
                    <Typography className={classes.mailColor}>
                      {i18n.t("openGmail")}
                    </Typography>
                  </Grid>
                </Link>
              </Grid>
              <Grid item>
                <Link
                  underline="none"
                  href="https://login.live.com/"
                  target="_blank"
                  className={classes.openMail}
                >
                  <Grid container item direction="row" alignItems="center">
                    <Outlook />
                    <Typography className={classes.mailColor}>
                      {i18n.t("openOutlook")}
                    </Typography>
                  </Grid>
                </Link>
              </Grid>
            </Grid>
            <Grid container item justifyContent="center" direction="row">
              {this.state.banner ? (
                <Grid
                  container
                  item
                  xs={12}
                  md={12}
                  lg={12}
                  justifyContent="center"
                  mb={1}
                  className={classes.wrapLabel}
                >
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <Typography className={classes.labelText} maxWidth="md">
                      {i18n.t("incorrectEmail")}
                    </Typography>
                    <Button
                      href={process.env.REACT_APP_CONFIRM_EMAIL}
                      className={classes.button2}
                    >
                      {" "}
                      {i18n.t("changeEmail")}{" "}
                    </Button>
                  </div>
                </Grid>
              ) : (
                <Box
                  sx={{
                    width: "90vw",
                    maxWidth: "380px",
                    margin: "0",
                    "@media (min-width: 780px)": {
                      maxWidth: "380px",
                    },
                  }}
                >
                  <Typography className={classes.labelText} maxWidth="md">
                    {i18n.t("notReceive")}
                    <Link
                      underline="none"
                      href={process.env.REACT_APP_CONFIRM_EMAIL}
                      className={classes.button2}
                    >
                      {" "}
                      {i18n.t("enterNewEmail")}
                    </Link>{" "}
                    {i18n.t("notReceive2")}
                  </Typography>
                </Box>
              )}
            </Grid>
          </Grid>
          {width >= 600 ? (
            <Grid
              container
              item
              xs={6}
              md={6}
              lg={6}
              className={classes.imgContainer}
            >
              <img
                src={process.env.REACT_APP_BASE_URL_IMAGES+ IMAGE_LOGIN_PATH}
                alt="computer_man"
                className={classes.backgroundImg}
              />

              <div className={classes.topdiv}>
                {/*<MenuLanguageComponent />*/}
              </div>
            </Grid>
          ) : (
            <Grid
              container
              item
              xs={12}
              md={12}
              lg={12}
              justifyContent="center"
            >
              {/*<MenuLanguageComponent />*/}
            </Grid>
          )}
        </Grid>
      </div>
    );
  }
}
export default withStyles(styles, { withTheme: true })(MailVerificationView);
