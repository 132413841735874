import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
/* import { Auth0Provider } from "@auth0/auth0-react"; */
/* import reportWebVitals from './reportWebVitals'; */

window.renderLogin = (containerId, history) => {
  ReactDOM.render(
    <App history={history} />,
    document.getElementById(containerId)
  );
};

window.unmountLogin = (containerId) => {
  ReactDOM.unmountComponentAtNode(document.getElementById(containerId));
};
if (!document.getElementById('login-container')) {
  ReactDOM.render(<App />, document.getElementById('root'));
}

