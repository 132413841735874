import amplitude from 'amplitude-js';

export const initAmplitude = () => {
  amplitude.getInstance().init("3fd99627cc179983dc296b7c86c0c182");
};

export const setAmplitudeUserDevice = installationToken => {
  amplitude.getInstance().setDeviceId(installationToken);
};

export const setAmplitudeUserId = userId => {
  amplitude.getInstance().setUserId(userId);
};

export const setAmplitudeUserProperties = properties => {
  amplitude.getInstance().setUserProperties(properties);
};

export const sendAmplitudeData = (eventType, eventProperties = null) => {
  if (!eventProperties) {
    amplitude.getInstance().logEvent(eventType);
  } else {
    amplitude.getInstance().logEvent(eventType, eventProperties);
  }
};

export const setAmplitudeIdentify = (properties) => {
  var identify = new amplitude.Identify().set('properties', properties);
  amplitude.getInstance().identify(identify);
};