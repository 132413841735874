export const eng = {
  textBtnGoogle:"Sign in with Google",
  textBtnGoogle2:"Sign in with Google",
  useEmail:" Use your email to enter",
  useEmail2:" Or use your email to enter",
  remember:"Remember",
  forgot:"I forgot my password",
  into:"Access",
  account:"Don't have an account?",
  register:"Register",
  startNow:"Start now",
  accountNow:"You already have an account?",
  intoNow:"Get into",
  iaccept:"I accept the",
  terms:"Terms and Conditions",
  reason:"Name or Business name *",
  pass:"Password",
  pass2:"Repeat password",
  phone:"Phone *",
  //forfriends:"For a friend?",
  //bysocial:"By social networks?",
  meetUs:"How did you meet us?",
  recoverText:"Enter the email you are using for your account.",
  recoverPass:"Recover Password",
  continue:"Continue",
  goBack:"Back to Login",
  recoverTextMail:"Enter the link to create a new password. If you didn't receive it, please check your spam box.",
  sendMailRecover:"An email was sent to the address entered.",
  createNewPass:"Write a unique and strong password that is easy ",
  createNewPass2:"to remember so you won't forget it next time.",
  newPass:"New password *",
  chancePass:"Change Password",
  newChancePass:"Create new password",
  characters:"Min 8 characters",
  oneCapital:"One capital letter",
  oneletter:"1 lowercase letter",
  complete:"Password change completed successfully.",
  goBackLogin1:"Now you can go back to the login and enter with your email and your new password.",
  expiredText:"The link is no longer valid, please recover your password again",
  expiredText2:"Remember that the link is valid for 24 hours.", 
  registerfree:"Register for free", 
  testforfree:"Try it for 10 days free of charge!",
  discountcode:"I have a discount code",
  registerG1: "Register with Google",
  registerG2: "We welcome you!",
  registerG3: "A new Contabilium account will be created",
  registerG4: "for email address",
  emailVerification: "Email verification",
  subEmailVerification: "We have sent a verification code to the account ",
  subEmailVerification2: "which we request below", 
  codeLabel: "Didn't receive the code? Please", 
  wait: "wait",
  resendNow: "Resend now",
  seconds: "seconds",
  enterLater: "I'll enter it later, let me continue", 
  notReceive: "Didn't get the email? Check your spam folder or ",
  enterNewEmail: "enter a new email",
  notReceive2: " and try again",
  confirm: "Confirm",
  openGmail: "Open Gmail",
  openOutlook: "Open Outlook",
  google:"Google",
  friends:"Friends",
  recomendation:"Recomendation",
  socialm:"Social medias",
  event:"Events",
  other:"Others", 
  code:"Discount code",
  activity:"Work activity",
  wrongUserPassword: "Wrong user or password",
  blockedUser: "Blocked user",
  errorMessage: "Please enter a valid email address",
  numberCh:"Min. 1 number character", 
  emaiCh:"Enter a valid email",
  matchPass:"Passwords do not match", 
  plusCh:"It is not allowed to include the character “+”", 
  reqField:"Required field",
  errorTextCode: "Please enter the 6 digit code",
  validTextCode: "The code entered is valid",
  confirmMail: "Email modification", 
  goBackMail: "Back to Email Verification",
  termin:"You must accept the terms and conditions", 
  successAuth: "Your email has been successfully verified.",
  enterCbl: "In a few seconds you'll enter Contabilium automatically.",
  errPass:"Wrong username or password",
  checkLink:"Please wait while we validate the link. Please do not close this window, it will automatically redirect", 
  confirmPass:"Confirm new password *",
  btnErr:"Complete all required fields", 
  err:"Error message",
  incorrectEmail: "Email isn't right?",
  changeEmail: "Change it from here",
  disableAccount: "Your account has been temporarily disabled because you have not yet entered the verification code we sent to",
  pleaseEnterCode: "Please enter the code to re-enable your account",
  errCodeApi: "You entered an incorrect or invalid code", 
  errCode: "Please enter 6 digit code", 
  enterNewEmailLabel: "Enter a new email for your account, where you will receive the verification code"
}