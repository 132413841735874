import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import { makeStyles } from "@material-ui/core/styles";
import i18n from "./../locale/index";
import {
  InputAdornment,
  Typography,
  Button,
  CircularProgress
} from "@material-ui/core";
import { Grid } from "@material-ui/core";
import { ReactComponent as Vector } from "./../assets/images/Vector.svg";
import ReactTooltip from "react-tooltip";
import { ReactComponent as VectorEye } from "./../assets/images/VectorEye.svg";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { ReactComponent as VectorPlus } from "./../assets/images/VectorPlus.svg";
import { ReactComponent as VectorCheck } from "./../assets/images/VectorCheck.svg";
//import { ChangePassword } from "./../configHost";
import InputBase from "@mui/material/InputBase";
import Dialog from "@material-ui/core/Dialog";

const useStyles = makeStyles((theme) => ({
  formButton: {
    color: "#131313",
    fontSize: "16px",
    fontWeight: 400,
    textAlign: "left",
    border: "1px solid rgba(19, 19, 19, 0.3)",
    borderRadius: 3,
    height: 45,
    padding: "5px 16px",
    fontFamily: "Work Sans, sans-serif",
  },
  input: {
    height: 45,
  },
  dropdownPhone: {
    width: "320px !important",
    "@media (min-width: 780px)": {
      width: "380px",
    },
  },
  registerText: {
    fontSize: "11px",
    fontFamily: "Work Sans, sans-serif",
  },
  icons: {
    width: "15px",
  },
  validationTextGreen: {
    color: "#00a490",
  },
  validationTextRed: {
    color: "#ff2569",
  },
  validationText: {
    color: "#ff2569",
    fontSize: "11px",
    marginTop: "3px",
    fontFamily: "Work Sans, sans-serif",
  },
  validationText2: {
    color: "#ff2569",
    fontSize: "11px",
    marginTop: "15px",
    fontFamily: "Work Sans, sans-serif",
  },
  errorText: {
    height: 45,
    border: "1px solid #ff2569",
    borderRadius: 1,
  },
  registerColorFont: {
    fontFamily: "Work Sans, sans-serif",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "18.77px",
    color: "#00A490",
    fontFamily: "Work Sans, sans-serif",
  },
  registerIcon: {
    color: "#00A490",
  },

  MuiCollapseRoot: {
    transitionDuration: "0",
  },
  MuiButtonText: {
    padding: "0",
  },
  formCheck: {
    fontFamily: "Work Sans, sans-serif",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "18.77px",
    alignItems: "center",
    textAlign: "center",
    color: "#131313",
  },
  registerColorFont2: {
    fontFamily: "Work Sans, sans-serif",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "18.77px",
    alignItems: "center",
    textAlign: "center",
    color: "#00A490",
    textDecoration: "none",
    marginLeft: 5,
    letterSpacing: "normal",
  },
  labelAlign: {
    alignSelf: "center",
    alignContent: "center",
    fontFamily: "Work Sans, sans-serif",
  },
  formGroupTerm: {
    marginRight: 0,
  },
  discountComponent: {
    marginTop: 5,
  },
  loginBtn: {
    marginTop: theme.spacing(1),
    height: 50,
    width: 320,
    textTransform: "none",
    border: "2px solid",
    borderColor: "#00A692",
    backgroundColor: "#00A692",
    borderRadius: 50,
    color: "#fff",
    autoComplete: "off",
    fontFamily: "Work Sans, sans-serif",
    fontWeight: "normal",
    fontSize: "16px/22px",
    "&:hover": {
      backgroundColor: "#007868",
      borderColor: "#007868",
      color: "#fff",
    },
    "@media (min-width: 780px)": {
      width: 380,
    },
  },
  loginBtn2: {
    marginTop: theme.spacing(1),
    height: 50,
    width: 320,
    textTransform: "none",
    border: "2px solid",
    borderColor: "#C6C6C6",
    backgroundColor: "#C6C6C6",
    borderRadius: 50,
    color: " #131313",
    autoComplete: "off",
    fontFamily: "Work Sans, sans-serif",
    fontWeight: "normal",
    fontSize: "16px/22px",
    /*  font: "normal normal normal 16px/22px", */
    letterSpacing: 0,

    "&:hover": {
      backgroundColor: "#007868",
      borderColor: "#007868",
      color: "#fff",
    },
    "@media (min-width: 780px)": {
      width: 380,
    },
  },
  containerLoginBtn: {
    marginTop: "20px!important",
  },
  marginText: {
    marginBottom: "5px",
  },
  btnText: {
    fontFamily: "Work Sans, sans-serif",
  },
}));

// Toast
const Alert = React.forwardRef(function Alert(props, ref) {
  return (
    <MuiAlert
      elevation={6}
      ref={ref}
      variant="filled"
      {...props}
      className="containerLoginBtn"
    />
  );
});

function NewPassForm() {
  const [pass, setPass] = React.useState("");
  const [eye, setEye] = useState(false);

  //Tooltip validation password
  const [eightCh, setEightCh] = useState(false);
  const [oneCapital, setOneCapital] = useState(false);
  const [numberCh, setnumberCh] = useState(false);
  const [plusCh, setPlusCh] = useState(false);
  const [passValnumber, setPassValnumber] = useState(false);
  const [oneCapitalVal, setOneCapitalVal] = useState(false);
  const [passnumberChVal, setNumberChVal] = useState(false);
  const [plusChVal, setPlusChVal] = useState(false);
  const [oneLowerCase, setOneLowercase] = useState(false);
  const [oneLowerCaseVal, setOneLowercaseVal] = useState(false);
  const [passEmpty, setPassEmpty] = useState(false);

  // validation match passwords
  const [matchPassVal, setMatchPassVal] = useState(false);
  const [matchPassEmpty, setMatchPassEmpty] = useState(false);
  const [matchPass, setMatchPass] = React.useState("");

  //btn register
  const [enabledBtn, setEnabledBtn] = useState(false);
  const [btnMatchPassVal, setBtnMatchPassVal] = useState(false);
  const [btnMatchPassEmpty, setBtnMatchPassEmpty] = useState(false);
  const [btnPlusCh, setBtnPlusCh] = useState(false);
  const [btnPassEmpty, setBtnPassEmpty] = useState(false);
  const [loading, setloading] = useState(false);
  const [errText, setErrText] = useState(false);

  // Toast
  const [open, setOpen] = useState(false);

  const classes = useStyles();

  // Password validation
  const handleValidatePassword = (event) => {
    setPass(event.target.value);
    if (event.target.value.length >= 8) {
      setEightCh(true);
      setPassValnumber(false);
    } else {
      setEightCh(false);
      setPassValnumber(true);
    }
    if (/[A-Z-Ñ]/.test(event.target.value)) {
      setOneCapital(true);
      setOneCapitalVal(false);
    } else {
      setOneCapital(false);
      setOneCapitalVal(true);
    }
    if (/[0-9]/.test(event.target.value)) {
      setnumberCh(true);
      setNumberChVal(false);
    } else {
      setnumberCh(false);
      setNumberChVal(true);
    }
    if (/[+]/.test(event.target.value)) {
      setPlusCh(true);
      setPlusChVal(true);
      setBtnPlusCh(false);
    } else {
      setPlusCh(false);
      setPlusChVal(false);
      setBtnPlusCh(true);
    }
    if (/[a-z-ñ]/.test(event.target.value)) {
      setOneLowercase(true);
      setOneLowercaseVal(false);
    } else {
      setOneLowercase(false);
      setOneLowercaseVal(true);
    }
    if (pass === "") {
      setPassEmpty(true);
      setBtnPassEmpty(false);
    } else {
      setPassEmpty(false);
      setBtnPassEmpty(true);
    }
  };

  // Password match validation
  const handlePasswordsMatch = (e) => {
    setMatchPass(e.target.value);

    if (matchPass === "") {
      setMatchPassEmpty(true);
      setBtnMatchPassEmpty(false);
    } else {
      setMatchPassEmpty(false);
      setBtnMatchPassEmpty(true);
    }
    localStorage.setItem("password", e.target.value);
  };

  // Toast
  const handleClickToast = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  useEffect(() => {
    if (pass !== matchPass) {
      setMatchPassVal(true);
      setBtnMatchPassVal(false);
    } else {
      setMatchPassVal(false);
      setBtnMatchPassVal(true);
    }
    if (
      eightCh === true &&
      oneCapital === true &&
      oneLowerCase === true &&
      numberCh === true &&
      passEmpty === false &&
      btnMatchPassVal === true &&
      btnMatchPassEmpty === true &&
      btnPlusCh === true &&
      btnPassEmpty === true
    ) {
      setEnabledBtn(true);
    } else {
      setEnabledBtn(false);
    }
  }, [
    passValnumber,
    oneCapitalVal,
    passnumberChVal,
    btnPlusCh,
    oneLowerCaseVal,
    btnPassEmpty,
    btnMatchPassVal,
    eightCh,
    oneCapital,
    oneLowerCase,
    numberCh,
    passEmpty,
    btnMatchPassEmpty,
    pass,
    matchPass,
  ]);

  //Country configuration
  const handleCountry = (country) => {
    let countrySend = "";
    switch (country) {
      case "Argentina":
        countrySend = "ar";
        break;
      case "Chile":
        countrySend = "cl";
        break;
      case "Uruguay":
        countrySend = "uy";
        break;
      default:
        countrySend = "ar";
        break;
    }
    return countrySend;
  };

  const handleLogin = (e) => {
    setloading(true);
    fetch(process.env.REACT_APP_CHANGEPASSWORD, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        user_id: parseInt(localStorage.getItem("user_id")),
        email: localStorage.getItem("emailPass"),
        country: handleCountry(localStorage.getItem("countryPass")),
        password: pass,
        secret: localStorage.getItem("secret"),
      }),
    })
      .then((response) => {
        if (response !== "") {
          return response.json();
        } else {
          return null;
        }
      })
      .then((response) => {
        if (response.error) {
          if (response.error === "secret_invalid") {
            setloading(false);
            handleClickToast();
          }
          if (response.error === "The password must be different from the old one") {
            setloading(false);
            setErrText(true);
          }
        }
        if (response.code === "password_changed") {
          setloading(false);
          window.open(process.env.REACT_APP_CCOMPLETE_PASS, "_self");
        }
      });
  };
    //Enter

    const handleEnter = (event)=>{
      if(event.key === 'Enter'){
        handleLogin()
      }
    }
  return (
    <Box
      component="form"
      sx={{
        "& .MuiGrid-item": {
          mt: 1.5,
          width: "90vw",
          maxWidth: "320px",
          "@media (min-width: 780px)": {
            maxWidth: "380px",
          },
        },
      }}
    >
      <Grid container item xs={12} md={12} lg={12} /*Pass 1*/>
        <InputBase
          placeholder={i18n.t("newPass")}
          fullWidth
          type={eye === false ? "password" : "text"}
          id="password"
          name="name"
          autocomplete="off"
          data-tip
          data-for="registerTip"
          className={classes.formButton}
          onChange={handleValidatePassword}
          onFocus={handleValidatePassword}
          onBlur={handleValidatePassword}
          inputProps={{
            maxLength: 255,
          }}
          sx={
            passValnumber === true ||
            oneCapitalVal === true ||
            passnumberChVal === true ||
            plusChVal === true ||
            oneLowerCaseVal === true ||
            matchPassEmpty === true
              ? { border: "1px solid #ff2569", borderRadius: 1 }
              : { className: classes.input2 }
          }
          endAdornment={
            <InputAdornment position="end" style={{ cursor: "pointer" }}>
              {eye === false ? (
                <VectorEye onClick={(e) => setEye(true)} />
              ) : (
                <Vector onClick={(e) => setEye(false)} />
              )}
            </InputAdornment>
          }
        />
        <ReactTooltip
          id="registerTip"
          place="right"
          effect="solid"
          backgroundColor="#FFFFFF"
          border="true"
          borderColor="#C6C6C6"
        >
          <Box
            className={
              eightCh === true
                ? classes.validationTextGreen
                : classes.validationTextRed
            }
            sx={{ flexDirection: "row" }}
          >
            {eightCh === true ? (
              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                className={classes.marginText}
              >
                <VectorCheck style={{ width: "15px", marginRight: "5px" }} />

                <Typography className={classes.registerText}>
                  {i18n.t("characters")}
                </Typography>
              </Grid>
            ) : (
              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                className={classes.marginText}
              >
                <VectorPlus style={{ width: "15px", marginRight: "5px" }} />

                <Typography className={classes.registerText}>
                  {i18n.t("characters")}
                </Typography>
              </Grid>
            )}
          </Box>
          <Box
            className={
              oneCapital === true
                ? classes.validationTextGreen
                : classes.validationTextRed
            }
            sx={{ flexDirection: "row" }}
          >
            {oneCapital === true ? (
              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                className={classes.marginText}
              >
                <VectorCheck style={{ width: "15px", marginRight: "5px" }} />
                <Typography className={classes.registerText}>
                  {i18n.t("oneCapital")}
                </Typography>
              </Grid>
            ) : (
              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                className={classes.marginText}
              >
                <VectorPlus style={{ width: "15px", marginRight: "5px" }} />
                <Typography className={classes.registerText}>
                  {i18n.t("oneCapital")}
                </Typography>
              </Grid>
            )}
          </Box>
          <Box
            className={
              oneLowerCase === true
                ? classes.validationTextGreen
                : classes.validationTextRed
            }
            sx={{ flexDirection: "row" }}
          >
            {oneLowerCase === true ? (
              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                className={classes.marginText}
              >
                <VectorCheck style={{ width: "15px", marginRight: "5px" }} />
                <Typography className={classes.registerText}>
                  {i18n.t("oneletter")}
                </Typography>
              </Grid>
            ) : (
              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                className={classes.marginText}
              >
                <VectorPlus style={{ width: "15px", marginRight: "5px" }} />
                <Typography className={classes.registerText}>
                  {i18n.t("oneletter")}
                </Typography>
              </Grid>
            )}
          </Box>
          <Box
            className={
              numberCh === true
                ? classes.validationTextGreen
                : classes.validationTextRed
            }
            sx={{ flexDirection: "row" }}
          >
            {numberCh === true ? (
              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
              >
                <VectorCheck style={{ width: "15px", marginRight: "5px" }} />
                <Typography className={classes.registerText}>
                  {i18n.t("numberCh")}
                </Typography>
              </Grid>
            ) : (
              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
              >
                <VectorPlus style={{ width: "15px", marginRight: "5px" }} />
                <Typography className={classes.registerText}>
                  {i18n.t("numberCh")}
                </Typography>
              </Grid>
            )}
          </Box>
        </ReactTooltip>
        {plusCh === true && (
          <Grid>
            <Typography
              className={classes.validationText}
              style={{ marginRight: "10px" }}
            >
              {i18n.t("plusCh")}
            </Typography>
          </Grid>
        )}
      </Grid>
      <Grid container item xs={12} md={12} lg={12} /*Pass 2*/>
        <InputBase
          placeholder={i18n.t("confirmPass")}
          fullWidth
          inputProps={{
            className: classes.input,
            maxLength: 255,
          }}
          type={eye === false ? "password" : "text"}
          id="password"
          name="name"
          autocomplete="off"
          sx={
            matchPassVal === true || matchPassEmpty === true
              ? { border: "1px solid #ff2569", borderRadius: 1 }
              : { className: classes.input2 }
          }
          className={classes.formButton}
          onChange={handlePasswordsMatch}
          onKeyDown={handleEnter}
        />
        {matchPassVal === true && (
          <Grid>
            <Typography
              className={classes.validationText}
              style={{ marginRight: "10px" }}
            >
              {i18n.t("matchPass")}
            </Typography>
          </Grid>
        )}
        {matchPassEmpty === true && (
          <Grid>
            <Typography className={classes.validationText}>
              {i18n.t("reqField")}
            </Typography>
          </Grid>
        )}
      </Grid>
      <Grid container items xs={12} md={12} lg={12}>
        {loading && (
          <Dialog open={loading}>
            <div
              style={{
                padding: "20px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: "transparent",
              }}
            >
              <CircularProgress
                size={30}
                style={{ backgroundColor: "transparent" }}
              />
            </div>
          </Dialog>
        )}
      </Grid>
      <Grid /*Button*/
        container
        item
        xs={12}
        md={12}
        lg={12}
        className={classes.containerLoginBtn}
      >
        {enabledBtn === true ? (
          <Button
            className={classes.loginBtn}
            onClick={handleLogin}
            disabled={false}
          >
            <Typography className={classes.btnText}>
              {i18n.t("chancePass")}
            </Typography>
          </Button>
        ) : (
          <Button
            className={classes.loginBtn2}
            onClick={handleLogin}
            disabled={true}
          >
            <Typography className={classes.btnText}>
              {i18n.t("chancePass")}
            </Typography>
          </Button>
        )}
        {errText  === true && (
          <Grid>
            {" "}
            <Typography className={classes.validationText2}>
              {i18n.t("btnErr")}
            </Typography>
          </Grid>
        )}
      </Grid>
      <Snackbar open={open} autoHideDuration={60000} /*Toast*/>
        <Alert
          onClose={handleClose}
          severity="error"
          sx={{ width: "100%", backgroundColor: "#F32465" }}
        >
          {i18n.t("err")}
        </Alert>
      </Snackbar>
    </Box>
  );
}

export default NewPassForm;
