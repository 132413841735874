import React from 'react';
import { Route, Redirect } from "react-router-dom";


const SecrectRoute = ({ component: Component, auth, ...rest }) => {
   
    const secret = localStorage.getItem('secret')

    return(
        <Route {...rest} render={(props) => (
            secret === "" || secret === undefined
                ?<Redirect to={process.env.REACT_APP_CHANGE_RECOVER} />
                :  <Component {...props} />
        )} />
    )
}

export default SecrectRoute;