import React, { Component } from "react";
import { Grid } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

import CheckedLink from "../components/CheckLink";

const IMAGE_LOGIN_PATH = "login_bg.png";

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    height: "100vh",
    backgroundColor: "#FFFFFF",
  },
  grid1: {
    backgroundColor: "#FFFFFF",
    alignContent: "center",
  },
  googleLogin: {
    marginBottom: 10,
  },
  mainTitle: {
    marginTop: "4%",
  },
  line: {
    margin: 15,
  },
  lineText: {
    fontFamily: "Work Sans, sans-serif",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "15px",
    lineHeight: "18.77px",
    alignItems: "center",
    textAlign: "center",
    color: "#131313",
    marginTop: 5,
  },
  googleFont: {
    fontFamily: "Work Sans, sans-serif",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "18.77px",
    color: "#131313",
  },
  googleBtn: {
    marginTop: theme.spacing(1),
    height: 50,
    width: 380,
    textTransform: "none",
    border: "2px solid",
    borderColor: "#131313",
    borderRadius: 15,
    color: "black",
    autoComplete: "off",
    fontFamily: "Work Sans, sans-serif",
    fontSize: "16px/22px",
    fontWeight: "normal",
    /* font: 'normal normal normal 16px/22px', */
  },
  registerFont: {
    fontFamily: "Work Sans, sans-serif",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "16.42px",
    color: "#131313",
  },
  loginBtn: {
    marginTop: theme.spacing(1),
    height: 50,
    width: 380,
    textTransform: "none",
    border: "2px solid",
    borderColor: "#131313",
    backgroundColor: "#FFFFFF",
    borderRadius: 30,
    color: "#131313",
    autoComplete: "off",
    fontFamily: "Work Sans, sans-serif",
    fontSize: "16px/22px",
    fontWeight: "normal",
    /* font: 'normal normal normal 16px/22px', */
  },
  formCheck: {
    fontFamily: "Work Sans, sans-serif",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "18.77px",
    alignItems: "center",
    textAlign: "center",
    color: "#131313",
    marginRight: 5,
  },
  password: {
    fontFamily: "Work Sans, sans-serif",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "18.77px",
    alignItems: "center",
    textAlign: "center",
    color: "#00A490",
    cursor: "pointer",
  },
  registerColorFont: {
    fontFamily: "Work Sans, sans-serif",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "18.77px",
    alignItems: "center",
    textAlign: "center",
    color: "#00A490",
  },
  BaseGrid: {
    margin: 10,
  },
  checkGrid: {
    alignItems: "center",
  },
  topButton: {
    backgroundColor: "#FFFFFF",
    alignItems: "center",
    borderRadius: 50,
    height: 60,
    width: 140,
  },
  topMenuItem: {
    backgroundColor: "#FFFFFF",
    alignItems: "center",
    borderRadius: 50,
    height: 50,
    width: 140,
  },
  topButtonText: {
    color: "#131313",
    fontFamily: "Work Sans, sans-serif",
    fontSize: "16px",
    marginTop: 15,
    fontWeight: 400,
    textAlign: "center",
    width: 96,
  },
  topdiv: {
    position: "absolute",
    top: 20,
    right: 30,
  },
  recoverTex: {
    color: "#131313",
    fontFamily: "Work Sans, sans-serif",
    fontSize: "21px",
    fontWeight: 400,
    textAlign: "center",
  },
  labelText: {
    color: "#131313",
    fontFamily: "Work Sans, sans-serif",
    fontSize: "15px",
    fontWeight: 400,
    lineHeight: 1.5,
    textAlign: "center",
  },
  backgroundImg: {
    objectFit: "cover",
    zIndex: 0,
    width: "100%",
    height: "100%",
    overFlowY: "hidden",
  },
  imgContainer:{
    //position: "fixed",
    top: 0,
    right:0,
    height: "100vh"
  }
});

class CheckLink extends Component {
  constructor(props) {
    super(props);
    this.state = {
      windowWidth: window.innerWidth,
    };
  }
  useQuery() {
    return new URLSearchParams(window.location.search);
  }
  handleCountry = (country) => {
    let countrySend = "";
    switch (country) {
      case "Argentina":
        countrySend = "ar";
        break;
      case "Chile":
        countrySend = "cl";
        break;
      case "Uruguay":
        countrySend = "uy";
        break;
      default:
        countrySend = "ar";
        break;
    }
    return countrySend;
  };

  handleResize = (e) => {
    this.setState({ windowWidth: window.innerWidth });
  };
  componentDidMount() {
    window.addEventListener("resize", this.handleResize);
    let user_id = this.useQuery().get("user_id");
    let email = this.useQuery().get("email");
    let secret = this.useQuery().get("secret");
    localStorage.setItem("user_id", user_id);
    localStorage.setItem("emailPass", email);
    localStorage.setItem("secret", secret);

    fetch(process.env.REACT_APP_VALIDATE_LINK, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        user_id: user_id,
        country: this.handleCountry(localStorage.getItem("countryPass")),
        secret: secret,
      }),
    })
      .then((response) => {
        if (response !== "") {
          return response.json();
        } else {
          return null;
        }
      })
      .then((response) => {
        if (response.error) {
          window.open(process.env.REACT_APP_EXPIRE_PASSWORD, "_self");
        } else {
          window.open(process.env.REACT_APP_CHANGE_PASSWORD, "_self");
        }
      });
      console.clear();
  }
  render() {
    function getWindowDimensions() {
      const { innerWidth: width } = window;
      return {
        width,
      };
    }

    const { classes } = this.props;
    const { width } = getWindowDimensions();

    return (
      <div style={{ backgroundColor: "#E5E5E5" }}>
        <Grid
          container
          className={classes.root}
          component="main"
          maxWidth="sm"
          alignContent="middle"
          justifyContent="center"
        >
          <Grid
            container
            item
            className={classes.grid1}
            xs={width >= 600 ? 6 : 12}
            md={width >= 600 ? 6 : 12}
            lg={width >= 600 ? 6 : 12}
            maxWidth="md"
            justifyContent="center"
          >
            <Grid
              container
              item
              xs={12}
              md={12}
              lg={12}
              justifyContent="center"
              mb={1}
            >
              <CheckedLink />
            </Grid>
          </Grid>
          {width >= 600 ? (
            <Grid
              container
              item
              xs={6}
              md={6}
              lg={6}
              className={classes.imgContainer}
            >
              <img
                src={process.env.REACT_APP_BASE_URL_IMAGES + IMAGE_LOGIN_PATH}
                alt="computer_man"
                className={classes.backgroundImg}
              />

              <div className={classes.topdiv}>
                {/*<MenuLanguageComponent />*/}
              </div>
            </Grid>
          ) : (
            <Grid
              container
              item
              xs={12}
              md={12}
              lg={12}
              justifyContent="center"
            >
              {/*<MenuLanguageComponent />*/}
            </Grid>
          )}
        </Grid>
      </div>
    );
  }
}
export default withStyles(styles, { withTheme: true })(CheckLink);
