import React from "react";
import { Grid, Typography, Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import i18n from "./../locale/index";

const useStyles = makeStyles({
  grid1: {
    backgroundColor: "#FFFFFF",
    alignContent: "center",
  },
  googleLogin: {
    marginBottom: 10,
  },
  BaseGrid: {
    margin: 10,
  },
  mainTitle: {
    marginTop: "4%",
    fontFamily: "Work Sans, sans-serif",
    fontSize: "21px",
    fontWeight: 600,
    textAlign: "left",
    lineHeight: 1.5, 

  },
  recoverTex: {
    marginTop: 10,
    color: "#131313",
    fontFamily: "Work Sans, sans-serif",
    fontSize: "15px",
    fontWeight: 500,
    lineHeight: 1.5, 
    textAlign: "left",
  },
  recoverTex2: {
    marginTop: 10,
    color: "#131313",
    fontFamily: "Work Sans, sans-serif",
    fontSize: "15px",
    fontWeight: 400,
    lineHeight: 1.5, 
    textAlign: "left",
  },
  alignGrid: {
    marginRight: 20,
  },
});

const Showmessage = (props) => {
  const classes = useStyles();

  return (
    <>
      <Grid
        container
        item
        className={classes.grid1}
        justifyContent="center"
        maxWidth="md"
      >
        <Grid
          container
          item
          className={classes.mainTitle}
          xs={12}
          md={12}
          lg={12}
          justifyContent="center"
        >
          {props.svg ? <props.svg /> : null}
        </Grid>
        <Box
          sx={{
            width: "90vw",
            maxWidth: "320px",
            margin: "0",
            "@media (min-width: 780px)": {
              maxWidth: "380px",
            },
          }}
        >
          <Grid
            container
            item
            className={classes.googleLogin}
            xs={12}
            md={12}
            lg={12}
            justifyContent="center"
            maxWidth="sm"
          >
            <Typography className={classes.mainTitle}>
              {i18n.t(`${props.texto}`)}
            </Typography>

            <Grid
              container
              xs={12}
              md={12}
              lg={12}
              direction="row"
              alignItems="center"
            >
              {props.loader ? (
                <Grid item xs={1} className={classes.alignGrid}>
                  <props.loader />
                </Grid>
              ) : null}
              <Grid item xs={10}>
                <Typography className={classes.recoverTex2}>
                  {i18n.t(`${props.texto2}`)}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Grid>
    </>
  );
};

export default Showmessage;
