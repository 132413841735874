import React from "react";
import { Grid, Box } from "@material-ui/core";
//import { makeStyles } from "@material-ui/core/styles";
import Stack from "@mui/material/Stack";
import CircularProgress from "@mui/material/CircularProgress";

/* const useStyles = makeStyles({
  grid1: {
    backgroundColor: "#FFFFFF",
    alignContent: "center",
  },
  googleLogin: {
    marginBottom: 10,
  },
  BaseGrid: {
    margin: 10,
  },
  mainTitle: {
    marginTop: "4%",
     fontFamily: "Work Sans, sans-serif",
    fontSize: "15px",
    fontWeight: 500,
    textAlign: "left",
  },
  recoverTex: {
    marginTop: 10,
    color: "#131313",
     fontFamily: "Work Sans, sans-serif",
    fontSize: "16px",
    fontWeight: 500,
    textAlign: "left",
  },
  contentAlign: {
    alignSelf: "center",
    alignContent: "center",
     fontFamily: "Work Sans, sans-serif",
  },
}); */

const CheckedLink = (props) => {
  // const classes = useStyles();

  return (
    <>
      <Box
        sx={{
          "& .MuiGrid-item": {
            mt: 1.5,
            width: "90vw",
            maxWidth: "320px",
            "@media (min-width: 780px)": {
              maxWidth: "380px",
            },
          },
        }}
      >
        <Grid
          container
          item
          alignItems="center"
          xs={12}
          md={12}
          lg={12}
          justifyContent="center"
        >
          <Stack
            sx={{ color: "#00c7af" }}
            spacing={2}
            direction="row"
            justifyContent="center"
          >
            <CircularProgress
              color="inherit"
              size="60px"
              thickness={4}
              value={100}
            />
          </Stack>
          {/*       <Grid direction="row" alignContent="center" xs={2} md={2} lg={2} >
            
            </Grid>
         <Grid direction="row" alignContent="center" xs={10} md={10} lg={10} >
            <Typography className={classes.mainTitle}>
              {i18n.t("checkLink")}
            </Typography>
            </Grid>
      */}
        </Grid>
      </Box>
    </>
  );
};

export default CheckedLink;
