export const es = {
    textBtnGoogle:" Iniciar sesion con Google",
    textBtnGoogle2:" Registrate con Google",
    useEmail:"Usá tu mail para ingresar",
    useEmail2:" O completá los siguientes datos",
    remember:"Recordarme",
    forgot:" Olvidé mi contraseña",
    into:"Ingresar",
    account:"¿No tenés cuenta?",
    register:"Registrate",
    registerfree: "Registrate gratis",
    startNow:"Empezar ahora",
    accountNow:"¿Ya tenés cuenta?",
    intoNow:"Ingresá",
    iaccept:"Acepto los",
    terms:"Términos y Condiciones",
    reason:"Nombre o razón social *",
    pass:"Contraseña *",
    pass2:"Repetir contraseña *",
    phone:"Teléfono ",
    //forfriends:"¿Por un amigo?",
    //bysocial:"¿Por redes sociales?",
    meetUs:"¿Cómo nos conociste?",
    recoverText:"Ingresá el correo electrónico que estás utilizando para tu cuenta.",
    recoverTextChUy: "Ingresa el correo electrónico que estás utilizando para tu cuenta.", 
    recoverPass:"Recuperar contraseña",
    continue:"Continuar",
    goBack:"Volver al Login",
    goBackMail: "Volver a Verificacion de email",
    recoverTextMail:"Ingresá al link del mismo para crear una contraseña nueva. Si no lo recibiste, verificá la casilla de correo no deseado.",
    sendMailRecover:"Se envió un correo a la dirección ingresada.",
    createNewPass:"Escribe una contraseña única y segura que sea fácil ",
    createNewPass2:"de recordar para que no la olvides la próxima vez.",
    newPass:"Contraseña nueva *",
    chancePass:"Cambiar contraseña",
    newChancePass:"Crear nueva contraseña",
    characters:"Min. 8 caracteres",
    oneCapital:"1 letra mayúscula",
    oneletter:"1 letra minúscula",
    complete:"Se ha completado el cambio de contraseña con éxito.",
    goBackLogin1:"Ya puedes volver al login e ingresar con tu correo electrónico y tu nueva contraseña. ",
    expiredText:"El link ya no es válido, por favor volvé a recuperar tu contraseña.",
    expiredText2:"Recordá que el link tiene validez por 24 horas.",
    testforfree: "¡Probalo 10 días sin cargo!",
    discountcode: "Tengo un código de promoción",
    registerG1:"Registro con Google",
    registerG2: "¡Te damos la bienvenida,",
    registerG3:"Se creará una nueva cuenta de Contabilium",
    registerG4: "para la dirección de correo electrónico",
    emailVerification: "Verificación de email",
    subEmailVerification: "Hemos enviado un código de verificación a la cuenta ",
    subEmailVerification2: "el cual te solicitamos a continuación", 
    codeLabel: "¿No recibiste el código?",
    wait: "Esperá",
    seconds: "segundos",
    resendNow: "Reenviar ahora",
    enterLater: "Lo ingresaré más tarde, déjame continuar", 
    notReceive: "¿No recibiste el email? Revisá tu carpeta de spam o ",
    enterNewEmail: "ingresá un nuevo email",
    notReceive2: " y probá nuevamente",
    confirm: "Confirmar", 
    openGmail: "Abrir Gmail",
    openOutlook: "Abrir Outlook",
    google:"Google",
    friends:"Amigos",
    recomendation:"Recomendacion",
    socialm:"Redes sociales",
    event:"Evento",
    other:"Otro", 
    code:"Código de promoción",
    activity: "Actividad", 
    wrongUserPassword: "Usuario o contraseña incorrecta",
    blockedUser: "Has superado el número máximo de intentos posibles. Por su seguridad, su cuenta ha sido bloqueada. Haga click en olvidé mi contraseña para poder recuperarla. ",
    errorMessage: "Por favor, ingrese una dirección de correo válida",
    numberCh:"Min. 1 caracter numérico", 
    emaiCh:"Ingresa un email válido", 
    matchPass:"Las contraseñas no coinciden", 
    plusCh:"No está permitido incluír el caracter “+”", 
    reqField:"Campo obligatorio",
    errorTextCode: "Por favor, ingresa el código de 6 digitos",
    validTextCode: "El código ingresado es correcto",
    confirmMail: "Cambio de email",
    termin:"Debe aceptar los términos y condiciones", 
    successAuth: "Se ha verificado tu email con éxito.",
    enterCbl: "En unos segundos ingresarás a Contabilium automáticamente.",
    errPass:"Usuario y/o contraseña incorrecta",
    checkLink:"Espera mientras validamos el enlace. Por favor no cierres esta pantalla, se redirigirá automáticamente",
    confirmPass:"Confirma la nueva contraseña *",
    btnErr:"La contraseña ha sido utilizada previamente", 
    err:"Mensaje de error",
    incorrectEmail: "¿El email no es correcto?",
    changeEmail: "Cámbialo desde aquí",
    disableAccount: "Tu cuenta se ha deshabilitado temporalmente porque aun no has ingresado el código de verificación que enviamos a",
    pleaseEnterCode: "Por favor ingresa el código para volver a habilitar tu cuenta",
    errCodeApi: "El código que ingresaste es incorrecto o inválido",
    errCode: "Por favor ingresa el código de 6 dígitos", 
    enterNewEmailLabel: "Ingresá un nuevo correo electrónico para tu cuenta, donde recibirás el código de verificación.",
    actProducts: "Vendo productos", 
    actService:"Vendo servicios", 
    actPandS:"Vendo productos y servicios",
    actCount:"Soy contador", 
    actCountName:"Asesoramiento contable",
    actSelect:"Seleccione su actividad",
    useEmailChUy:"Usa tu mail para ingresar",
    accountChUy:"¿No tienes cuenta?",
    registerfreeChUy: "Regístrate gratis",
    testforfreeChUy: "¡Pruébalo 10 días sin cargo!",
    terminChUy:"Debes aceptar los términos y condiciones",
    intoNowChUy:"Ingresa",
    accountNowChUy:"¿Ya tienes cuenta?",
    expiredTextChUy:"El link ya no es válido, por favor vuelve a recuperar tu contraseña.",
    expiredText2ChUy:"Recuerda que el link tiene validez por 24 horas.",
    recoverTextMailChUy:"Ingresa al link del mismo para crear una contraseña nueva. Si no lo recibiste, verifica la casilla de correo no deseado.",  
}