import React, { Component } from "react";
import { Grid } from "@material-ui/core";
import i18n from "./../locale/index";
import { withStyles } from "@material-ui/core/styles";
import { ReactComponent as Mail } from "../assets/images/recovermail.svg";
//import i18n from './../locale/index';
import Showmessage from "../components/ShowMessage";
//import MenuLanguageComponent from '../components/MenuLanguage'


const IMAGE_LOGIN_PATH = "login_bg.png";

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    height: "100vh",
    backgroundColor: "#FFFFFF",
  },
  grid1: {
    backgroundColor: "#FFFFFF",
    alignContent: "center",
    justifyContent: "center",
  },
  googleLogin: {
    marginBottom: 10,
  },
  mainTitle: {
    marginTop: "4%",
  },
  line: {
    margin: 15,
  },
  lineText: {
    fontFamily: "Work Sans, sans-serif",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "15px",
    lineHeight: "18.77px",
    alignItems: "center",
    textAlign: "center",
    color: "#131313",
    marginTop: 5,
  },
  googleFont: {
    fontFamily: "Work Sans, sans-serif",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "18.77px",
    color: "#131313",
  },
  googleBtn: {
    marginTop: theme.spacing(1),
    height: 50,
    width: 380,
    textTransform: "none",
    border: "2px solid",
    borderColor: "#131313",
    borderRadius: 15,
    color: "black",
    autoComplete: "off",
    fontFamily: "Work Sans, sans-serif",
    fontSize: "16px/22px",
    fontWeight: "normal",
    /* font: 'normal normal normal 16px/22px', */
  },
  registerFont: {
    fontFamily: "Work Sans, sans-serif",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "16.42px",
    color: "#131313",
  },
  loginBtn: {
    marginTop: theme.spacing(1),
    height: 50,
    width: 380,
    textTransform: "none",
    border: "2px solid",
    borderColor: "#00C7AF",
    backgroundColor: "#00C7AF",
    borderRadius: 30,
    color: "white",
    autoComplete: "off",
    fontFamily: "Work Sans, sans-serif",
    fontSize: "16px/22px",
    fontWeight: "normal",
    /* font: 'normal normal normal 16px/22px', */
  },
  formCheck: {
    fontFamily: "Work Sans, sans-serif",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "18.77px",
    alignItems: "center",
    textAlign: "center",
    color: "#131313",
    marginRight: 5,
  },
  password: {
    fontFamily: "Work Sans, sans-serif",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "18.77px",
    alignItems: "center",
    textAlign: "center",
    color: "#00A490",
    cursor: "pointer",
  },
  registerColorFont: {
    fontFamily: "Work Sans, sans-serif",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "18.77px",
    alignItems: "center",
    textAlign: "center",
    color: "#00A490",
  },
  BaseGrid: {
    margin: 10,
  },
  checkGrid: {
    alignItems: "center",
  },
  topButton: {
    backgroundColor: "#FFFFFF",
    alignItems: "center",
    borderRadius: 50,
    height: 60,
    width: 140,
  },
  topMenuItem: {
    backgroundColor: "#FFFFFF",
    alignItems: "center",
    borderRadius: 50,
    height: 50,
    width: 140,
  },
  topButtonText: {
    color: "#131313",
    fontFamily: "Work Sans, sans-serif",
    fontSize: "16px",
    marginTop: 15,
    fontWeight: 400,
    textAlign: "center",
    width: 96,
  },
  topdiv: {
    position: "absolute",
    top: 20,
    right: 30,
  },
  recoverTex: {
    color: "#131313",
    fontFamily: "Work Sans, sans-serif",
    fontSize: "21px",
    fontWeight: 400,
    textAlign: "center",
  },
  backgroundImg: {
    objectFit: "cover",
    zIndex: 0,
    width: "100%",
    height: "100%",
    overFlowY: "hidden",
    left: "40px",
  },
  imgContainer:{
 //   position: "fixed",
    top: 0,
    right:0,
    height: "100vh"
  }
});

class RecoverMailView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      textMultiCountry: i18n.t("recoverTextMail"),
      windowWidth: window.innerWidth,
    };
  }

  handleResize = (e) => {
    this.setState({ windowWidth: window.innerWidth });
  };
  handleTextMulticountry = (host) => {
    let region = i18n.t("");
    switch (host) {
      case "com":
        region = i18n.t("recoverTextMail");
        break;
      case "ar":
        region = i18n.t("recoverTextMail");
        break;
      case "cl":
        region = i18n.t("recoverTextMailChUy");
        break;
      case "uy":
        region = i18n.t("recoverTextMailChUy");
        break;
      default:
        region =i18n.t("recoverTextMail");
        break;
    }
    return region
  }
  componentDidMount() {
    const extUrl = window.location.host.replace(/^.*\./, "");
    this.setState({
      textMultiCountry:this.handleTextMulticountry(extUrl),         
    })
    window.addEventListener("resize", this.handleResize);
    console.clear();
  }
  render() {
    function getWindowDimensions() {
      const { innerWidth: width } = window;
      return {
        width,
      };
    }

    const { classes } = this.props;
    const { width } = getWindowDimensions();

    return (
      <div style={{ backgroundColor: "#E5E5E5" }}>
        <Grid
          container
          className={classes.root}
          component="main"
          maxWidth="sm"
          alignContent="middle"
        >
          <Grid
            container
            item
            className={classes.grid1}
            xs={width >= 600 ? 6 : 12}
            md={width >= 600 ? 6 : 12}
            lg={width >= 600 ? 6 : 12}
            maxWidth="md"
            justifyContent="center"
          >
            <Showmessage
              svg={Mail}
              texto="sendMailRecover"
              texto2={this.state.textMultiCountry}
            />
          </Grid>
          {width >= 600 ? (
            <Grid
              container
              item
              xs={6}
              md={6}
              lg={6}
              className={classes.imgContainer}
            >
              <img
                src={process.env.REACT_APP_BASE_URL_IMAGES+ IMAGE_LOGIN_PATH}
                alt="computer_man"
                className={classes.backgroundImg}
              />

              <div className={classes.topdiv}>
                {/*<MenuLanguageComponent />*/}
              </div>
            </Grid>
          ) : (
            <Grid
              container
              item
              xs={12}
              md={12}
              lg={12}
              justifyContent="center"
            >
              {/*<MenuLanguageComponent />*/}
            </Grid>
          )}
        </Grid>
      </div>
    );
  }
}
export default withStyles(styles, { withTheme: true })(RecoverMailView);
