import React from 'react';
import { Route, Redirect } from "react-router-dom";
import Cookies from 'universal-cookie';

const CookieRoute = ({ component: Component, auth, ...rest }) => {
    const cookies = new Cookies();
    const jwtCookie = cookies.get('Secure-1CBL')

    return(
        <Route {...rest} render={(props) => (
            jwtCookie
                ? <Component {...props} />
                : <Redirect to='/login' />
        )} />
    )
}

export default CookieRoute;