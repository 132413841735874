/* import client from './cliente'; */

import { setToken } from './auth-helpers'

const URI = process.env.REACT_APP_ACCOUNT_LOGIN;
const { localStorage } = global.window;

export const auth = {
  login( username, password ) {
     if (auth.loggedIn()) {
      return Promise.resolve(true);
    } 
    return fetch(URI, {
      method: 'post',
      headers: {       
        'Content-Type': 'application/json'
      },
      body:JSON.stringify ({
        "email": username,
        "password": password,              
       
        })
    })
      .then(response => {   
          
        if (response !==''){ 
        return response.json();
         } else{
        return null;
        } 
      })
       .then(response => {
        setToken(response.jwt);
       
      })  
      .catch(() => {
        localStorage.removeItem('jwt');
      });
  },

  logout() {
    /* localStorage.removeItem('id_token'); */
    localStorage.clear();
    return Promise.resolve(true);
  },

  loggedIn() {
    return !!localStorage.jwt;
  },
  userData() {
    return localStorage.jwt ? JSON.parse(localStorage.jwt) : null;
  },
};

