import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import { Typography } from "@material-ui/core";
import {
  Grid,
  Button,
  InputAdornment,
  CircularProgress,
} from "@material-ui/core";
import { InputBase } from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import i18n from "./../locale/index";
import Link from "@mui/material/Link";
import Checkbox from "@mui/material/Checkbox";
import Dialog from "@material-ui/core/Dialog";
import { ReactComponent as Vector } from "./../assets/images/Vector.svg";
import { ReactComponent as VectorEye } from "./../assets/images/VectorEye.svg";
//import { ReactComponent as Vectorx } from "./../assets/images/xVector.svg";
import { ReactComponent as Ar } from "./../assets/images/argentina.svg";
import { ReactComponent as Uy } from "./../assets/images/uruguay.svg";
import { ReactComponent as Cl } from "./../assets/images/chile.svg";
//import { ReactComponent as Vectorx } from "./../assets/images/xVector.svg";
import { sendAmplitudeData, setAmplitudeUserId, setAmplitudeIdentify } from "../helpers/amplitude";

import Cookies from "universal-cookie";

const { localStorage } = global.window;
const useStyles = makeStyles((theme) => ({
  formButton: {
    color: "#131313",
    fontFamily: "Work Sans, sans-serif",
    fontSize: "16px",
    fontWeight: 400,
    textAlign: "left",
    border: "1px solid rgba(19, 19, 19, 0.3)",
    borderRadius: 3,
    height: 45,
    padding: "5px 16px",
  },
  loginBtn: {
    marginTop: theme.spacing(1),
    height: 50,
    width: 320,
    textTransform: "none",
    border: "2px solid",
    borderColor: "#00A692",
    backgroundColor: "#00A692",
    borderRadius: 50,
    color: "#fff",
    autoComplete: "off",
    fontFamily: "Work Sans, sans-serif",
    fontSize: "16px",
    fontWeight: "normal",
    "&:hover": {
      backgroundColor: "#007868",
      borderColor: "#007868",
      color: "#fff",
    },
    "@media (min-width: 780px)": {
      width: 380,
    },
  },
  loginBtn2: {
    marginTop: theme.spacing(1),
    height: 50,
    width: 320,
    textTransform: "none",
    border: "2px solid",
    borderColor: "#C6C6C6",
    backgroundColor: "#C6C6C6",
    borderRadius: 50,
    color: " #131313",
    autoComplete: "false",
    fontFamily: "Work Sans, sans-serif",
    fontSize: "16px/22px",
    fontWeight: "normal",

    "&:hover": {
      backgroundColor: "#007868",
      borderColor: "#007868",
      color: "#fff",
    },
    "@media (min-width: 780px)": {
      width: 380,
    },
  },
  input: {
    height: 45,
  },
  inputError: {
    height: 45,
    backgroundColor: "#FFD3E1",
    border: "1px solid rgba(189, 28, 79, 0.5)",
  },
  inputError2: {
    backgroundColor: "#FFD3E1",
    border: "1px solid rgba(189, 28, 79, 0.5)",
    borderRadius: "5px",
    paddingLeft: "14px",
  },
  labelAlign: {
    alignSelf: "center",
    alignContent: "center",
    fontFamily: "Work Sans, sans-serif",
    justifyContent: "flex-end",
  },
  checkGrid: {
    width: "100%",
  },
  validationText: {
    fontFamily: "Work Sans, sans-serif",
    color: "#ff2569",
    fontSize: "11px",
    marginTop: "3px",
  },
  passErr: {
    fontFamily: "Work Sans, sans-serif",
    color: "#BD1C4F",
    fontSize: "14px",
  },
  MuiOutlinedInputInput: {
    opacity: "8!important",
  },
  password: {
    fontFamily: "Work Sans, sans-serif",
    fontSize: "14px",
    textDecoration: "none",
    "&:hover": {
      color: "#007868",
    },
  },
  formCheck: {
    fontFamily: "Work Sans, sans-serif",
  },
  buttonText: {
    fontFamily: "Work Sans, sans-serif",
    fontSize: "16px",
  },
  formTypografy: {
    color: "#131313",
    fontSize: "15px",
    fontWeight: 400,
    textAlign: "left",
    fontFamily: "Work Sans, sans-serif",
    height: "fit-content",
    marginLeft: "2px",
  },
  formSelect: {
    height: 45,
    alignContent: "center",
    padding: "1px 0",
  },
  formFlag: {
    margin: " 0 5px 0px 16px",
    display:"flex",
    alignSelf:"center"
  },
  formCountry: {
    paddingBottom: "0",
  },
}));

const countries = [
  {
    value: "Argentina",
    label: <Ar code="AR" />,
  },
  {
    value: "Chile",
    label: <Cl code="CL" />,
  },
  {
    value: "Uruguay",
    label: <Uy code="UY" />,
  },
];
function capturedHost(host) {
  let countrySend = "";
  switch (host) {
    case "com":
      countrySend = "Argentina";
      break;
    case "ar":
      countrySend = "Argentina";
      break;
    case "cl":
      countrySend = "Chile";
      break;
    case "uy":
      countrySend = "Uruguay";
      break;
    default:
      countrySend = "Argentina";
      break;
  }
  return countrySend;
}

function LoginForm() {
  const [country, setCountry] = React.useState(
    capturedHost(window.location.host.replace(/^.*\./, ""))
  );
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [eye, setEye] = useState(false);

  // validation email
  const [emailCh, setEmailCh] = useState(true);

  // checkbox
  //const [checkedValue, setCheckedValue] = useState(false);
  const [checkedEnter, setCheckedEnter] = useState(false);

  //btn register

  const [textError, setTextError] = useState(false);
  const [loading, setloading] = useState(false);

  /* const [show, setShow] = useState(false);
  const [active, setActive] = useState(false); */

  // Error msj
  const [errormsj, setErrorMsj] = useState(false);
  const [errorCountry, setErrorCountry] = useState(false);
  const classes = useStyles();
  // Country
  const saveCountry = () => {
    localStorage.setItem("country", country);
  };

  const handleChange = (event) => {
    setCountry(event.target.value);
    if (event.target.value === "Argentina"){
      window.open(process.env.REACT_APP_BASE_URL_AR, "_self");
    }
    if (event.target.value === "Chile"){
      window.open(process.env.REACT_APP_BASE_URL_CL, "_self");
    }
    if (event.target.value === "Uruguay"){
      window.open(process.env.REACT_APP_BASE_URL_UY, "_self");
    }
  };
  useEffect(() => {
    saveCountry();
  }, [country]);

  const handleCountry = (country) => {
    let countrySend = "";
    switch (country) {
      case "Argentina":
        countrySend = "ar";
        break;
      case "Chile":
        countrySend = "cl";
        break;
      case "Uruguay":
        countrySend = "uy";
        break;
      default:
        countrySend = "ar";
        break;
    }
    return countrySend;
  };

  // Email validation
  const handleValidateEmail = (event) => {
    setEmail(event.target.value);
    if (/\S+@\S+\.\S+/.test(event.target.value)) {
      setEmailCh(true);
      //  setEmailChBtn(true);
    } else {
      setEmailCh(false);
      //  setEmailChBtn(false);
    }
    localStorage.setItem("email", email);
  };

  useEffect(() => {
    if (/\S+@\S+\.\S+/.test(email)) {
      setEmailCh(true);
      // setEmailChBtn(true);
    } else {
      //  setEmailCh(false);
      //  setEmailChBtn(false);
    }
  }, [email]);

  // Checkbox

  const handleCookie = (e) => {
    if (e.target.checked === true) {
      // setCheckedValue(true);
      setCheckedEnter(true);
    } else {
      // setCheckedValue(false);
      setCheckedEnter(false);
    }
  };
  const CompareDate = (now, exp) => {
    return now > new Date(exp * 1000);
  };

  // err msj

  useEffect(() => {
    if (email !== "") {
      setErrorMsj(false);
      setErrorCountry(false);
    } else {
    }

    if (password !== "") {
      setErrorMsj(false);
      setErrorCountry(false);
    } else {
    }
  }, [email, password]);

  //Btn Login catch information

  const desencripJwt = (jwt) => {
    let base64Url = jwt.split(".")[1];
    let base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    let jsonPayload = decodeURIComponent(
      atob(base64)
        .split("")
        .map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );
    return jsonPayload;
  };

  const handleLogin = (e) => {
    // e.preventDefault();

    setloading(true);
    const URI = process.env.REACT_APP_ACCOUNT_LOGIN;
    if (email === null || password === null) {
      //    setErrorMsj(true);
      setloading(false);
      setErrorMsj(true);
    } else {
      fetch(URI, {
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: email,
          password: password,
          country: handleCountry(country),
        }),
      })
        .then((response) => {
          if (response !== "") {
            return response.json();
          } else {
            return null;
          }
        })

        .then((response) => {
          if (response.error) {
            if (response.error === "User blocked for security purposes.") {
              setTextError(true);
              setErrorMsj(false);
              setloading(false);
            } else if (response.error === "Incorrect email or password.") {
              setTextError(false);
              setErrorMsj(true);
              setloading(false);
            } else if (
              response.message === "Username or password is required."
            ) {
              setErrorMsj(true);
              setloading(false);
            }
            sendAmplitudeData("Login - Ingresar", {
              "properties.email": email,
              "properties.exito": false
            });
          } else {
            let jsonParse = JSON.parse(desencripJwt(response.jwt));
            let ext = handleCountry(localStorage.getItem("country"));
            localStorage.setItem("jwt", response.jwt);
            localStorage.setItem("validated", jsonParse.data.verified);
            localStorage.setItem("userID_Product", jsonParse.data.userId); 
            localStorage.setItem("Company", jsonParse.data.business_name); 
            localStorage.setItem("email", jsonParse.data.email);
            localStorage.setItem("userAdd", jsonParse.data.is_additional); 
            localStorage.setItem("parent", jsonParse.data.parent_id);  
            localStorage.setItem("country_prod", jsonParse.data.country);
            localStorage.setItem("sign_up_date", jsonParse.data.sign_up_date); 
            localStorage.setItem("promotion_code", jsonParse.data.promotion_code);     
            const cookies = new Cookies();
            cookies.set("Secure-1CBL", response.jwt, {
              path: "/",
              expire: jsonParse.data.exp,
            });
            setAmplitudeUserId(jsonParse.data.userId);
            setAmplitudeIdentify({
              userID: jsonParse.data.userId,
              username: jsonParse.data.email,
              email: jsonParse.data.email,
              plan: jsonParse.data.plan_name,
              Pais: jsonParse.data.country == "ar" ? "Argentina" : (jsonParse.data.country == "cl" ? "Chile" : "Uruguay"),
              UsuarioAdicional: jsonParse.data.is_additional,
              padre: parseInt(jsonParse.data.parent_id) > 0 ? parseInt(jsonParse.data.parent_id) : 0
            });
            sendAmplitudeData("Login - Ingresar", {
              "properties.exito": true
            });
            sendAmplitudeData("Login_Success", {
              userID: jsonParse.data.userId,
            });
            const expired = CompareDate(
              Date.now(),
              jsonParse.data.verification_exp
            );
            setloading(false); 
            if (expired === true && jsonParse.data.verified === false) {
              window.open(process.env.REACT_APP_MAIL_AUTH, "_self");
            } else {
              window.open(process.env.REACT_APP_LINK, "_self");
            }
          }
        });
    }
  };

  //enter login input
  const handleEnter = (event) => {
    if (event.key === "Enter") {
      handleLogin();
    }
  };

  // enter remember me
  const handleEnterRemember = (event) => {
    if (event.key === "Enter") {
      setCheckedEnter(!checkedEnter);
    }
  };

  return (
    <Box
      component="form"
      sx={{
        "& .MuiGrid-item": {
          mt: 1.5,
          width: "90vw",
          maxWidth: "320px",
          "@media (min-width: 780px)": {
            maxWidth: "380px",
          },
        },
        "& 	fieldset": {
          height: "45px",
          marginTop: "5px",
        },
        legend: { display: "none" },
        "& 	.MuiInputBase-input": {
          fontFamily: "Work Sans, sans-serif",
          lineHeight: "17.6px",
          fontSize: "15px",
        },
        "& 	.MuiFormControlLabel-label": {
          fontFamily: "Work Sans, sans-serif",
          lineHeight: "16.42px",
          fontSize: "14px",
        },
        "& 	.MuiButton-text .MuiTypography-root": {
          fontFamily: "Work Sans, sans-serif",
          lineHeight: "18.77px",
          fontSize: "15px",
        },
        "& 	.css-9n8air-MuiButtonBase-root-MuiCheckbox-root.Mui-checked, .css-9n8air-MuiButtonBase-root-MuiCheckbox-root.MuiCheckbox-indeterminate":
          {
            color: "#00A692",
          },
        "& 	.MuiGrid-root .makeStyles-labelAlign-26 .MuiGrid-container .MuiGrid-item .MuiGrid-grid-xs-6 .MuiGrid-grid-md-5 .MuiGrid-grid-lg-4":
          {
            justifyContent: "flex-end",
          },
        "& 	.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input":
          {
            padding: "0",
            height: "45px",
          },
        "& 	input": {
          height: "45px",
          paddingBottom: 0,
          paddingTop: 0,
        },
        "& input:-webkit-autofill": {
          transition: "all 5000s ease-in-out 0s",
        },
        "& .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input-Mui-focused": {
          backgroundColor: "yellow",
        },
        "& .css-md26zr-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
          {
            border: "1px solid rgba(0, 0, 0, 0.23)",
          },
        "& .css-154xyx0-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
          {
            border: "1px solid rgba(0, 0, 0, 0.23)",
          },
        "& .css-md26zr-MuiInputBase-root-MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
          {
            border: "1px solid rgba(0, 0, 0, 0.23)",
          },
        "& .css-154xyx0-MuiInputBase-root-MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
          {
            border: "1px solid rgba(0, 0, 0, 0.23)",
          },
        "& .css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root": {
          paddingTop: "0px",
          paddingBottom: "0px",
        },
        "& .css-9n8air-MuiButtonBase-root-MuiCheckbox-root:hover": {
          backgroundColor: "rgba(0, 0, 0, 0)",
        },
        "& .css-9n8air-MuiButtonBase-root-MuiCheckbox-root.Mui-checked": {
          backgroundColor: "rgba(0, 0, 0, 0)",
        },
        "& .css-154xyx0-MuiInputBase-root-MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline":
          {
            borderColor: "rgba(0, 0, 0, 0)",
          },
        ".css-md26zr-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
          { border: "1px solid rgba(0, 0, 0, 0.23)" },
        ".css-md26zr-MuiInputBase-root-MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
          { border: "1px solid rgba(0, 0, 0, 0.23)" },
        "& 	.css-7dqvty-MuiInputBase-input": {
          paddingTop: 0,
        },

        "& 	.css-6hp17o-MuiList-root-MuiMenu-list": {
          paddingTop: "80px",
          paddingBottom: "0!important",
        },
      }}
      noValidate
      autoComplete="off"
    >
      <Grid container item xs={12} md={12} lg={12} /*Country*/>
        <TextField
          id="outlined-select-country"
          select
          fullWidth
          className={classes.formButton}
          value={country}
          onChange={handleChange}
          onBlur={saveCountry}
          InputProps={{
            className: classes.input,
          }}
        >
          {countries.map((option) => (
            <MenuItem key={option.value} value={option.value} className={classes.formCountry} disableRipple >
              <Grid
                container
                xs={12}
                md={12}
                lg={12}
                className={classes.formSelect}
              >
                <Grid className={classes.formFlag}>{option.label}</Grid>

                <Typography className={classes.formTypografy}>
                  {option.value}
                </Typography>
              </Grid>
            </MenuItem>
          ))}
        </TextField>
      </Grid>
      <Grid container item xs={12} md={12} lg={12} /*Email*/>
        <InputBase
          placeholder="Email"
          fullWidth
          id="email"
          name="name"
          InputProps={{
            className: classes.input,
          }}
          sx={
            emailCh === false
              ? { border: "1px solid #ff2569", borderRadius: 1 }
              : { className: classes.input2 }
          }
          autoComplete="false"
          //  className= {emailCh === false ?classes.formButton : classes.errorText}
          onChange={(e) => {
            setEmail(e.target.value);
          }}
          className={classes.formButton}
          onBlur={handleValidateEmail}
        />
        {emailCh === false && (
          <Grid>
            <Typography className={classes.validationText}>
              {i18n.t("emaiCh")}
            </Typography>
          </Grid>
        )}
      </Grid>
      <Grid container item xs={12} md={12} lg={12} /*Pass*/>
        <TextField
          placeholder="Contraseña"
          fullWidth
          type={eye === false ? "password" : "text"}
          id="password"
          name="password"
          autoComplete="false"
          className={classes.formButton}
          onChange={(e) => {
            setPassword(e.target.value);
          }}
          onBlur={(e) => {
            localStorage.setItem("password", password);
          }}
          onKeyDown={handleEnter}
          required
          inputProps={{
            maxLength: 255,
          }}
          InputProps={{
            className: classes.input,

            endAdornment: (
              <InputAdornment position="end" style={{ cursor: "pointer" }}>
                {eye === false /* onClick  */ ? (
                  <VectorEye onClick={(e) => setEye(true)} />
                ) : (
                  <Vector onClick={(e) => setEye(false)} />
                )}
              </InputAdornment>
            ),
          }}
        />
      </Grid>

      {errormsj === true && (
        <Grid container item xs={12} md={12} lg={12} /*Error msj*/>
          <TextField
            fullWidth
            disabled
            id="msj error"
            name="msj error"
            autoComplete="false"
            className={classes.formButton}
            InputProps={{
              className: classes.inputError,
              endAdornment: (
                <>
                  <InputAdornment
                    position="start"
                    style={{ position: "absolute", marginLeft: "10px" }}
                  >
                    <Typography className={classes.passErr}>
                      {i18n.t("errPass")}
                    </Typography>
                  </InputAdornment>
                  {/*<InputAdornment position="end" style={{ cursor: "pointer" }}>
                    <Vectorx onClick={(e) => setErrorMsj(true)} />
              </InputAdornment>*/}
                </>
              ),
            }}
          />
        </Grid>
      )}

      {textError === true && (
        <Grid container item xs={12} md={12} lg={12} /*Error msj*/>
          <Grid className={classes.inputError2}>
            <Typography className={classes.passErr}>
              {i18n.t("blockedUser")}
            </Typography>
          </Grid>
        </Grid>
      )}
      {errorCountry === true && (
        <Grid container item={true} xs={12} md={12} lg={12} /*Error msj*/>
          <TextField
            fullWidth
            disabled
            id="msj error"
            name="msj error"
            autoComplete="false"
            className={classes.formButton}
            InputProps={{
              className: classes.inputError,
              endAdornment: (
                <>
                  <InputAdornment
                    position="start"
                    style={{ position: "absolute", marginLeft: "10px" }}
                  >
                    <Typography className={classes.passErr}>
                      Intenta loguearse con usuario de otro país
                    </Typography>
                  </InputAdornment>
                  {/*<InputAdornment position="end" style={{ cursor: "pointer" }}>
          <Vectorx onClick={(e) => setErrorMsj(true)} />
    </InputAdornment>*/}
                </>
              ),
            }}
          />
        </Grid>
      )}

      {loading && (
        <Dialog open={loading}>
          <div
            style={{
              padding: "20px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: "transparent",
            }}
          >
            <CircularProgress
              size={30}
              style={{ backgroundColor: "transparent" }}
            />
          </div>
        </Dialog>
      )}
      <Grid container item xs={12} md={12} lg={12} /*Remember me*/>
        <Grid
          container
          className={classes.checkGrid}
          justifyContent="space-between"
        >
          <Grid container xs={6} md={5} lg={4} justifyContent="start">
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    sx={{
                      color: "#131313",
                      "&.Mui-checked": {
                        color: "#00A692",
                      },
                    }}
                    checked={checkedEnter}
                    disableRipple
                  />
                }
                label={i18n.t("remember")}
                classes={classes.formCheck}
                onClick={handleCookie}
                onKeyDown={handleEnterRemember}
              />
            </FormGroup>
          </Grid>
          <Grid
            container
            xs={6}
            md={5}
            lg={4}
            justifyContent="end"
            className={classes.labelAlign}
          >
            <Link 
            underline="none" 
            color="#007868" 
            href={process.env.REACT_APP_CHANGE_RECOVER}
            onClick={()=> sendAmplitudeData("Login - Olvide Contraseña")}>
              <Typography className={classes.password} noWrap={true}>
                {i18n.t("forgot")}
              </Typography>
            </Link>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        container
        item
        xs={12}
        md={12}
        lg={12}
        justifyContent="center" /*Button*/
      >
        <Button
          className={classes.loginBtn}
          onClick={handleLogin}
          disabled={false}
          classes={classes.buttonText}
          disableRipple
        >
          <Typography classes={classes.buttonText}>{i18n.t("into")}</Typography>
        </Button>
      </Grid>
    </Box>
  );
}

export default LoginForm;
