import React, { useState, useEffect } from "react";
import MenuItem from "@mui/material/MenuItem";
import { Typography, TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { FlagIcon } from 'react-flag-kit';
const useStyles = makeStyles({
  topButton: {
    backgroundColor: "#FFFFFF",
    alignItems: "center",
    borderRadius: 50,
    height: 30,
    width: 100,
  },
  topMenuItem: {
    backgroundColor: "#FFFFFF",
    alignItems: "center",
    borderRadius: 50,
    height: 30,
  },
  topButtonText: {
    color: "#131313",
    fontFamily: "Work Sans, sans-serif",
    fontSize: "16px",
    fontWeight: 400,
    textAlign: "center",
    width: 50,
  },
});

const language = [
  {
    value: "ES",
    label: <FlagIcon code="ES" size={12} />,
  },
  {
    value: "EN",
    label: <FlagIcon code="GB" size={12}/>,
  },
];

function MenuLanguageComponent() {
  const [trasn, setTrasn] = useState("ES");

  const saveTrans = () => {
    localStorage.setItem("Languaje", trasn);
  }

  const handleChangeLanguaje = (event) => {
    setTrasn(event.target.value);

  };
 
  useEffect(() => {
    saveTrans()
  })

  const classes = useStyles();

  return (
    <TextField
      id="outlined-select-country"
      select
      outline="false"
      value={trasn}
      onChange={handleChangeLanguaje}
      onBlur={saveTrans}
      variant="standard"
      InputProps={{
        disableUnderline: true,
      }}    
             
      className={classes.topButton}
    >
      {language.map((option) => (
        <MenuItem
          key={option.value}
          value={option.value}
          className={classes.topMenuItem}
          
        >
          <Typography className={classes.topButtonText}>
            {option.label} {option.value}
          </Typography>
        </MenuItem>
      ))}
    </TextField>
  );
}

export default MenuLanguageComponent;
