import React, { useEffect } from "react";
import { createBrowserHistory } from "history";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import styled from "styled-components";
import Login from "./views/login";
import RegisterGoogle from "./views/registerGoogle";
import Register from "./views/register";
import RecoverPass from "./views/recoverPass";
import RecoverMailView from "./views/recoverMail";
import NewPassView from "./views/newPassword";
import CompletePassword from "./views/completePass";
import ExpiredPass from "./views/expiredPass";
//import DashBoard from "./views/Dashboard";
import ConfirmMailView from "./views/ConfirmMail";
import MailVerificationView from "./views/mailVerification";
import SuccessfullMailAuth from "./views/SuccessfullMailAuth";
import CheckLink from "./views/checkLink";
import { auth } from "./helpers/auth";
//import GuardedRoute from "./helpers/GuardedRoute";
import LinkAuth from "./views/LinkAuth";
import SecrectRoute from "./helpers/secrectRoute";
import CookieRoute from "./helpers/CookieRoute";
import { gapi } from "gapi-script";
//import LoginContext from "./Context/loginContex/loginContext";
import { initAmplitude } from "./helpers/amplitude";
import "./App.css";

const defaultHistory = createBrowserHistory();
const MainColumn = styled.div`
  margin: 0 auto;
`;
initAmplitude();

function App({ history = defaultHistory }) {
  useEffect(() => {
    function start() {
      gapi.client.init({
        clientid:
          "542719658258-gevjbf4sd89nsha2sdhpn278fnvq03p8.apps.googleusercontent.com",
        scope: "email",
      });
    }
    gapi.load("client:auth2", start);
  }, []);
  return (
    <Router>
      <MainColumn>
        {/*   */}
        {/* <LoginContext.Provider > */}
        <Switch>
          <Route exact path={process.env.REACT_APP_LOGIN} component={Login} />
          {/* <Route exact path="/v2/login/en" component={Login} />
          <Route exact path="/v2/login/es" component={Login} /> */}

          <Route
            exact
            path={process.env.REACT_APP_ACCOUNT_REGISTER}
            component={Register}
          />
          {/*  <Route exact path="/v2/login/register/en" component={Register} />
          <Route exact path="/v2/login/register/es" component={Register} />
 */}
          <Route
            exact
            path={process.env.REACT_APP_ACCOUNT_REGISTERSSO}
            component={RegisterGoogle}
          />
          {/*  <Route exact path="/v2/login/registersso/en" component={RegisterGoogle}/>
          <Route exact path="/v2/login/registersso/es" component={RegisterGoogle}/>
 */}
          <CookieRoute
            exact
            path={process.env.REACT_APP_MAIL_AUTH}
            component={MailVerificationView}
            auth={auth.loggedIn()}
          />
          {/*  <CookieRoute
            exact
            path="/v2/login/mailauth/en"
            component={MailVerificationView}
            auth={auth.loggedIn()}
          />
          <CookieRoute
            exact
            path="/v2/login/mailauth/es"
            component={MailVerificationView}
            auth={auth.loggedIn()}
          /> */}

          <Route
            exact
            path={process.env.REACT_APP_CHANGE_RECOVER}
            component={RecoverPass}
          />
          {/* <Route exact path="/v2/login/recoverpass/en" component={RecoverPass} />
          <Route exact path="/v2/login/recoverpass/es" component={RecoverPass} /> */}
          <Route
            exact
            path={process.env.REACT_APP_SEND_EMAIL}
            component={RecoverMailView}
          />
          {/*  <Route exact path="/v2/login/recovermailsend/en" component={RecoverMailView} />
          <Route exact path="/v2/login/recovermailsend/es"  component={RecoverMailView}/> */}

          <SecrectRoute
            exact
            path={process.env.REACT_APP_CHANGE_PASSWORD}
            component={NewPassView}
          />

          {/*  <SecrectRoute
            exact
            path="/v2/login/changepass/en"
            component={NewPassView}
          />
          <SecrectRoute
            exact
            path="/v2/login/changepass/es"
            component={NewPassView}
          /> */}

          <SecrectRoute
            exact
            path={process.env.REACT_APP_CCOMPLETE_PASS}
            component={CompletePassword}
          />
          {/*  <SecrectRoute
            exact
            path="/v2/login/completepass/en"
            component={CompletePassword}
          />
          <SecrectRoute
            exact
            path="/v2/login/completepass/es"
            component={CompletePassword}
          /> */}

          <SecrectRoute
            exact
            path={process.env.REACT_APP_EXPIRE_PASSWORD}
            component={ExpiredPass}
          />
          {/* <SecrectRoute
            exact
            path="/v2/login/expiredpass/en"
            component={ExpiredPass}
          />
          <SecrectRoute
            exact
            path="/v2/login/expiredpass/es"
            component={ExpiredPass}
          /> */}

          <CookieRoute
            exact
            path={process.env.REACT_APP_CONFIRM_EMAIL}
            component={ConfirmMailView}
          />
          {/*  <CookieRoute
            exact
            path="/v2/login/confirmemail/en"
            component={ConfirmMailView}
          />
          <CookieRoute
            exact
            path="/v2/login/confirmemail/es"
            component={ConfirmMailView}
          /> */}

          <CookieRoute
            exact
            path={process.env.REACT_APP_SUCCESS_AUTH}
            component={SuccessfullMailAuth}
          />
          {/*  <CookieRoute
            exact
            path="/v2/login/successauth/en"
            component={SuccessfullMailAuth}
          />
          <CookieRoute
            exact
            path="/v2/login/successauth/es"
            component={SuccessfullMailAuth}
          /> */}

          <Route
            exact
            path={process.env.REACT_APP_CHECK_LINK}
            component={CheckLink}
          />
          {/* <Route exact path="/v2/login/checklink/en" component={CheckLink} />
          <Route exact path="/v2/login/checklink/es" component={CheckLink} />
 */}
          <Route exact path={process.env.REACT_APP_LINK} component={LinkAuth} />
          {/*  <Route exact path="/v2/login/link/en" component={LinkAuth} />
          <Route exact path="/v2/login/link/es" component={LinkAuth} /> */}
        </Switch>
        {/*   </v2/loginContext.Provider> */}
        {/*  </Auth0Provider> */}
      </MainColumn>
    </Router>
  );
}

export default App;
