import React from 'react';

class CanonicalLink extends React.Component {
  constructor(props) {
    super(props);
    this.linkElement = document.createElement('link');
    this.linkElement.rel = 'canonical';
    this.linkElement.href = props.href;
  }

  componentDidMount() {
    const firstHeadElement = document.head.children[this.props?.position || 0];
    document.head.insertBefore(this.linkElement, firstHeadElement);
  }

  componentWillUnmount() {
    document.head.removeChild(this.linkElement);
  }

  render() {
    return null;
  }
}

export default CanonicalLink;
