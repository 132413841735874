import React, { useState, useRef, useEffect } from "react";
import { Typography, Grid } from "@material-ui/core";
import i18n from "./../locale/index";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
//import { ResendCode } from "../configHost";

const useStyles = makeStyles((theme) => ({
  registerColorFont: {
    fontFamily: "Work Sans, sans-serif",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "11px",
    lineHeight: "18.77px",
    alignItems: "center",
    textAlign: "center",
    color: "#00A490",
    textDecoration: "none",
    "&:hover": {
      textDecoration: "underline",
      color: "#00A490",
    },
  },

  labels: {
    color: "#131313",
    fontFamily: "Work Sans, sans-serif",
    fontSize: "11px",
    fontWeight: 400,
    marginRight: "5px",
  },
}));

const CountDown = () => {
  const classes = useStyles();
  // We need ref in this, because we are dealing
  // with JS setInterval to keep track of it and
  // stop it when needed
  const Ref = useRef(null);

  // The state for our timer
  const [timer, setTimer] = useState("00");

  const resendCode = (e) => {
    const bearerJWT = localStorage.getItem("jwt");

    fetch(process.env.REACT_APP_RESEND_CODE, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${bearerJWT}`,
      },
    }).catch((err) => {
      console.log(err);
    });
  };
  const getTimeRemaining = (e) => {
    const total = Date.parse(e) - Date.parse(new Date());
    const seconds = Math.floor((total / 1000) % 60);
    return {
      total,
      seconds,
    };
  };

  const startTimer = (e) => {
    let { total, seconds } = getTimeRemaining(e);
    if (total >= 0) {
      // update the timer
      // check if less than 10 then we need to
      // add '0' at the begining of the variable
      setTimer(seconds > 9 ? seconds : "0" + seconds);
    }
  };

  const clearTimer = (e) => {
    // If you adjust it you should also need to
    // adjust the Endtime formula we are about
    // to code next
    setTimer("60");
    if (Ref.current) clearInterval(Ref.current);
    const id = setInterval(() => {
      startTimer(e);
    }, 1000);
    Ref.current = id;
  };

  const getDeadTime = () => {
    let deadline = new Date();
    deadline.setSeconds(deadline.getSeconds() + 60);
    return deadline;
  };

  // We can use useEffect so that when the component
  // mount the timer will start as soon as possible

  // We put empty array to act as componentDid
  // mount only
  useEffect(() => {
    clearTimer(getDeadTime());
  }, []);

  function Reset() {
    resendCode();
    clearTimer(getDeadTime());
  }

  return (
    <div>
      {timer > "00" ? (
        <Grid
          container
          item
          direction="row"
          spacing="5px"
          className={classes.labels}
        >
          <Grid item>
            {" "}
            <Typography maxWidth="md" className={classes.labels}>
              {i18n.t("wait")}
            </Typography>
          </Grid>
          <Grid item>
            {" "}
            <Typography maxWidth="md" className={classes.labels}>
              {timer}
            </Typography>
          </Grid>
          <Grid item>
            <Typography maxWidth="md" className={classes.labels}>
              {i18n.t("seconds")}
            </Typography>
          </Grid>
        </Grid>
      ) : (
        <Grid container item className={classes.registerColorFont}>
          <Typography className={classes.registerColorFont} maxWidth="md">
            <Link
              underline="none"
              onClick={Reset}
              className={classes.registerColorFont}
            >
              {i18n.t("resendNow")}
            </Link>
          </Typography>
        </Grid>
      )}
    </div>
  );
};

export default CountDown;
