import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  MenuItem,
  TextField,
  Box,
  Link,
  Snackbar,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Collapse,
  InputBase,
} from "@mui/material";
import {
  Typography,
  InputAdornment,
  Button,
  Grid,
  CircularProgress,
} from "@material-ui/core";
import MuiAlert from "@mui/material/Alert";
import i18n from "./../locale/index";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import "./registerForm.css";
import ReactTooltip from "react-tooltip";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import Dialog from "@material-ui/core/Dialog";
import Cookies from "universal-cookie";
import { sendAmplitudeData } from "../helpers/amplitude";
//btn Register

// Img
import { ReactComponent as VectorPlus } from "./../assets/images/VectorPlus.svg";
import { ReactComponent as VectorCheck } from "./../assets/images/VectorCheck.svg";
import { ReactComponent as Ar } from "./../assets/images/argentina.svg";
import { ReactComponent as Uy } from "./../assets/images/uruguay.svg";
import { ReactComponent as Cl } from "./../assets/images/chile.svg";
import { ReactComponent as Vector } from "./../assets/images/Vector.svg";
import { ReactComponent as VectorEye } from "./../assets/images/VectorEye.svg";

const useStyles = makeStyles((theme) => ({
  formButton: {
    fontFamily: "Work Sans, sans-serif",
    color: "#131313",
    fontSize: "16px",
    fontWeight: 400,
    textAlign: "left",
    border: "1px solid rgba(19, 19, 19, 0.3)",
    borderRadius: 3,
    height: 45,
    padding: "5px 16px",
  },
  formButton2: {
    color: "#131313",
    fontSize: "16px",
    fontWeight: 400,
    textAlign: "left",
  },
  formButton3: {
    fontFamily: "Work Sans, sans-serif",
    color: "#131313",
    fontSize: "16px",
    fontWeight: 400,
    textAlign: "left",
    border: "1px solid rgba(19, 19, 19, 0.3)",
    borderRadius: 3,
    height: 45,
    padding: "5px 16px",
    marginBottom: "30px",
  },
  iconSuccess: {
    fill: "green",
    position: "absolute",
    top: "25px",
    right: "8px",
  },
  input: {
    height: 45,
  },
  dropdownPhone: {
    width: "320px !important",
    "@media (min-width: 780px)": {
      width: "380px",
    },
  },
  registerText: {
    fontFamily: "Work Sans, sans-serif",
    fontSize: "11px",
  },
  icons: {
    width: "15px",
  },
  validationTextGreen: {
    color: "#00a490",
  },
  validationTextRed: {
    color: "#ff2569",
  },
  validationText: {
    fontFamily: "Work Sans, sans-serif",
    color: "#ff2569",
    fontSize: "11px",
    marginTop: "5px",
  },
  validationText2: {
    color: "#ff2569",
    fontSize: "11px",
    marginTop: "15px",
    fontFamily: "Work Sans, sans-serif",
  },
  errorText: {
    height: 45,
    border: "1px solid #ff2569",
    borderRadius: 1,
  },
  registerColorFont: {
    fontFamily: "Work Sans, sans-serif",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "18.77px",
    color: "#00A490",
    marginRight: 10,
  },
  discountText: {
    fontFamily: "Work Sans, sans-serif",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "15px",
    lineHeight: "18.77px",
    color: "#00A490",
    marginRight: 10,
    cursor: "pointer",
  },
  registerIcon: {
    color: "#00A490",
    fontWeight: 300,
    cursor: "pointer",
    position: "relative",
    top: "-4px",
  },

  MuiCollapseRoot: {
    transitionDuration: "0",
  },
  MuiButtonText: {
    padding: "0",
  },
  formCheck: {
    fontFamily: "Work Sans, sans-serif",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "15px",
    lineHeight: "18.77px",
    alignItems: "center",
    textAlign: "center",
    color: "#131313",
  },
  registerColorFont2: {
    fontFamily: "Work Sans, sans-serif",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "18.77px",
    alignItems: "center",
    textAlign: "center",
    color: "#00A490",
    textDecoration: "none",
    marginLeft: 5,
    letterSpacing: "normal",
  },
  labelAlign: {
    alignSelf: "center",
    alignContent: "center",
    fontFamily: "Work Sans, sans-serif",
  },
  formGroupTerm: {
    marginRight: 0,
  },
  discountComponent: {
    // marginTop: 10,
    height: 20,
  },
  discountComponentForm: {
    marginTop: "10px",
  },
  loginBtn: {
    marginTop: theme.spacing(1),
    height: 50,
    width: 320,
    textTransform: "none",
    border: "2px solid",
    borderColor: "#00A692",
    backgroundColor: "#00A692",
    borderRadius: 50,
    color: "#fff",
    autoComplete: "false",
    fontFamily: "Work Sans, sans-serif",
    fontSize: "16px/22px",
    fontWeight: "normal",
    /* font: 'normal normal normal 16px/22px', */
    "&:hover": {
      backgroundColor: "#007868",
      borderColor: "#007868",
    },
    "@media (min-width: 780px)": {
      width: 380,
    },
  },
  loginBtn2: {
    marginTop: theme.spacing(1),
    height: 50,
    width: 320,
    textTransform: "none",
    border: "2px solid",
    borderColor: "#C6C6C6",
    backgroundColor: "#C6C6C6",
    borderRadius: 50,
    color: " #131313",
    autoComplete: "false",
    fontFamily: "Work Sans, sans-serif",
    fontSize: "16px/22px",
    fontWeight: "normal",
    /* font: 'normal normal normal 16px/22px', */

    "&:hover": {
      backgroundColor: "#007868",
      borderColor: "#007868",
    },
    "@media (min-width: 780px)": {
      width: 380,
    },
  },
  loginBtnText: {
    fontFamily: "Work Sans, sans-serif",
    fontSize: 16,
  },
  marginText: {
    marginTop: 5,
  },
  selectForm: {
    margin: 0,
  },
  borderClass: {
    "&.react-tel-input .form-control:focus": {
      border: "1px solid rgba(19, 19, 19, 0.3)",
      boxShadow: "none",
      borderRadius: 2,
    },
  },
  countrySelect: {
    backgroundColor: "red",
  },
  formTypografy: {
    color: "#131313",
    fontSize: "15px",
    fontWeight: 400,
    textAlign: "left",
    fontFamily: "Work Sans, sans-serif",
    height: "fit-content",
    marginLeft: "2px",
  },
  formSelect: {
    height: 45,
    alignContent: "center",
    padding: "1px 0",
  },
  formFlag: {
    margin: " 0 5px 0px 16px",
    display: "flex",
    alignSelf: "center",
  },
  formTypografy2: {
    color: "#131313",
    fontSize: "15px",
    fontWeight: 400,
    textAlign: "left",
    height: 30,
    padding: "5px 16px 0 16px",
    fontFamily: "Work Sans, sans-serif",

    marginLeft: "2px",
  },
  codeLabel: {
    marginBottom: "10px",
  },
}));

const countries = [
  {
    value: "Argentina",
    label: <Ar code="AR" />,
  },
  {
    value: "Chile",
    label: <Cl code="CL" />,
  },
  {
    value: "Uruguay",
    label: <Uy code="UY" />,
  },
];

const questions = [
  {
    value: `${i18n.t("meetUs")}`,
    disabled: true,
  },
  {
    value: `${i18n.t("google")}`,
    disabled: false,
  },
  {
    value: `${i18n.t("friends")}`,
    disabled: false,
  },
  {
    value: `${i18n.t("recomendation")}`,
    disabled: false,
  },
  {
    value: `${i18n.t("socialm")}`,
    disabled: false,
  },
  {
    value: `${i18n.t("event")}`,
    disabled: false,
  },
  {
    value: `${i18n.t("other")} `,
    disabled: false,
  },
];

const activities = [
  {
    value: `${i18n.t("actSelect")}`,
    name: `${i18n.t("actSelect")}`,
    disabled: true,
  },
  {
    value: `${i18n.t("actProducts")}`,
    name: `${i18n.t("actProducts")}`,
    disabled: false,
  },
  {
    value: `${i18n.t("actService")}`,
    name: `${i18n.t("actService")}`,
    disabled: false,
  },
  {
    value: `${i18n.t("actPandS")}`,
    name: `${i18n.t("actPandS")}`,
    disabled: false,
  },
  {
    value: `${i18n.t("actCount")}`,
    name: `${i18n.t("actCountName")}`,
    disabled: false,
  },
  {
    value: `${i18n.t("other")}`,
    name: `${i18n.t("other")}`,
    disabled: false,
  },
];

// Toast
const Alert = React.forwardRef(function Alert(props, ref) {
  return (
    <MuiAlert
      elevation={6}
      ref={ref}
      variant="filled"
      {...props}
      className="containerLoginBtn"
    />
  );
});

function capturedHost(host) {
  let countrySend = "";
  switch (host) {
    case "com":
      countrySend = "Argentina";
      break;
    case "ar":
      countrySend = "Argentina";
      break;
    case "cl":
      countrySend = "Chile";
      break;
    case "uy":
      countrySend = "Uruguay";
      break;
    default:
      countrySend = "Argentina";
      break;
  }
  return countrySend;
}

function RegisterForm(props) {
  const [country, setCountry] = React.useState(
    capturedHost(window.location.host.replace(/^.*\./, ""))
  );
  const [phone, setPhone] = React.useState("");
  const [question, setQuestion] = React.useState(`${i18n.t("meetUs")}`);
  const [eye, setEye] = useState(false);
  const [activity, setActivity] = React.useState(`${i18n.t("actSelect")}`);

  // value catched
  const [razon, setRazon] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [pass, setPass] = React.useState("");
  const [matchPass, setMatchPass] = React.useState("");

  // validation reason
  const [reasonVal, setReasonVal] = useState(true);
  const [reasonValBtn, setReasonValBtn] = useState(true);

  // validation email
  const [emailCh, setEmailCh] = useState(true);
  const [emailChBtn, setEmailChBtn] = useState(true);

  //Tooltip validation password
  const [eightCh, setEightCh] = useState(false);
  // const [maxCh, setMaxCh] = useState(false); //validation max characters
  const [oneCapital, setOneCapital] = useState(false);
  const [numberCh, setnumberCh] = useState(false);
  const [plusCh, setPlusCh] = useState(false);
  const [passValnumber, setPassValnumber] = useState(false);
  //const [maxChNumber, setMaxChNumber] = useState(false); //validation max characters
  const [oneCapitalVal, setOneCapitalVal] = useState(false);
  const [passnumberChVal, setPassNumberChVal] = useState(false);
  const [plusChVal, setPlusChVal] = useState(false);
  const [oneLowerCase, setOneLowercase] = useState(false);
  const [oneLowerCaseVal, setOneLowercaseVal] = useState(false);
  const [passEmpty, SetPassEmpty] = useState(false);
  const [loading, setloading] = useState(false);

  // validation match passwords
  const [matchPassVal, setMatchPassVal] = useState(false);
  const [matchPassEmpty, SetMatchPassEmpty] = useState(false);

  // validation phone
  const [phoneVal, setPhoneVal] = useState(true);
  const [phoneTest, setPhoneTest] = useState(true);
  const [phoneBtn, setPhoneBtn] = useState(false);
  const [countryPhone, setCountryPhone] = useState("ar");

  // activity
  const [activityBtn, setActivityBtn] = useState(false);
  const [activityErr, setActivityErr] = useState(false);

  // meet us
  const [meetUsBtn, setMeetUsBtn] = useState(false);
  const [meetUsErr, setMeetUsErr] = useState(false);

  // Discount code
  const [openCode, setOpenCode] = React.useState(false);
  const [code, setCode] = React.useState("");

  //btn register
  const [enabledBtn, setEnabledBtn] = useState(false);
  const [btnMatchPassVal, setBtnMatchPassVal] = useState(false);
  const [btnMatchPassEmpty, setBtnMatchPassEmpty] = useState(false);
  const [btnPlusCh, setBtnPlusCh] = useState(false);
  const [btnPassEmpty, setBtnPassEmpty] = useState(false);
  const [textError, setTextError] = useState("");
  //Change multi country text
  const [terminos, setTerminos] = useState(i18n.t("termin"));
  // check terminos
  const [checked, setChecked] = useState(true);
  const [checkedEnter, setCheckedEnter] = useState(false);

  // Toast
  const [openToast, setOpenToast] = useState(false);
  const classes = useStyles();

  const handleTextMulticountry = (host) => {
    let region = i18n.t("termin");
    switch (host) {
      case "com":
        region = i18n.t("termin");
        break;
      case "ar":
        region = i18n.t("termin");
        break;
      case "cl":
        region = i18n.t("terminChUy");
        break;
      case "uy":
        region = i18n.t("terminChUy");
        break;
      default:
        region = i18n.t("termin");
        break;
    }
    return region;
  };
  // Country validation
  const saveCountry = () => {
    localStorage.setItem("country", country);
  };
  useEffect(() => {
    saveCountry();
  }, [country]);

  useEffect(() => {
    const extUrl = window.location.host.replace(/^.*\./, "");
    setTerminos(handleTextMulticountry(extUrl));

    if (country === "Argentina") {
      setCountryPhone("ar");
    }
    if (country === "Chile") {
      setCountryPhone("cl");
    }
    if (country === "Uruguay") {
      setCountryPhone("uy");
    }
  }, [terminos]);

  const handleChangeCountry = (event) => {
    setCountry(event.target.value);
    if (event.target.value === "Argentina") {
      setCountryPhone("ar");
      window.open(process.env.REACT_APP_BASE_URL_AR + "register", "_self");
    }
    if (event.target.value === "Chile") {
      setCountryPhone("cl");
      window.open(process.env.REACT_APP_BASE_URL_CL + "register", "_self");
    }
    if (event.target.value === "Uruguay") {
      setCountryPhone("uy");
      window.open(
        process.env.REACT_APP_BASE_URL_UY + "/" + "register",
        "_self"
      );
    }
  };

  const handleCountry = (country) => {
    let countrySend = "";
    switch (country) {
      case "Argentina":
        countrySend = "ar";

        break;
      case "Chile":
        countrySend = "cl";

        break;
      case "Uruguay":
        countrySend = "uy";

        break;
      default:
        countrySend = "ar";
        break;
    }
    return countrySend;
  };

  // Business name validation

  const handleValidateReason = (event) => {
    setRazon(event.target.value);

    if (razon === "") {
      //setReasonVal(false);
      setReasonValBtn(false);
    } else {
      setReasonVal(true);
      setReasonValBtn(true);
    }
    localStorage.setItem("name", razon);
  };
  const handleValidateReasonEmpty = () => {
    if (razon === "") {
      setReasonVal(false);
      setReasonValBtn(false);
    } else {
      setReasonVal(true);
      setReasonValBtn(true);
    }
  };
  // Email validation
  const handleValidateEmail = (event) => {
    setEmail(event.target.value);
    if (/\S+@\S+\.\S+/.test(event.target.value)) {
      setEmailCh(true);
      //  setEmailChBtn(true);
    } else {
      setEmailCh(false);
      //  setEmailChBtn(false);
    }
    localStorage.setItem("email", email);
  };

  // Password validation
  const handleValidatePassword = (event) => {
    setPass(event.target.value);

    if (event.target.value.length >= 8) {
      setEightCh(true);
      setPassValnumber(false);
    } else {
      setEightCh(false);
      setPassValnumber(true);
    }
    if (/[A-Z]/.test(event.target.value)) {
      setOneCapital(true);
      setOneCapitalVal(false);
    } else {
      setOneCapital(false);
      setOneCapitalVal(true);
    }
    if (/[0-9]/.test(event.target.value)) {
      setnumberCh(true);
      setPassNumberChVal(false);
    } else {
      setnumberCh(false);
      setPassNumberChVal(true);
    }
    if (/[+]/.test(event.target.value)) {
      setPlusCh(true);
      setPlusChVal(true);
      setBtnPlusCh(false);
    } else {
      setPlusCh(false);
      setPlusChVal(false);
      setBtnPlusCh(true);
    }
    if (/[a-z]/.test(event.target.value)) {
      setOneLowercase(true);
      setOneLowercaseVal(false);
    } else {
      setOneLowercase(false);
      setOneLowercaseVal(true);
    }
  };

  useEffect(() => {
    // Business name validation
    if (razon === "") {
      setReasonValBtn(false);
    } else {
      setReasonValBtn(true);
    }

    // Email
    if (/\S+@\S+\.\S+/.test(email)) {
      setEmailCh(true);
      setEmailChBtn(true);
    } else {
      //  setEmailCh(false);
      setEmailChBtn(false);
    }

    if (pass !== matchPass) {
      setMatchPassVal(true);
    } else {
      setMatchPassVal(false);
    }

    if (phoneTest === true) {
      setPhoneVal(true);
      setPhoneBtn(true);
    } else {
      setPhoneBtn(false);
    }
  }, [email, matchPass, pass, phoneTest, razon]);

  const handleEmptypass = () => {
    if (pass === "") {
      SetPassEmpty(true);
      setBtnPassEmpty(true);
    } else {
      SetPassEmpty(false);
      setBtnPassEmpty(false);
    }
    if (pass !== matchPass) {
      setMatchPassVal(true);
    } else {
      setMatchPassVal(false);
    }
  };

  // Password match validation
  const handlePasswordsMatch = (e) => {
    if (pass !== matchPass) {
      setMatchPassVal(true);
      setBtnMatchPassVal(false);
    } else {
      setMatchPassVal(false);
      setBtnMatchPassVal(true);
    }
    if (matchPass === "") {
      SetMatchPassEmpty(true);
      setBtnMatchPassEmpty(false);
    } else {
      SetMatchPassEmpty(false);
      setBtnMatchPassEmpty(true);
    }
    localStorage.setItem("password", e.target.value);
  };
  useEffect(() => {
    if (pass !== matchPass) {
      setMatchPassVal(true);
      setBtnMatchPassVal(false);
    } else {
      setMatchPassVal(false);
      setBtnMatchPassVal(true);
    }
    if (pass === "") {
      //  SetPassEmpty(true);
      setBtnPassEmpty(true);
    } else {
      //  SetPassEmpty(false);
      setBtnPassEmpty(false);
    }
  }, [matchPass, pass]);

  //Activity

  const handleChangeActivity = (event) => {
    setActivity(event.target.value);
  };

  const saveActivity = (e) => {
    localStorage.setItem("activity", activity);
  };

  useEffect(() => {
    saveActivity();
    if (activity === "Seleccione su actividad") {
      setActivityBtn(false);
    } else {
      setActivityBtn(true);
    }
  }, [activity]);

  //Meet us validation
  const handleChangeQuestion = (event) => {
    setQuestion(event.target.value);
  };

  const saveQuestion = (e) => {
    localStorage.setItem("question", question);
  };

  useEffect(() => {
    saveQuestion();
    if (question === "¿Cómo nos conociste?") {
      setMeetUsBtn(false);
    } else {
      setMeetUsBtn(true);
      setMeetUsErr(false);
    }
  }, [question]);

  // Discount code
  const handleClickCode = () => {
    setOpenCode(!openCode);
  };
  const handleChangeCode = (event) => {
    setCode(event.target.value);
  };

  const handlerTer = (e) => {
    if (checked === false) {
      setChecked(true);
      setCheckedEnter(false);
    } else if (checked === true) {
      setChecked(false);
      setCheckedEnter(true);
    }
    localStorage.setItem("termino", checked);
  };
  // Toast

  const handleClickToast = () => {
    setOpenToast(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenToast(false);
  };

  //Validation btn
  useEffect(() => {
    if (
      reasonValBtn === true &&
      emailChBtn === true &&
      passValnumber === false &&
      oneCapitalVal === false &&
      passnumberChVal === false &&
      btnPlusCh === true &&
      oneLowerCaseVal === false &&
      btnPassEmpty === false &&
      btnMatchPassVal === true &&
      checked === false &&
      phoneBtn === true
    ) {
      setEnabledBtn(true);
    } else {
      setEnabledBtn(false);
    }
  }, [
    reasonValBtn,
    emailChBtn,
    passValnumber,
    oneCapitalVal,
    passnumberChVal,
    btnPlusCh,
    oneLowerCaseVal,
    btnPassEmpty,
    btnMatchPassVal,
    checked,
    phoneBtn,
  ]);

  // Btn Register catch information
  const handleLogin = (e) => {
    // e.preventDefault();
    handleCountry(country);
    sendAmplitudeData("Register_Intent");
    setloading(true);
    const URI = process.env.REACT_APP_ACCOUNT_LOGIN;
    fetch(process.env.REACT_APP_ACCOUNTREGISTER, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        country: handleCountry(country),
        name: razon,
        email: email,
        password: pass,
        phone: phone,
        promotion_code: code,
        terms_accepted: true,
      }),
    })
      .then((response) => {
        if (response !== "") {
          return response.json();
        } else {
          sendAmplitudeData("Register_Failed");
          return null;
        }
      })
      .then((response) => {
        if (response.code !== "user_created") {
          if (response.code === "email_exists") {
            setloading(false);
            handleClickToast();
            sendAmplitudeData("Register_Failed");
            setTextError("El correo utilizado ya esta registrado");
          }
          if (response.code === "password_wrong_policy") {
            setloading(false);
            handleClickToast();
            sendAmplitudeData("Register_Failed");
            setTextError(
              "La contraseña no cumple las validaciones sugeridas  "
            );
          }
          if (response.code === "email_empty_error") {
            setloading(false);
            handleClickToast();
            sendAmplitudeData("Register_Failed");
            setTextError("El correo no puede estar vacio ");
          }
          if (response.code === "meet_us_by_empty_error") {
            setloading(false);
            handleClickToast();
            sendAmplitudeData("Register_Failed");
            setTextError("Razon Social no puede estar vacio ");
          }
          if (response.code === "phone_empty_error") {
            setloading(false);
            handleClickToast();
            sendAmplitudeData("Register_Failed");
            setTextError("Teléfono no puede estar vacio ");
          }
          if (response.code === "terms_accepted_empty_error") {
            setloading(false);
            handleClickToast();
            sendAmplitudeData("Register_Failed");
            setTextError("Aceptar términos es obligatorio ");
          }
          if (response.code === "country_error") {
            setloading(false);
            handleClickToast();
            sendAmplitudeData("Register_Failed");
            setTextError("Pais erroneo");
          }
          if (
            response.error === "An account with that email, already exists."
          ) {
            setloading(false);
            handleClickToast();
            sendAmplitudeData("Register_Failed");
            setTextError("El correo utilizado ya esta registrado");
          }
          if (
            response.error ===
            "The password must contain at least 8 characters. At least one number. At least an upper letter. At least lower letter"
          ) {
            setloading(false);
            handleClickToast();
            sendAmplitudeData("Register_Failed");
            setTextError("Contraseña inválida");
          }
        } else {
          if (textError === "") {
            fetch(URI, {
              method: "post",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                email: email,
                password: pass,
                country: handleCountry(country),
              }),
            })
              .then((response) => {
                if (response !== "") {
                  return response.json();
                } else {
                  return null;
                }
              })
              .then((response) => {
                if (response.error) {
                  //text
                  setloading(false);
                  handleClickToast();
                } else {
                  const cookies = new Cookies();
                  let base64Url = response.jwt.split(".")[1];
                  let base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
                  let jsonPayload = decodeURIComponent(
                    atob(base64)
                      .split("")
                      .map(function (c) {
                        return (
                          "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2)
                        );
                      })
                      .join("")
                  );
                  let jsonParse = JSON.parse(jsonPayload);
                  localStorage.setItem("jwt", response.jwt);
                  localStorage.setItem("validated", jsonParse.data.verified);
                  cookies.set("Secure-1CBL", response.jwt, {
                    path: "/",
                    expire: jsonParse.data.exp,
                  });
                  sendAmplitudeData("Register_Success", {
                    Reference: localStorage.getItem("question"),
                  });
                  setloading(false);
                  localStorage.removeItem("question");
                  localStorage.removeItem("name");
                  localStorage.removeItem("phone");
                  window.open(process.env.REACT_APP_MAIL_AUTH, "_self");
                }
              })
              .catch((err) => {
                setloading(false);
                handleClickToast(err);
                console.log(err);
              });
          }
        }
      });

    // }
  };

  //Enter
  useEffect(
    (event) => {
      if (enabledBtn === true) {
        const handleEnter = (event) => {
          if (event.key === "Enter") {
            handleLogin();
          }
        };

        window.addEventListener("keydown", handleEnter);
        return () => {
          window.removeEventListener("keydown", handleEnter);
        };
      }
    },
    [enabledBtn]
  );
  // enter remember me
  const handleEnterRemember = (event) => {
    if (event.key === "Enter") {
      setCheckedEnter(!checkedEnter);
    }
  };

  useEffect(() => {
    if (checkedEnter === true) {
      setChecked(false);
    } else {
      setChecked(true);
    }
  }, [checkedEnter]);

  return (
    <Box
      component="form"
      sx={{
        "& .MuiGrid-item": {
          mt: 1.5,
          width: "90vw",
          maxWidth: "320px",
          "@media (min-width: 780px)": {
            maxWidth: "380px",
          },
        },
        legend: { display: "none" },
        "& 	.MuiInputBase-input": {
          fontFamily: "Work Sans, sans-serif",
          lineHeight: "17.6px",
          fontSize: "15px",
        },
        "& 	.MuiFormControlLabel-label": {
          fontFamily: "Work Sans, sans-serif",
          lineHeight: "16.42px",
          fontSize: "14px",
        },
        "& 	.MuiButton-text .MuiTypography-root": {
          fontFamily: "Work Sans, sans-serif",
          lineHeight: "18.77px",
          fontSize: "15px",
        },
        "& 	fieldset": {
          height: "45px",
          marginTop: "5px",
        },
        "& 	input": {
          height: "45px",
          paddingBottom: 0,
          paddingTop: 0,
        },
        "& input:-webkit-autofill": {
          transition: "all 5000s ease-in-out 0s",
        },
        ".css-md26zr-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
          { border: "1px solid rgba(0, 0, 0, 0.23)" },
        ".css-md26zr-MuiInputBase-root-MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
          { border: "1px solid rgba(0, 0, 0, 0.23)" },

        "& 	.css-9n8air-MuiButtonBase-root-MuiCheckbox-root.Mui-checked, .css-9n8air-MuiButtonBase-root-MuiCheckbox-root.MuiCheckbox-indeterminate":
          {
            color: "#00A692",
          },
        "& 	.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input":
          {
            padding: "0",
          },
        "& 	.css-7dqvty-MuiInputBase-input": {
          paddingTop: 0,
        },
        "& 	.css-6hp17o-MuiList-root-MuiMenu-list": {
          paddingTop: "0px",
          paddingBottom: "0px",
        },
        "& .css-9n8air-MuiButtonBase-root-MuiCheckbox-root:hover": {
          backgroundColor: "rgba(0, 0, 0, 0)",
        },
        "& .react-tel-input .form-control.invalid-number": {
          border: "1px solid #cacaca",
        },
        "& .css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root": {
          paddingLeft: "0px!important",
        },
      }}
      noValidate
      autoComplete="off"
    >
      <Grid
        container
        item
        xs={12}
        md={12}
        lg={12}
        className={classes.selectForm} /*Country*/
      >
        <TextField
          id="outlined-select-country"
          select
          fullWidth
          className={classes.formButton2}
          value={country}
          onChange={handleChangeCountry}
          onBlur={saveCountry}
          InputProps={{
            className: classes.input,
          }}
          sx={{ "& css-hdw1oc": { display: "none" } }}
        >
          {countries.map((option) => (
            <MenuItem key={option.value} value={option.value} disableRipple>
              <Grid
                container
                xs={12}
                md={12}
                lg={12}
                className={classes.formSelect}
              >
                <Grid className={classes.formFlag}>{option.label}</Grid>

                <Typography className={classes.formTypografy}>
                  {option.value}
                </Typography>
              </Grid>
            </MenuItem>
          ))}
        </TextField>
      </Grid>
      <Grid container item xs={12} md={12} lg={12} /*Business name*/>
        <InputBase
          placeholder={i18n.t("reason")}
          fullWidth
          size="small"
          id="social"
          name="social"
          inputProps={{
            classes: {
              input: classes.formButton,
            },
            className: classes.input,
            maxLength: 255,
          }}
          sx={
            reasonVal === false
              ? { border: "1px solid #ff2569", borderRadius: 1 }
              : { className: classes.input2 }
          }
          autoComplete="false"
          className={classes.formButton}
          onChange={handleValidateReason}
          onBlur={handleValidateReasonEmpty}
        />
        {reasonVal === false && (
          <Grid>
            <Typography className={classes.validationText}>
              {i18n.t("reqField")}
            </Typography>
          </Grid>
        )}
      </Grid>
      <Grid container item xs={12} md={12} lg={12} /*Email*/>
        <InputBase
          placeholder="Email *"
          fullWidth
          size="small"
          id="email"
          name="name"
          className={classes.formButton}
          sx={
            emailCh === false
              ? { border: "1px solid #ff2569", borderRadius: 1 }
              : { className: classes.input2 }
          }
          autoComplete="false"
          onChange={(e) => {
            setEmail(e.target.value);
          }}
          onBlur={handleValidateEmail}
        />
        {emailCh === false && (
          <Grid>
            <Typography className={classes.validationText}>
              {i18n.t("emaiCh")}
            </Typography>
          </Grid>
        )}
      </Grid>
      <Grid container item xs={12} md={12} lg={12} /*Pass 1*/>
        <InputBase
          placeholder={i18n.t("newPass")}
          fullWidth
          type={eye === false ? "password" : "text"}
          id="password"
          name="name"
          autoComplete="false"
          data-tip
          data-for="registerTip"
          inputProps={{
            maxLength: 255,
          }}
          className={classes.formButton}
          onChange={handleValidatePassword}
          onFocus={handleValidatePassword}
          onBlur={handleEmptypass}
          sx={
            passValnumber === true ||
            oneCapitalVal === true ||
            passnumberChVal === true ||
            plusChVal === true ||
            oneLowerCaseVal === true ||
            matchPassEmpty === true ||
            matchPassVal === true
              ? { border: "1px solid #ff2569", borderRadius: 1 }
              : { className: classes.input2 }
          }
          endAdornment={
            <InputAdornment position="end" style={{ cursor: "pointer" }}>
              {eye === false ? (
                <VectorEye onClick={(e) => setEye(true)} />
              ) : (
                <Vector onClick={(e) => setEye(false)} />
              )}
            </InputAdornment>
          }
        />
        <ReactTooltip
          id="registerTip"
          place="right"
          effect="solid"
          backgroundColor="#FFFFFF"
          border="true"
          borderColor="#C6C6C6"
        >
          <Box
            className={
              eightCh === true
                ? classes.validationTextGreen
                : classes.validationTextRed
            }
            sx={{ flexDirection: "row" }}
          >
            {eightCh === true ? (
              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                className={classes.marginText}
              >
                <VectorCheck style={{ width: "15px", marginRight: "10px" }} />

                <Typography className={classes.registerText}>
                  {i18n.t("characters")}
                </Typography>
              </Grid>
            ) : (
              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                className={classes.marginText}
              >
                <VectorPlus style={{ width: "15px", marginRight: "10px" }} />

                <Typography className={classes.registerText}>
                  {i18n.t("characters")}
                </Typography>
              </Grid>
            )}
          </Box>
          <Box
            className={
              oneCapital === true
                ? classes.validationTextGreen
                : classes.validationTextRed
            }
            sx={{ flexDirection: "row" }}
          >
            {oneCapital === true ? (
              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                className={classes.marginText}
              >
                <VectorCheck style={{ width: "15px", marginRight: "10px" }} />
                <Typography className={classes.registerText}>
                  {i18n.t("oneCapital")}
                </Typography>
              </Grid>
            ) : (
              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                className={classes.marginText}
              >
                <VectorPlus style={{ width: "15px", marginRight: "10px" }} />
                <Typography className={classes.registerText}>
                  {i18n.t("oneCapital")}
                </Typography>
              </Grid>
            )}
          </Box>
          <Box
            className={
              oneLowerCase === true
                ? classes.validationTextGreen
                : classes.validationTextRed
            }
            sx={{ flexDirection: "row" }}
          >
            {oneLowerCase === true ? (
              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                className={classes.marginText}
              >
                <VectorCheck style={{ width: "15px", marginRight: "10px" }} />
                <Typography className={classes.registerText}>
                  {i18n.t("oneletter")}
                </Typography>
              </Grid>
            ) : (
              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                className={classes.marginText}
              >
                <VectorPlus style={{ width: "15px", marginRight: "10px" }} />
                <Typography className={classes.registerText}>
                  {i18n.t("oneletter")}
                </Typography>
              </Grid>
            )}
          </Box>
          <Box
            className={
              numberCh === true
                ? classes.validationTextGreen
                : classes.validationTextRed
            }
            sx={{ flexDirection: "row" }}
          >
            {numberCh === true ? (
              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                className={classes.marginText}
              >
                <VectorCheck style={{ width: "16px", marginRight: "10px" }} />
                <Typography className={classes.registerText}>
                  {i18n.t("numberCh")}
                </Typography>
              </Grid>
            ) : (
              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                className={classes.marginText}
              >
                <VectorPlus style={{ width: "16px", marginRight: "10px" }} />
                <Typography className={classes.registerText}>
                  {i18n.t("numberCh")}
                </Typography>
              </Grid>
            )}
          </Box>
        </ReactTooltip>
        {plusCh === true && (
          <Grid>
            <Typography
              className={classes.validationText}
              style={{ marginRight: "10px" }}
            >
              {i18n.t("plusCh")}
            </Typography>
          </Grid>
        )}
        {passEmpty === true && (
          <Grid>
            <Typography className={classes.validationText}>
              {i18n.t("reqField")}
            </Typography>
          </Grid>
        )}
      </Grid>
      <Grid container item xs={12} md={12} lg={12} /*Pass 2*/>
        <InputBase
          placeholder={i18n.t("confirmPass")}
          fullWidth
          type={eye === false ? "password" : "text"}
          id="password"
          name="name"
          autoComplete="false"
          inputProps={{
            maxLength: 255,
          }}
          sx={
            matchPassVal === true || matchPassEmpty === true
              ? { border: "1px solid #ff2569", borderRadius: 1 }
              : { className: classes.input2 }
          }
          className={classes.formButton}
          onChange={(e) => setMatchPass(e.target.value)}
          onBlur={handlePasswordsMatch}
        />
        {matchPassVal === true && (
          <Grid>
            <Typography
              className={classes.validationText}
              style={{ marginRight: "10px" }}
            >
              {i18n.t("matchPass")}
            </Typography>
          </Grid>
        )}
        {matchPassEmpty === true && (
          <Grid>
            <Typography className={classes.validationText}>
              {i18n.t("reqField")}
            </Typography>
          </Grid>
        )}
      </Grid>
      <Grid container item xs={12} md={12} lg={12} height="61px" /*Phone*/>
        <PhoneInput
          isValid={(value, country) => {
            if (/.{11,}/.test(phone)) {
              setPhoneTest(true);
            } else {
              setPhoneTest(false);
            }
          }}
          inputStyle={{
            height: 45,
            width: "100%",
            fontSize: "15px",
            fontFamily: "Work Sans, sans-serif",
          }}
          inputClass={phoneVal === false ? "input2" : "input1"}
          containerClass={classes.borderClass}
          buttonStyle={{
            border: "5px",
            margin: "3px",
            backgroundColor: "white",
            paddingLeft: "8px",
            fontFamily: "Work Sans, sans-serif"
          }}
          id="phone"
          name="phone"
          value={phone}
          country={countryPhone}
          placeholder="phone"
          onChange={(phone) => {
            setPhone(phone);
          }}
          onBlur={(phone) => {
            if (phoneTest === true) {
              setPhoneVal(true);
              setPhoneBtn(true);
              localStorage.setItem("phone", phone.currentTarget.value);
            } else {
              setPhoneVal(false);
              setPhoneBtn(false);
            }
          }}
          preferredCountries={["ar", "uy", "cl"]}
          masks={{
            ar: "(...) ....-....",
            uy: "(....) ....", //+598 (4364) 1234
            cl: "(.) ....-....", //+56 9 9771 6480
          }}
          dropdownStyle={{ width: "320px" }}
        />
        {phoneVal === false && (
          <Grid>
            <Typography className={classes.validationText}>
              {i18n.t("reqField")}
            </Typography>
          </Grid>
        )}
      </Grid>

      <Grid container item xs={12} md={12} lg={12} /*Discount code*/>
        <Box
          component="form"
          sx={{
            "& .MuiTextField-root": {
              width: "90vw",
              maxWidth: "320px",
              margin: "0",
              "@media (min-width: 780px)": {
                maxWidth: "380px",
              },
            },
          }}
          noValidate
          autoComplete="false"
          className={classes.discountComponentForm}
        >
          <Grid
            onClick={handleClickCode}
            container
            direction="row"
            alignContent="center"
            className={classes.discountComponent}
          >
            <Typography underline="none" className={classes.discountText}>
              {i18n.t("discountcode")}
            </Typography>
            {openCode ? (
              <ExpandLess className={classes.registerIcon} />
            ) : (
              <ExpandMore className={classes.registerIcon} />
            )}
          </Grid>
          <Grid container item xs={12} md={12} lg={12}>
            <Collapse in={openCode}>
              <Grid className={classes.codeLabel}>
                <TextField
                  id="outlined-select-country"
                  placeholder={i18n.t("code")}
                  fullWidth
                  className={classes.formButton3}
                  value={code}
                  onChange={handleChangeCode}
                  InputProps={{
                    className: classes.input,
                  }}
                  //className={classes.codeLabel}
                ></TextField>
              </Grid>
            </Collapse>
          </Grid>
        </Box>
      </Grid>
      <Grid xs={12} md={12} lg={12} /*Terms and condition*/>
        <Grid container direction="row" alignContent="center" bac>
          <FormGroup
            className={classes.formGroupTerm}
            row={true}
            sx={{
              "& .Mui-checked": {
                color: "#00A692!important",
              },
            }}
          >
            <Grid mr={0}>
              {" "}
              <FormControlLabel
                control={
                  <Checkbox
                    sx={{ color: "#131313" }}
                    onChange={handlerTer}
                    checked={checkedEnter}
                    disableRipple
                  />
                }
                label={i18n.t("iaccept")}
                onKeyDown={handleEnterRemember}
                className={classes.formCheck}
                sx={{
                  mr: 0,
                  "& .MuiTypography-root": {
                    fontFamily: "Work Sans, sans-serif",
                    fontSize: 14,
                  },
                }}
              />
            </Grid>
            <Grid className={classes.labelAlign}>
              {" "}
              <Link
                underline="none"
                alignItems="center"
                href="https://contabilium.com/terminos-de-uso"
              >
                <Typography
                  align="center"
                  className={classes.registerColorFont2}
                >
                  {i18n.t("terms")}
                </Typography>
              </Link>
            </Grid>
          </FormGroup>
        </Grid>
        {checked === true && (
          <Grid>
            <Typography className={classes.validationText}>
              {terminos}
            </Typography>
          </Grid>
        )}
      </Grid>

      <Grid container items xs={12} md={12} lg={12}>
        {loading && (
          <Dialog open={loading}>
            <div
              style={{
                padding: "20px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: "transparent",
              }}
            >
              <CircularProgress
                size={30}
                style={{ backgroundColor: "transparent" }}
              />
            </div>
          </Dialog>
        )}
      </Grid>
      <Grid
        container
        item
        xs={12}
        md={12}
        lg={12}
        className={classes.containerLoginBtn} /*Button*/
      >
        {enabledBtn === false ? (
          <Button
            className={classes.loginBtn2}
            onClick={handleLogin}
            disabled={true}
            disableRipple
          >
            <Typography>{i18n.t("startNow")}</Typography>
          </Button>
        ) : (
          <Button
            className={classes.loginBtn}
            onClick={handleLogin}
            disabled={false}
            disableRipple
          >
            <Typography>{i18n.t("startNow")}</Typography>
          </Button>
        )}
      </Grid>
      <Snackbar open={openToast} autoHideDuration={60000} /*Toast*/>
        <Alert
          onClose={handleClose}
          severity="error"
          sx={{ width: "100%", backgroundColor: "#F32465" }}
        >
          {textError}
        </Alert>
      </Snackbar>
    </Box>
  );
}

export default RegisterForm;
