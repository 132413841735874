import React, { Component } from "react";
import { Grid, Typography } from "@material-ui/core";
/* import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox'; */
import Link from "@mui/material/Link";
import MenuLanguageComponent from "../components/MenuLanguage";
import { withStyles } from "@material-ui/core/styles";
import { ReactComponent as Google } from "../assets/images/google.svg";
import i18n from "./../locale/index";
import RegisterFormGoogle from "../components/RegisterFormGoogle";

//import TermsAndConditionsComponent from "../components/TermsAndConditions";
//import DiscountCodeComponent from "../components/DiscountCode";
const IMAGE_LOGIN_PATH = "login_bg.png";

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    height: "100vh",
    backgroundColor: "#FFFFFF",
  },
  grid1: {
    backgroundColor: "#FFFFFF",
    alignContent: "center",
  },
  googleLogin: {
    marginBottom: theme.spacing(1),
  },
  mainTitle: {
    marginTop: "4%",
  },
  line: {
    margin: 15,
  },
  lineText: {
    marginBottom: theme.spacing(2),
    fontFamily: "Work Sans, sans-serif",
    fontStyle: "",
    fontWeight: 550,
    fontSize: "19px",
    lineHeight: "18.77px",
    alignItems: "center",
    textAlign: "left",
    color: "#131313",
    marginTop: 5,
  },
  lineText2: {
    fontFamily: "Work Sans, sans-serif",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "17px",
    lineHeight: "18.77px",
    alignItems: "center",
    textAlign: "left",
    color: "#131313",
    marginTop: 5,
  },
  googleFont: {
    marginLeft: theme.spacing(3),
    fontFamily: "Work Sans, sans-serif",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "32px",
    lineHeight: "18.77px",
    color: "#131313",
  },
  googleBtn: {
    marginTop: theme.spacing(4),
    height: 50,
    width: 380,
    textTransform: "none",
    border: "0px solid",
    borderColor: "#131313",
    borderRadius: 15,
    color: "black",
    autoComplete: "off",
    font: "normal normal normal 16px/22px WorkSans",
  },
  registerFont: {
    marginRight: theme.spacing(1),
    fontFamily: "Work Sans, sans-serif",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "16.42px",
    color: "#131313",
  },
  loginBtn: {
    marginTop: theme.spacing(1),
    height: 50,
    width: 350,
    textTransform: "none",
    border: "2px solid",
    borderColor: "#00C7AF",
    backgroundColor: "#00C7AF",
    borderRadius: 30,
    color: "white",
    autoComplete: "off",
    font: "normal normal normal 16px/22px WorkSans",
  },
  formCheck: {
    fontFamily: "Work Sans, sans-serif",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "18.77px",
    alignItems: "center",
    textAlign: "center",
    color: "#131313",
    marginRight: 5,
  },
  password: {
    fontFamily: "Work Sans, sans-serif",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "18.77px",
    alignItems: "center",
    textAlign: "center",
    color: "#00A490",
  },
  checkGrid: {
    alignItems: "center",
  },
  topButton: {
    backgroundColor: "#FFFFFF",
    alignItems: "center",
    borderRadius: 50,
    height: 60,
    width: 140,
  },
  topMenuItem: {
    backgroundColor: "#FFFFFF",
    alignItems: "center",
    borderRadius: 50,
    height: 50,
    width: 140,
  },
  topButtonText: {
    color: "#131313",
    fontFamily: "Work Sans, sans-serif",
    fontSize: "16px",
    marginTop: 15,
    fontWeight: 400,
    textAlign: "center",
    width: 96,
  },
  topdiv: {
    position: "absolute",
    top: 20,
    right: 30,
  },
  googleLogin2: {
    marginBottom: 10,
    marginTop: 20,
  },
  registerColorFont: {
    marginLeft: theme.spacing(20),
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "18.77px",
    alignItems: "center",
    textAlign: "center",
    color: "#00A490",
  },
  registerColorFont2: {
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "18.77px",
    color: "#00A490",
    marginLeft: 3,
  },
  BaseGrid: {
    marginTop: 5,
    marginBottom: 10,
  },
});

class RegisterGoogle extends Component {
  constructor(props) {
    super(props);
    this.state = {
      windowWidth: window.innerWidth,
    };
  }

  handleResize = (e) => {
    this.setState({ windowWidth: window.innerWidth });
  };
  componentDidMount() {
    window.addEventListener("resize", this.handleResize);
  }
  render() {
    function getWindowDimensions() {
      const { innerWidth: width } = window;
      return {
        width,
      };
    }

    const { classes } = this.props;
    const { width } = getWindowDimensions();

    // se agregan variables para que se muestre similar al mockup, luego habria que eliminarlo
    const usuario = "Emanuel Nocera";
    const correo = "ema.nocera.89@gmail.com";

    return (
      <div style={{ backgroundColor: "#E5E5E5" }}>
        <Grid
          container
          className={classes.root}
          component="main"
          maxWidth="sm"
          alignContent="middle"
        >
          <Grid
            container
            item
            className={classes.grid1}
            xs={width >= 600 ? 6 : 12}
            md={width >= 600 ? 6 : 12}
            lg={width >= 600 ? 6 : 12}
            maxWidth="md"
          >
            <Grid
              container
              item
              className={classes.googleLogin}
              xs={12}
              md={12}
              lg={12}
              justify="center"
              maxWidth="sm"
            >
              <Grid
                container
                item
                className={classes.googleBtn}
                style={{ width: width >= 600 ? 380 : "90%" }}
              >
                <Grid
                  container
                  item
                  xs={1}
                  md={1}
                  lg={1}
                  justifyContent="flex-start"
                >
                  <Google />
                </Grid>
                <Grid
                  container
                  item
                  xs={11}
                  md={11}
                  lg={11}
                  justifyContent="flex-start"
                >
                  <Typography className={classes.googleFont} align="left">
                    {i18n.t("registerG1")}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              container
              item
              className={classes.BaseGrid}
              xs={12}
              md={12}
              lg={12}
              justifyContent="center"
            >
              <Grid
                container
                item
                className={classes.grid1}
                xs={12}
                md={12}
                lg={12}
                justifyContent="center"
              >
                <Typography className={classes.lineText} align="left">
                  {i18n.t("registerG2")} {usuario}!
                </Typography>
              </Grid>
              <Grid
                container
                item
                className={classes.grid1}
                xs={12}
                md={12}
                lg={12}
                justifyContent="center"
              >
                <Typography className={classes.lineText2}>
                  {i18n.t("registerG3")}
                </Typography>
              </Grid>
              <Grid
                container
                item
                className={classes.grid1}
                xs={12}
                md={12}
                lg={12}
                justifyContent="center"
              >
                <Grid>
                  <Typography className={classes.lineText2}>
                    {i18n.t("registerG4")}{" "}
                  </Typography>
                  <Typography className={classes.lineText}>{correo}</Typography>
                </Grid>
              </Grid>
            </Grid>

            <Grid
              container
              item
              className={classes.BaseGrid}
              xs={12}
              md={12}
              lg={12}
              justify="center"
            >
              <RegisterFormGoogle />
            </Grid>

            <Grid
              container
              item
              className={classes.BaseGridRight}
              justifyContent="center"
            >
              <Typography className={classes.registerFont}>
                {i18n.t("accountNow")}
              </Typography>
              <Link underline="hover" href="/">
                <Typography className={classes.registerColorFont2}>
                  {" "}
                  {i18n.t("intoNow")}
                </Typography>
              </Link>
            </Grid>
          </Grid>
          {width >= 600 ? (
            <Grid container item xs={6} md={6} lg={6}>
              <img
                src={process.env.REACT_APP_BASE_URL_IMAGES + IMAGE_LOGIN_PATH}
                alt="computer_man"
                style={{ objectFit: "cover", zIndex: 0 }}
                width={"100%"}
                height={"100%"}
              />

              <div className={classes.topdiv}>
                <MenuLanguageComponent />
              </div>
            </Grid>
          ) : (
            <Grid container item xs={12} md={12} lg={12} justify="center">
              <MenuLanguageComponent />
            </Grid>
          )}
        </Grid>
      </div>
    );
  }
}
export default withStyles(styles, { withTheme: true })(RegisterGoogle);
