import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import PhoneInput from "react-phone-input-2";
import { Typography, Button, Grid } from "@material-ui/core";
import i18n from "./../locale/index";
import Collapse from "@mui/material/Collapse";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import "./registerForm.css";

const useStyles = makeStyles((theme) => ({
  formButton: {
    fontFamily: "Work Sans, sans-serif",
    color: "#131313",
    fontSize: "16px",
    fontWeight: 400,
    textAlign: "left",
  },
  input: {
    height: 45,
  },
  dropdownPhone: {
    width: "320px !important",
    "@media (min-width: 780px)": {
      width: "380px",
    },
  },
  registerText: {
    fontFamily: "Work Sans, sans-serif",
    fontSize: "11px",
  },
  icons: {
    width: "15px",
  },
  validationTextGreen: {
    color: "#00a490",
  },
  validationTextRed: {
    color: "#ff2569",
  },
  validationText: {
    fontFamily: "Work Sans, sans-serif",
    color: "#ff2569",
    fontSize: "11px",
    marginTop: "3px",
  },
  validationText2: {
    color: "#ff2569",
    fontSize: "11px",
    marginTop: "15px",
  },
  errorText: {
    height: 45,
    border: "1px solid #ff2569",
    borderRadius: 1,
  },
  registerColorFont: {
    fontFamily: "Work Sans, sans-serif",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "18.77px",
    color: "#00A490",
  },
  registerIcon: {
    color: "#00A490",
  },

  MuiCollapseRoot: {
    transitionDuration: "0",
  },
  MuiButtonText: {
    padding: "0",
  },
  formCheck: {
    fontFamily: "Work Sans, sans-serif",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "18.77px",
    alignItems: "center",
    textAlign: "center",
    color: "#131313",
  },
  registerColorFont2: {
    fontFamily: "Work Sans, sans-serif",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "18.77px",
    alignItems: "center",
    textAlign: "center",
    color: "#00A490",
    textDecoration: "none",
    marginLeft: 5,
    letterSpacing: "normal",
  },
  labelAlign: {
    alignSelf: "center",
    alignContent: "center",
    fontFamily: "Work Sans, sans-serif",
  },
  formGroupTerm: {
    marginRight: 0,
  },
  discountComponent: {
    marginTop: 5,
  },
  loginBtn: {
    marginTop: theme.spacing(1),
    height: 50,
    width: 320,
    textTransform: "none",
    border: "2px solid",
    borderColor: "#00A692",
    backgroundColor: "#00A692",
    borderRadius: 50,
    color: "#fff",
    autoComplete: "off",
    fontFamily: "Work Sans, sans-serif",
    fontSize: "16px/22px",
    fontWeight: "normal",
    "&:hover": {
      backgroundColor: "#007868",
      borderColor: "#007868",
      color: "#fff",
    },
    "@media (min-width: 780px)": {
      width: 380,
    },
  },
  loginBtn2: {
    marginTop: theme.spacing(1),
    height: 50,
    width: 320,
    textTransform: "none",
    border: "2px solid",
    borderColor: "#C6C6C6",
    backgroundColor: "#C6C6C6",
    borderRadius: 50,
    color: " #131313",
    autoComplete: "off",
    fontFamily: "Work Sans, sans-serif",
    fontSize: "16px/22px",
    fontWeight: "normal",
    "&:hover": {
      backgroundColor: "#007868",
      borderColor: "#007868",
      color: "#fff",
    },
    "@media (min-width: 780px)": {
      width: 380,
    },
  },
}));

const countries = [
  {
    value: "Uruguay",
    label: "🇺🇾",
  },
  {
    value: "Mexico",
    label: "🇲🇽",
  },
  {
    value: "Argentina",
    label: "🇦🇷",
  },
  {
    value: "Chile",
    label: "🇨🇱",
  },
];

const questions = [
  {
    value: `${i18n.t("meetUs")}`,
  },
  {
    value: `${i18n.t("google")}`,
  },
  {
    value: `${i18n.t("friends")}`,
  },
  {
    value: `${i18n.t("recomendation")}`,
  },
  {
    value: `${i18n.t("socialm")}`,
  },
  {
    value: `${i18n.t("event")}`,
  },
  {
    value: `${i18n.t("other")} `,
  },
];
const activitys = [
  {
    value: `${i18n.t("friends")}`,
  },
  {
    value: `${i18n.t("socialm")}`,
  },
  {
    value: `${i18n.t("activity")}`,
  },
];

function RegisterFormGoogle() {
  const [country, setCountry] = React.useState("Argentina");
  const [phone, setPhone] = React.useState("");
  const [question, setQuestion] = React.useState(`${i18n.t("meetUs")}`);
  const [activity, setActivity] = React.useState(`${i18n.t("activity")}`);
  const [phoneVal, setPhoneVal] = React.useState(false);

  // value catched
  const [razon, setRazon] = React.useState("");
  // validation reason
  const [reasonVal, setReasonVal] = useState(true);
  // Discount code
  const [open, setOpen] = React.useState(false);
  const [code, setCode] = React.useState("");

  //btn register
  const [enabledBtn, setEnabledBtn] = useState(false);
  const [btnErrText, setBtnErrText] = useState(false);

  // check terminos
  const [checked, setChecked] = useState(false);

  const classes = useStyles();

  // Country validation
  const saveCountry = () => {
    localStorage.setItem("country", country);
  };
  useEffect(() => {
    saveCountry();
  });

  const handleChangeCountry = (event) => {
    setCountry(event.target.value);
  };
  // Business name validation
  const handleValidateReason = () => {
    if (razon === "") {
      setReasonVal(false);
    } else {
      setReasonVal(true);
    }
  };

  //Activity validation
  const handleActivity = (event) => {
    setActivity(event.target.value);
  };
  const saveActivity = (e) => {
    localStorage.setItem("activity", activity);
  };

  useEffect(() => {
    saveActivity();
  });
  //Meet us validation
  const handleChangeQuestion = (event) => {
    setQuestion(event.target.value);
  };

  const saveQuestion = (e) => {
    localStorage.setItem("question", question);
  };

  useEffect(() => {
    saveQuestion();
  });

  // Discount code
  const handleClick = () => {
    setOpen(!open);
  };
  const handleChangeCode = (event) => {
    setCode(event.target.value);
  };

  const handlerTer = (e) => {
    setChecked(!checked);
    handleActivaBtn();
  };
  // validation btn

  const handleActivaBtn = (e) => {
    if (reasonVal === true && checked === true) {
      setEnabledBtn(true);
      setBtnErrText(false);
    } else {
      setEnabledBtn(false);
      setBtnErrText(true);
    }
  };

  // Btn Register catch information
  const handleLogin = (e) => {
    e.preventDefault();
    handleActivaBtn();
  };

  return (
    <Box
      component="form"
      sx={{
        "& .MuiGrid-item": {
          mt: 1.5,
          width: "90vw",
          maxWidth: "320px",
          "@media (min-width: 780px)": {
            maxWidth: "380px",
          },
        },
      }}
      noValidate
      autoComplete="off"
    >
      <Grid container item xs={12} md={12} lg={12} /*Country*/>
        <TextField
          id="outlined-select-country"
          select
          fullWidth
          className={classes.formButton}
          value={country}
          onChange={handleChangeCountry}
          onBlur={saveCountry}
          InputProps={{
            className: classes.input,
          }}
        >
          {countries.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              <Typography className={classes.formButton}>
                {option.label} {option.value}
              </Typography>
            </MenuItem>
          ))}
        </TextField>
      </Grid>
      <Grid container item xs={12} md={12} lg={12} /*Business name*/>
        <TextField
          placeholder={i18n.t("reason")}
          fullWidth
          size="small"
          id="social"
          name="social"
          InputProps={{
            classes: {
              input: classes.formButton,
            },
            className: classes.input,
          }}
          sx={
            reasonVal === false
              ? { border: "1px solid red", borderRadius: 1 }
              : { className: classes.input2 }
          }
          autoComplete="off"
          className={classes.formButton}
          onChange={(e) => {
            setRazon(e.target.value);
            localStorage.setItem("name", razon);
          }}
          onBlur={handleValidateReason}
        />
        {reasonVal === false && (
          <Grid>
            <Typography className={classes.validationText}>
              {i18n.t("reqField")}
            </Typography>
          </Grid>
        )}
      </Grid>
      <Grid container item xs={12} md={12} lg={12} height="61px" /*Phone*/>
        <PhoneInput
          inputStyle={{ height: 45, width: "100%" }}
          inputClass={phoneVal === false ? "input1" : "input2"}
          buttonStyle={{
            border: "5px",
            margin: "3px",
            backgroundColor: "white",
          }}
          id="phone"
          name="phone"
          value={phone}
          country={"ar"}
          placeholder="phone"
          onChange={(phone) => setPhone(phone)}
          onBlur={(phone) => {
            if (/.{10,}/.test(phone)) {
              setPhoneVal(true);
              localStorage.setItem("phone", phone.currentTarget.value);
            } else {
              setPhoneVal(false);
            }
          }}
          preferredCountries={["ar", "uy", "cl"]}
          masks={{
            ar: "(...) ....-....",
            uy: "(....) ....-....",
            cl: "(..) ....-....",
          }}
          dropdownStyle={{ width: "320px" }}
        />
        {phoneVal === false ? (
          <Grid>
            {" "}
            <Typography className={classes.validationText}></Typography>
          </Grid>
        ) : (
          <Grid>
            <Typography className={classes.validationText}>
              {i18n.t("reqField")}
            </Typography>
          </Grid>
        )}
      </Grid>
      <Grid container item xs={12} md={12} lg={12} /*Activity*/>
        <TextField
          id="outlined-select-country"
          select
          fullWidth
          className={classes.formButton}
          value={activity}
          onChange={handleActivity}
          onBlur={saveActivity}
          size="small"
        >
          {activitys.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              <Typography className={classes.formButton}>
                {option.value}
              </Typography>
            </MenuItem>
          ))}
        </TextField>
      </Grid>
      <Grid container item xs={12} md={12} lg={12} /*Meet us*/>
        <TextField
          id="outlined-select-country"
          select
          fullWidth
          InputProps={{
            className: classes.input,
          }}
          className={classes.formButton}
          value={question}
          onChange={handleChangeQuestion}
          onBlur={saveQuestion}
        >
          {questions.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              <Typography className={classes.formButton}>
                {option.value}
              </Typography>
            </MenuItem>
          ))}
        </TextField>
      </Grid>
      <Grid container item xs={12} md={12} lg={12} /*Discount code*/>
        <Box
          component="form"
          sx={{
            "& .MuiTextField-root": {
              width: "90vw",
              maxWidth: "320px",
              margin: "0",
              "@media (min-width: 780px)": {
                maxWidth: "380px",
              },
            },
          }}
          noValidate
          autoComplete="off"
        >
          <Grid
            onClick={handleClick}
            container
            direction="row"
            alignContent="center"
            className={classes.discountComponent}
          >
            <Typography underline="none" className={classes.registerColorFont}>
              {i18n.t("discountcode")}
            </Typography>
            {open ? (
              <ExpandLess className={classes.registerIcon} />
            ) : (
              <ExpandMore className={classes.registerIcon} />
            )}
          </Grid>
          <Grid container item xs={12} md={12} lg={12}>
            <Collapse in={open} className={classes.codeLabel}>
              <Grid>
                <TextField
                  id="outlined-select-country"
                  placeholder={i18n.t("code")}
                  fullWidth
                  className={classes.formButton}
                  value={code}
                  onChange={handleChangeCode}
                  InputProps={{
                    className: classes.input,
                  }}
                ></TextField>
              </Grid>
            </Collapse>
          </Grid>
        </Box>
      </Grid>
      <Grid container item xs={12} md={12} lg={12} /*Terms and condition*/>
        <Grid container direction="row" alignContent="center" bac>
          <FormGroup className={classes.formGroupTerm} row={true}>
            <Grid mr={0}>
              {" "}
              <FormControlLabel
                control={
                  <Checkbox /*defaultChecked*/
                    sx={{ color: "#131313" }}
                    onChange={handlerTer}
                  />
                }
                label={i18n.t("iaccept")}
                className={classes.formCheck}
                sx={{ mr: 0 }}
              />
            </Grid>
            <Grid className={classes.labelAlign}>
              {" "}
              <Link
                underline="none"
                alignItems="center"
                href="https://contabilium.com/terminos-de-uso"
              >
                <Typography
                  align="center"
                  className={classes.registerColorFont2}
                >
                  {i18n.t("terms")}
                </Typography>
              </Link>
            </Grid>
          </FormGroup>
        </Grid>
      </Grid>
      <Grid container item xs={12} md={12} lg={12} /*Button*/>
        <Button className={classes.loginBtn} onClick={handleLogin}>
          <Typography>{i18n.t("chancePass")}</Typography>
        </Button>
        {btnErrText === true && (
          <Grid>
            {" "}
            <Typography className={classes.validationText2}>
              {i18n.t("btnErr")}
            </Typography>
          </Grid>
        )}
      </Grid>
    </Box>
  );
}

export default RegisterFormGoogle;
