import React, { Component } from "react";
import { Grid, Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { ReactComponent as Logo } from "../assets/images/logo.svg";
import i18n from "./../locale/index";
import NewPassForm from "../components/CreateNewPassForm";
//import MenuLanguageComponent from "../components/MenuLanguage";
import GoBack from "../components/GoBack";

import { Box } from "@mui/material";

const IMAGE_LOGIN_PATH = "login_bg.png";

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    height: "100vh",
    backgroundColor: "#FFFFFF",
  },
  grid1: {
    backgroundColor: "#FFFFFF",
    alignContent: "center",
  },
  googleLogin: {
    marginBottom: 10,
    alignContent: "center",
  },
  mainTitle: {
    marginTop: "4%",
    marginBottom: "10px",
  },
  line: {
    margin: 15,
  },
  lineText: {
    fontFamily: "WorkSans",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "15px",
    lineHeight: "18.77px",
    alignItems: "center",
    textAlign: "center",
    color: "#131313",
    marginTop: 5,
  },
  googleFont: {
    fontFamily: "WorkSans",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "18.77px",
    color: "#131313",
  },
  googleBtn: {
    marginTop: theme.spacing(1),
    height: 50,
    width: 380,
    textTransform: "none",
    border: "2px solid",
    borderColor: "#131313",
    borderRadius: 15,
    color: "black",
    autoComplete: "off",
    font: "normal normal normal 16px/22px WorkSans",
  },
  registerFont: {
    fontFamily: "WorkSans",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "16.42px",
    color: "#131313",
  },
  loginBtn: {
    marginTop: theme.spacing(1),
    height: 50,
    width: 380,
    textTransform: "none",
    border: "2px solid",
    borderColor: "#00C7AF",
    backgroundColor: "#00C7AF",
    borderRadius: 30,
    color: "white",
    autoComplete: "off",
    font: "normal normal normal 16px/22px WorkSans",
  },
  formCheck: {
    fontFamily: "WorkSans",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "18.77px",
    alignItems: "center",
    textAlign: "center",
    color: "#131313",
    marginRight: 5,
  },
  password: {
    fontFamily: "WorkSans",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "18.77px",
    alignItems: "center",
    textAlign: "center",
    color: "#00A490",
    cursor: "pointer",
  },
  registerColorFont: {
    fontFamily: "WorkSans",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "18.77px",
    alignItems: "center",
    textAlign: "center",
    color: "#00A490",
  },
  BaseGrid: {
    margin: 10,
  },
  checkGrid: {
    alignItems: "center",
  },
  topButton: {
    backgroundColor: "#FFFFFF",
    alignItems: "center",
    borderRadius: 50,
    height: 60,
    width: 140,
  },
  topMenuItem: {
    backgroundColor: "#FFFFFF",
    alignItems: "center",
    borderRadius: 50,
    height: 50,
    width: 140,
  },
  topButtonText: {
    color: "#131313",
    fontFamily: "WorkSans",
    fontSize: "16px",
    marginTop: 15,
    fontWeight: 400,
    textAlign: "center",
    width: 96,
  },
  topdiv: {
    position: "absolute",
    top: 20,
    right: 30,
  },
  recoverTex: {
    color: "#131313",
    fontFamily: "Work Sans, sans-serif",
    fontSize: "28px",
    fontWeight: 600,
    textAlign: "center",
    "@media (min-width: 780px)": {
      fontSize: "32px",
    },
  },
  labelText: {
    color: "#131313",
    fontFamily: "Work Sans, sans-serif",
    fontSize: "15px",
    fontWeight: 400,
    lineHeight: 1.5,
    textAlign: "left",
    marginBottom: "5px",
    letterSpacing: 0,
  },

  containerLabel: {
    marginTop: "10px",
  },
  backgroundImg: {
    objectFit: "cover",
    zIndex: 0,
    width: "100%",
    height: "100%",
    overFlowY: "hidden",
    left: "40px",
  },
  imgContainer:{
   // position: "fixed",
    top: 0,
    right:0,
    height: "100vh"
  }
});

class NewPassView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      windowWidth: window.innerWidth,
    };
  }

  handleResize = (e) => {
    this.setState({ windowWidth: window.innerWidth });
  };
  componentDidMount() {
    window.addEventListener("resize", this.handleResize);
    console.clear();
  }
  render() {
    function getWindowDimensions() {
      const { innerWidth: width } = window;
      return {
        width,
      };
    }

    const { classes } = this.props;
    const { width } = getWindowDimensions();

    return (
      <div style={{ backgroundColor: "#E5E5E5" }}>
        <Grid
          container
          className={classes.root}
          component="main"
          maxWidth="sm"
          justifyContent="center"
          alignContent="middle"
        >
          <Grid
            container
            item
            className={classes.grid1}
            xs={width >= 600 ? 6 : 12}
            md={width >= 600 ? 6 : 12}
            lg={width >= 600 ? 6 : 12}
            maxWidth="md"
          >
            <Grid
              container
              item
              className={classes.mainTitle}
              xs={12}
              md={12}
              lg={12}
              justifyContent="center"
            >
              <Logo />
            </Grid>
            <Grid
              container
              item
              xs={12}
              md={12}
              lg={12}
              justifyContent="center"
              maxWidth="sm"
            >
              <Grid>
                <Box
                  sx={{
                    width: "90vw",
                    maxWidth: "320px",
                    margin: "0",
                    "@media (min-width: 780px)": {
                      maxWidth: "380px",
                      justifyContent: "center",
                      margin: "0",
                    },
                  }}
                >
                  <Typography className={classes.recoverTex}>
                    {i18n.t("newChancePass")}
                  </Typography>
                  <Grid className={classes.containerLabel}>
                    <Typography className={classes.labelText}>
                      {i18n.t("createNewPass")} {""} {i18n.t("createNewPass2")}
                    </Typography>
                  </Grid>
                </Box>
              </Grid>
            </Grid>

            <Grid
              container
              item
              className={classes.BaseGrid}
              xs={12}
              md={12}
              lg={12}
              justifyContent="center"
            >
              <NewPassForm />
            </Grid>
            <Grid
              container
              item
              className={classes.BaseGrid}
              justifyContent="center"
            >
              <GoBack text="goBack" link="/" />
            </Grid>
          </Grid>
          {width >= 600 ? (
            <Grid
              container
              item
              xs={6}
              md={6}
              lg={6}
              className={classes.imgContainer}
            >
              <img
                src={process.env.REACT_APP_BASE_URL_IMAGES+ IMAGE_LOGIN_PATH}
                alt="computer_man"
                className={classes.backgroundImg}
              />

              <div className={classes.topdiv}>
                {/*<MenuLanguageComponent />*/}
              </div>
            </Grid>
          ) : (
            <Grid
              container
              item
              xs={12}
              md={12}
              lg={12}
              justifyContent="center"
            >
              {/*<MenuLanguageComponent />*/}
            </Grid>
          )}
        </Grid>
      </div>
    );
  }
}
export default withStyles(styles, { withTheme: true })(NewPassView);
