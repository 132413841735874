import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@mui/material/TextField";
import i18n from "./../locale/index";
import {
  Typography,
  CircularProgress,
  Dialog,
  Button,
} from "@material-ui/core";
import { Grid } from "@material-ui/core";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import MenuItem from "@mui/material/MenuItem";
import { ReactComponent as Ar } from "./../assets/images/argentina.svg";
import { ReactComponent as Uy } from "./../assets/images/uruguay.svg";
import { ReactComponent as Cl } from "./../assets/images/chile.svg";
import { InputBase } from "@mui/material";
import { sendAmplitudeData } from "../helpers/amplitude";

const useStyles = makeStyles((theme) => ({
  formButton: {
    color: "#131313",
    fontFamily: "Work Sans, sans-serif",
    fontSize: "16px",
    fontWeight: 400,
    textAlign: "left",
    border: "1px solid rgba(19, 19, 19, 0.3)",
    borderRadius: 3,
    height: 45,
    padding: "5px 16px",
  },
  labelText: {
    marginBotton: "50px",
    color: "#131313",
    fontFamily: "Work Sans, sans-serif",
    fontSize: "15px",
    fontWeight: 400,
    lineHeight: 1.5,
    textAlign: "left",
  },
  loginBtn: {
    marginTop: "20px",
    height: 50,
    width: 380,
    textTransform: "none",
    border: "2px solid",
    borderColor: "#00c7af",
    backgroundColor: "#00c7af",
    borderRadius: 50,
    color: "#fff",
    autoComplete: "off",
    font: "normal normal normal 16px/22px WorkSans",
    "&:hover": {
      backgroundColor: "#007868",
      borderColor: "#007868",
      color: "#fff",
    },
    "@media (min-width: 780px)": {
      width: 380,
    },
  },
  loginBtn2: {
    marginTop: "20px",
    height: 50,
    width: 320,
    textTransform: "none",
    border: "2px solid",
    borderColor: "#C6C6C6",
    backgroundColor: "#C6C6C6",
    borderRadius: 50,
    color: " #131313",
    autoComplete: "off",
    fontFamily: "Work Sans, sans-serif",
    fontWeight: "normal",
    fontSize: "16px/22px",
    /*  font: "normal normal normal 16px/22px", */
    letterSpacing: 0,

    "&:hover": {
      backgroundColor: "#007868",
      borderColor: "#007868",
      color: "#fff",
    },
    "@media (min-width: 780px)": {
      width: 380,
    },
  },
  validationText: {
    color: "#ff2569",
    fontSize: "11px",
    margin: "5px 0",
    fontFamily: "Work Sans, sans-serif",
  },
  baseGrid: {
    marginTop: "20px",
  },
  selectComponent: {
    backgroundColor: "red",
  },
  formTypografy: {
    color: "#131313",
    fontSize: "15px",
    fontWeight: 400,
    textAlign: "left",
    fontFamily: "Work Sans, sans-serif",
    height:"fit-content",
    marginLeft:"2px",
    alignSelf:"center",
  },
  formSelect:{
    height: "45px",
    alignContent:"center",
    padding:"1px 0"

  },
  formFlag:{
    margin:" 0 5px 0 16px",
   // alignSelf:"center"
  },
  formCountry:{
    paddingBottom:"0"
  }
}));

const theme = createTheme({
  palette: {
    error: {
      main: "#ff2569",
    },
  },
});

const countries = [
  {
    value: "Argentina",
    label: <Ar code="AR" />,
  },
  {
    value: "Chile",
    label: <Cl code="CL" />,
  },
  {
    value: "Uruguay",
    label: <Uy code="UY" />,
  },
];
function capturedHost(host){
  let countrySend = "";
  switch (host) {
    case "com":
      countrySend = "Argentina";
      break;
    case "ar":
      countrySend = "Argentina";
      break;
    case "cl":
      countrySend = "Chile";
      break;
    case "uy":
      countrySend = "Uruguay";
      break;
    default:
      countrySend = "Argentina";
      break;
  }
  return countrySend;
}
function RecoverPass(props) {
  const classes = useStyles();
  const [email, setEmail] = useState("");
  const [error, setError] = useState(false);
  const [enabledBtn, setEnabledBtn] = useState(false);
  const [loading, setloading] = useState(false);
  const [country, setCountry] = React.useState(capturedHost(window.location.host.replace(/^.*\./,"")));
  const [errorMessage, setErrorMessage] = useState(false);
  const regEx = /\S+@\S+\.\S+/;

  const handleEmail = (event) => {
    setEmail(event.target.value);
    if (regEx.test(email)) {
      setErrorMessage(false);
      setError(false);
    } else {
      setErrorMessage(true);
      setError(true);
    }
  };

  useEffect(() => {
    if (email) {
      if (regEx.test(email)) {
        setEnabledBtn(true);
        setErrorMessage(false);
        setError(false);
      } else {
        setErrorMessage(true);
        setError(true);
        setEnabledBtn(false);
      }
    } else {
      setEnabledBtn(false);
      setErrorMessage(false);
      setError(false);
    }
  }, [email, regEx]);

  // Country
  const saveCountry = () => {
    localStorage.setItem("countryPass", country);
  };

  const handleChange = (event) => {
    setCountry(event.target.value);
    if (event.target.value === "Argentina"){
      window.open(process.env.REACT_APP_BASE_URL_AR + "recoverpass", "_self");
    }
    if (event.target.value === "Chile"){
      window.open(process.env.REACT_APP_BASE_URL_CL + "recoverpass", "_self");
    }
    if (event.target.value === "Uruguay"){
      window.open(process.env.REACT_APP_BASE_URL_UY + "recoverpass", "_self");
    }
  };
  useEffect(() => {
    saveCountry();
  }, [country]);

  const handleCountry = (country) => {
    let countrySend = "";
    switch (country) {
      case "Argentina":
        countrySend = "ar";
        break;
      case "Chile":
        countrySend = "cl";
        break;
      case "Uruguay":
        countrySend = "uy";
        break;
      default:
        countrySend = "ar";
        break;
    }
    return countrySend;
  };

  const handleChangeEmail = (e) => {
    //e.preventDefault();
    sendAmplitudeData("Recuperar Contraseña - Continuar");
    setloading(true);
    const countryPass = handleCountry(localStorage.getItem("countryPass"));
    fetch(process.env.REACT_APP_CHANGERECOVER, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email: email,
        country: countryPass,
      }),
    }).then((response) => {
      setloading(false);
      //localStorage.removeItem("email");
      window.open(process.env.REACT_APP_SEND_EMAIL, "_self");
    });
  };
  //Enter
  const handleEnter = (event) => {
    if (event.key === "Enter") {
      handleChangeEmail();
    }
  };
  return (
    <ThemeProvider theme={theme}>
      <Box
        component="form"
        sx={{
          "& .MuiGrid-item": {
            mt: 1.5,
            width: "90vw",
            maxWidth: "320px",
            height: "45px",
            "@media (min-width: 780px)": {
              maxWidth: "380px",
            },
          },
          "& 	fieldset": {
            height: "45px",
            marginTop: "5px",
          },
          legend: { display: "none" },
          "& 	.MuiInputBase-input": {
            fontFamily: "Work Sans, sans-serif",
            lineHeight: "17.6px",
            fontSize: "15px",
          },
          "& 	.MuiFormControlLabel-label": {
            fontFamily: "Work Sans, sans-serif",
            lineHeight: "16.42px",
            fontSize: "14px",
          },
          "& 	.MuiButton-text .MuiTypography-root": {
            fontFamily: "Work Sans, sans-serif",
            lineHeight: "18.77px",
            fontSize: "15px",
          },
          "& 	input": {
            height: "45px",
            paddingBottom: 0,
            paddingTop: 0,
          },
          ".css-1f3cabt-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
            { border: "1px solid rgba(0, 0, 0, 0.23)" },
          ".css-1f3cabt-MuiInputBase-root-MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
            { border: "1px solid rgba(0, 0, 0, 0.23)" },
          ".css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input":
            { height: "45px", padding: "0" },
          "& 	.css-6hp17o-MuiList-root-MuiMenu-list": {
            paddingTop: "0px",
            paddingBottom: "0px",
          },
        }}
        noValidate
        autoComplete="off"
      >
        <Grid container item xs={12} md={12} lg={12} /*Country*/>
          <TextField
            id="outlined-select-country"
            select
            fullWidth
            className={classes.formButton}
            value={country}
            onChange={handleChange}
            onBlur={saveCountry}
            InputProps={{
              className: classes.input,
            }}
          >
            {countries.map((option) => (
              <MenuItem
                key={option.value}
                value={option.value}
                className={classes.formCountry}
                disableRipple
              >
                <Grid
                  container
                  xs={12}
                  md={12}
                  lg={12}
                  className={classes.formSelect}
                >
                  <Grid className={classes.formFlag}>{option.label}</Grid>

                  <Typography className={classes.formTypografy}>
                    {option.value}
                  </Typography>
                </Grid>
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid container item xs={12} md={12} lg={12} /*Email*/>
          <InputBase
            placeholder="demo@contabilium.com"
            fullWidth
            onKeyDown={handleEnter}
            helperText={errorMessage}
            id="email"
            name="name"
            InputProps={{
              classes: {
                input: classes.formButton,
              },
            }}
            autoComplete="off"
            className={classes.formButton}
            onClick={(e) => {}}
            onChange={handleEmail}
            sx={
              error === true
                ? {
                    border: "1px solid #ff2569",
                    height: "45px",
                    borderRadius: 1,
                  }
                : { className: classes.input2 }
            }
          />
          {errorMessage === true && (
            <Grid>
              <Typography className={classes.validationText}>
                {i18n.t("errorMessage")}
              </Typography>
            </Grid>
          )}
        </Grid>
        <Grid container items xs={12} md={12} lg={12} /*Loading*/>
          {loading && (
            <Dialog open={loading}>
              <div
                style={{
                  padding: "20px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  backgroundColor: "transparent",
                }}
              >
                <CircularProgress
                  size={30}
                  style={{ backgroundColor: "transparent" }}
                />
              </div>
            </Dialog>
          )}
        </Grid>
        <Grid /*Button*/
          container
          item
          className={classes.baseGrid}
          xs={12}
          md={12}
          lg={12}
          justify="center"
        >
          {enabledBtn === true ? (
            <Button
              className={classes.loginBtn}
              onClick={handleChangeEmail}
              disabled={false}
            >
              <Typography>{i18n.t(`${"continue"}`)}</Typography>
            </Button>
          ) : (
            <Button
              className={classes.loginBtn2}
              onClick={handleChangeEmail}
              disabled={true}
            >
              <Typography>{i18n.t(`${"continue"}`)}</Typography>
            </Button>
          )}
        </Grid>
      </Box>
    </ThemeProvider>
  );
}

export default RecoverPass;
