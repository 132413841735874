import i18next from 'i18next';
import {eng} from './en';
import {es} from './es';


i18next.init({
  interpolation :{
      escapeValue : false,
  },
  lng: window.location.pathname.substr(window.location.pathname.length - 2 ) === 'en'? 'en':'es',
  
  resources : {
      en: {
          translation : eng,
      },
      es:{
           translation: es,
       },
  },
})

export default i18next;