import React, { Component } from "react";
import { Grid, Typography } from "@material-ui/core";
import Link from "@mui/material/Link";
import { withStyles } from "@material-ui/core/styles";
import { ReactComponent as Line } from "../assets/images/Line.svg";
import i18n from "./../locale/index";
//import MenuLanguageComponent from "../components/MenuLanguage";
import RegisterForm from "../components/RegisterForm";
//import BtnGoogleComponent from "../components/BtnGoogle";

import { Box } from "@mui/material";
import { ReactComponent as Logo } from "../assets/images/logo.svg";
const { localStorage } = global.window;
const IMAGE_LOGIN_PATH = "login_bg.png";

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    height: "100vh",
    backgroundColor: "#FFFFFF",
  },
  grid1: {
    backgroundColor: "#FFFFFF",
    alignContent: "center",
  },

  mainTitle: {
    textAlign: "left",
    marginTop: theme.spacing(2),
  },
  line: {
    width: 27,
    alignSelf: "center",
    "@media (min-width: 780px)": {
      width: 56,
    },
  },
  lineText: {
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "1.4px",
    alignItems: "center",
    textAlign: "center",
    color: "#131313",
    fontFamily: "Work Sans, sans-serif",
    marginBottom:"10px"
  },
  googleFont: {
    fontFamily: "Work Sans, sans-serif",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "18.77px",
    color: "#131313",
  },
  googleBtn: {
    marginTop: theme.spacing(1),
    height: 50,
    width: 380,
    textTransform: "none",
    border: "2px solid",
    borderColor: "#131313",
    borderRadius: 15,
    color: "black",
    autoComplete: "off",
    fontFamily: "Work Sans, sans-serif",
    fontSize: "16px/22px",
    fontWeight: "normal",
  },
  registerFont: {
    fontFamily: "Work Sans, sans-serif",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "16.42px",
    color: "#131313",
  },
  formCheck: {
    fontFamily: "Work Sans, sans-serif",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "18.77px",
    alignItems: "center",
    textAlign: "center",
    color: "#131313",
    marginRight: 5,
  },
  password: {
    fontFamily: "Work Sans, sans-serif",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "18.77px",
    alignItems: "center",
    textAlign: "center",
    color: "#00A490",
  },
  registerColorFont: {
    marginLeft: theme.spacing(20),
    fontFamily: "Work Sans, sans-serif",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "18.77px",
    alignItems: "center",
    textAlign: "center",
    color: "#00A490",
  },
  registerColorFont2: {
    fontFamily: "Work Sans, sans-serif",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "18.77px",
    color: "#007868",
    marginLeft: 3,
    "&:hover": {
      color: "#007868",
    },
  },
  BaseGrid: {
    marginTop: 10,
    marginBottom: 10,
  },
  topButton: {
    backgroundColor: "#FFFFFF",
    alignItems: "center",
    borderRadius: 50,
    height: 60,
    width: 140,
  },
  topMenuItem: {
    backgroundColor: "#FFFFFF",
    alignItems: "center",
    borderRadius: 50,
    height: 50,
    width: 140,
  },
  topButtonText: {
    color: "#131313",
    fontFamily: "Work Sans, sans-serif",
    fontSize: "16px",
    marginTop: 15,
    fontWeight: 400,
    textAlign: "center",
    width: 96,
  },
  topdiv: {
    position: "absolute",
    top: 20,
    right: 30,
  },
  formGroupTerm: {
    justifyContent: "flex-start",
    alignContent: "center",
  },
  registerText: {
    color: "#131313",
    fontSize: "28px",
    fontWeight: 600,
    textAlign: "center",
    marginTop: "10px",
    marginBottom: "15px",
    margin: "30px 0 ",
    fontStyle: "normal",
    fontFamily: "Work Sans, sans-serif",
    "@media (min-width: 780px)": {
      fontSize: "32px",
    },
  },
  labelText: {
    color: "#131313",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "18px",
    marginBottom: "10px",
    textAlign: "center",
    fontFamily: "Work Sans, sans-serif",
  },
  BaseGridRight: {
    marginTop: "10px",
  },
  BaseGridLine: {
    justifyContent: "space-between",
    width: 320,
    "@media (min-width: 780px)": {
      width: 380,
    },
  },
  backgroundImg: {
    objectFit: "cover",
    zIndex: 0,
    width: "100%",
    height: "100%",
    overFlowY: "hidden",
    left: "40px",
  },
});

class Register extends Component {
  constructor(props) {
    super(props);
    this.state = {
      textFree:i18n.t("testforfree"),
      registerFree:i18n.t("registerfree"),
      accountNow:i18n.t("accountNow"),
      condition:i18n.t("termin"),
      intoNow:i18n.t("intoNow"),
      windowWidth: window.innerWidth,
    };
  }

  handleResize = (e) => {
    this.setState({ windowWidth: window.innerWidth });
  };
  handleTextMulticountry = (host) => {
    let region = i18n.t("testforfree");
    switch (host) {
      case "com":
        region = i18n.t("testforfree");
        break;
      case "ar":
        region = i18n.t("testforfree");
        break;
      case "cl":
        region = i18n.t("testforfreeChUy");
        break;
      case "uy":
        region = i18n.t("testforfreeChUy");
        break;
      default:
        region =i18n.t("testforfree");
        break;
    }
    return region
  }
  handleTextMulticountry2 = (host) => {
    let region = i18n.t("registerfree")
    switch (host) {
      case "com":
        region = i18n.t("registerfree");
        break;
      case "ar":
        region = i18n.t("registerfree");
        break;
      case "cl":
        region = i18n.t("registerfreeChUy");
        break;
      case "uy":
        region = i18n.t("registerfreeChUy");
        break;
      default:
        region =i18n.t("registerfree");
        break;
    }
    return region
  }
  handleTextMulticountry3 = (host) => {
    let region = i18n.t("accountNow")
    switch (host) {
      case "com":
        region = i18n.t("accountNow");
        break;
      case "ar":
        region = i18n.t("accountNow");
        break;
      case "cl":
        region = i18n.t("accountNowChUy");
        break;
      case "uy":
        region = i18n.t("accountNowChUy");
        break;
      default:
        region =i18n.t("accountNow");
        break;
    }
    return region
  }
  handleTextMulticountry4 = (host) => {
    let region = i18n.t("termin")
    switch (host) {
      case "com":
        region = i18n.t("termin");
        break;
      case "ar":
        region = i18n.t("termin");
        break;
      case "cl":
        region = i18n.t("terminChUy");
        break;
      case "uy":
        region = i18n.t("terminChUy");
        break;
      default:
        region =i18n.t("termin");
        break;
    }
    return region
  }
  handleTextMulticountry5 = (host) => {
    let region = i18n.t("intoNow")
    switch (host) {
      case "com":
        region = i18n.t("intoNow");
        break;
      case "ar":
        region = i18n.t("intoNow");
        break;
      case "cl":
        region = i18n.t("intoNowChUy");
        break;
      case "uy":
        region = i18n.t("intoNowChUy");
        break;
      default:
        region =i18n.t("intoNow");
        break;
    }
    return region
  }
  
  componentDidMount() {
    const extUrl = window.location.host.replace(/^.*\./, "");
    this.setState({
     textFree:this.handleTextMulticountry(extUrl),
     registerFree:this.handleTextMulticountry2(extUrl),
     accountNow:this.handleTextMulticountry3(extUrl),
     condition:this.handleTextMulticountry4(extUrl),
     intoNow:this.handleTextMulticountry5(extUrl)
    })

    window.addEventListener("resize", this.handleResize);
    localStorage.removeItem("password");
    localStorage.removeItem("question");
    localStorage.removeItem("name");
    localStorage.removeItem("phone");
    //localStorage.removeItem("email");
    localStorage.setItem("termino", false);
    console.clear();
  }
  render() {
    function getWindowDimensions() {
      const { innerWidth: width } = window;
      return {
        width,
      };
    }

    const { classes } = this.props;
    const { width } = getWindowDimensions();

    return (
      <div style={{ backgroundColor: "#E5E5E5" }}>
        <Grid
          container
          className={classes.root}
          component="main"
          maxWidth="sm"
          alignContent="middle"
        >
          <Grid
            container
            item
            className={classes.grid1}
            xs={width >= 600 ? 6 : 12}
            md={width >= 600 ? 6 : 12}
            lg={width >= 600 ? 6 : 12}
            maxWidth="md"
          >
            <Grid
              container
              item
              xs={12}
              md={12}
              lg={12}
              justifyContent="center"
              mb={1}
            >
              <Grid
                container
                item
                className={classes.mainTitle}
                xs={12}
                md={12}
                lg={12}
                justifyContent="center"
              >
                <Logo />
              </Grid>
              <Grid>
                <Box
                  sx={{
                    width: "90vw",
                    maxWidth: "320px",
                    margin: "0",
                    "@media (min-width: 780px)": {
                      maxWidth: "380px",
                    },
                  }}
                >
                  <Typography className={classes.registerText}>
                    {this.state.registerFree}
                  </Typography>
                </Box>
              </Grid>
            </Grid>
            <Grid
              container
              item
              xs={12}
              md={12}
              lg={12}
              justifyContent="center"
            >
              <Grid>
                <Box
                  sx={{
                    width: "90vw",
                    maxWidth: "320px",
                    margin: "0",
                    "@media (min-width: 780px)": {
                      maxWidth: "380px",
                    },
                  }}
                ></Box>
              </Grid>
            </Grid>

            <Grid
              container
              item
              className={classes.googleLogin}
              xs={12}
              md={12}
              lg={12}
              justifyContent="center"
              maxWidth="sm"
            >
         {/*     <Link href={process.env.REACT_APP_ACCOUNT_REGISTERSSO} underline="none">
                <BtnGoogleComponent text="textBtnGoogle2" />
                </Link>*/}
                </Grid>

            <Grid
              container
              item
              sx={{
                width: "90vw",
                maxWidth: "380px",
                margin: "0",
              }}
              justifyContent="center"
            >
              <Box className={classes.BaseGridLine} sx={{ display: "flex", justifyContent:"center" }}>
               {/*<Line className={classes.line} />*/}
                <Typography className={classes.lineText}>
                  {this.state.textFree}
                </Typography>
                {/*<Line className={classes.line} />*/}
              </Box>
            </Grid>
            <Grid
              container
              item
              className={classes.BaseGrid}
              xs={12}
              md={12}
              lg={12}
              justifyContent="center"
            >
              <RegisterForm />
            </Grid>

            <Grid
              container
              item
              className={classes.BaseGridRight}
              justifyContent="center"
            >
              <Typography className={classes.registerFont}>
                {this.state.accountNow}
              </Typography>
              <Link underline="none" href={process.env.REACT_APP_LOGIN}>
                <Typography className={classes.registerColorFont2}>
                {this.state.intoNow}
                </Typography>
              </Link>
            </Grid>
          </Grid>
          {width >= 600 ? (
            <Grid
              container
              item
              xs={6}
              md={6}
              lg={6}
              className={classes.imgContainer}
            >
              <img
                src={process.env.REACT_APP_BASE_URL_IMAGES + IMAGE_LOGIN_PATH}
                alt="computer_man"
                style={{ objectFit: "cover", zIndex: 0 }}
                width={"100%"}
                height={"100%"}
                className={classes.backgroundImg}
              />

              <div className={classes.topdiv}>
                {/*<MenuLanguageComponent />*/}
              </div>
            </Grid>
          ) : (
            <Grid
              container
              item
              xs={12}
              md={12}
              lg={12}
              justifyContent="center"
            >
              {/*<MenuLanguageComponent />*/}
            </Grid>
          )}
        </Grid>
      </div>
    );
  }
}
export default withStyles(styles, { withTheme: true })(Register);
