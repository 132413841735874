import React, { useState, useEffect } from "react";
import {
  Grid,
  Typography,
  Button,
  CircularProgress,
  Dialog,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import styled from "styled-components";

//import BtnAccessComponent from "../components/BtnAccess";
import ReactInputVerificationCode from "react-input-verification-code";
import CountDown from "../components/Countdown";
import Cookies from "universal-cookie";
import i18n from "./../locale/index";
//import { TextField } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  googleLogin: {
    marginBottom: 10,
    alignContent: "center",
  },
  BaseGrid: {
    margin: 10,
  },
  labels: {
    color: "#131313",
    fontFamily: "Work Sans, sans-serif",
    fontSize: "11px",
    fontWeight: 400,
    marginRight: "5px",
    lineHeight: 1.5,
  },
  errorText: {
    color: "#ff2569",
    fontFamily: "WorkSans, sans-serif;",
    fontSize: "12px",
    marginTop: "10px",
  },
  validText: {
    color: "#00c7af",
    fontFamily: "WorkSans, sans-serif;",
    fontSize: "12px",
  },
  loginBtn: {
    marginTop: theme.spacing(1),
    height: 50,
    width: 380,
    textTransform: "none",
    border: "2px solid",
    borderColor: "#00A692",
    backgroundColor: "#00A692",
    borderRadius: 50,
    color: "#fff",
    autoComplete: "off",
    fontFamily: "Work Sans, sans-serif",
    fontSize: "16px",
    fontWeight: "normal",
    "&:hover": {
      backgroundColor: "#007868",
      borderColor: "#007868",
      color: "#fff",
    },
  },
  loginBtn2: {
    marginTop: theme.spacing(1),
    height: 50,
    width: 320,
    textTransform: "none",
    border: "2px solid",
    borderColor: "#C6C6C6",
    backgroundColor: "#C6C6C6",
    borderRadius: 50,
    color: " #131313",
    autoComplete: "off",
    fontFamily: "Work Sans, sans-serif",
    fontSize: "16px",
    fontWeight: "normal",
    "&:hover": {
      backgroundColor: "#00A692",
      borderColor: "#00A692",
      color: "#fff",
    },
    "@media (min-width: 780px)": {
      width: 380,
    },
  },
  typography: {
    fontFamily: "Work Sans, sans-serif",
    fontSize: "16px",
    fontWeight: "normal",
  },
}));

const StyledReactInputVerificationCode = styled.div`
  display: flex;
  justify-content: center;
  
  --ReactInputVerificationCode-itemWidth: 43px;
  --ReactInputVerificationCode-itemHeight: 57px;
  --ReactInputVerificationCode-itemSpacing: 19px; 

  .ReactInputVerificationCode__item {
    font-family: 'WorkSans, sans-serif;',
    font-size: 28px;
    font-weight: 500;
    color: #131313;

    backgroundcolor: rgba(53, 67, 98, 1);
    border: 1px solid
      ${({ isInvalid }) =>
        isInvalid === true ? "#ff2569" : "rgba(19, 19, 19, 0.3)"}      ;
    border-radius: 3px;
    box-shadow: none;
  }
   .ReactInputVerificationCode__item.is-active {
    box-shadow: none;
    border: 1px solid #777777;
  } 
`;

function VerificationForm() {
  const classes = useStyles();
  const [code, setCode] = useState("");
  const [completed, setCompleted] = useState(false);
  const [isInvalid, setIsInvalid] = useState(false);
  const [loading, setloading] = useState(false);
  const [handleErrCh, setHandleErrCh] = useState(false);

  function getWindowDimensions() {
    const { innerWidth: width } = window;
    return {
      width,
    };
  }
  const desencripJwt = (jwt) => {
    let base64Url = jwt.split(".")[1];
    let base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    let jsonPayload = decodeURIComponent(
      atob(base64)
        .split("")
        .map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );
    return jsonPayload;
  };

  useEffect(
    (event) => {
      if (completed === true) {
        const handleUserKeyPress = (event) => {
          if (event.key === "Enter") {
            handleValidateCode();
          }
        };
        setCompleted(true);
        window.addEventListener("keydown", handleUserKeyPress);
        return () => {
          window.removeEventListener("keydown", handleUserKeyPress);
        };
      } else {
        setCompleted(false);
      }
    },
    [completed]
  );

  const handleValidateCode = (e) => {
    if (handleErrCh === false) {
      setIsInvalid(true);
    } else {
      // e.preventDefault();
      setloading(true);
      const bearerJWT = localStorage.getItem("jwt");
      const URI = process.env.REACT_APP_CODE;

      fetch(URI, {
        method: "post",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${bearerJWT}`,
        },
        body: JSON.stringify({
          code: code,
        }),
      })
        .then((response) => {
          if (response !== "") {
            return response.json();
          }
        })
        .then((response) => {
          if (response.error === "FAILED") {
            setloading(false);
            setIsInvalid(true);
          } else {
            fetch(process.env.REACT_APP_REFRESH_JWT, {
              method: "post",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${bearerJWT}`,
              },             
            })
              .then((response) => {
                if (response !== "") {
                  return response.json();
                }
              })
              .then((response) =>{
                let jsonParse = JSON.parse(desencripJwt(response.jwt));
                localStorage.setItem("validated", jsonParse.data.verified);
                localStorage.setItem("userID_Product", jsonParse.data.userId); 
                localStorage.setItem("Company", jsonParse.data.business_name); 
                localStorage.setItem("email", jsonParse.data.email);
                localStorage.setItem("userAdd", jsonParse.data.is_additional); 
                localStorage.setItem("parent", jsonParse.data.parent_id);  
                localStorage.setItem("country_prod", jsonParse.data.country);
                localStorage.setItem("sign_up_date", jsonParse.data.sign_up_date); 
                localStorage.setItem("promotion_code", jsonParse.data.promotion_code);     
                const cookies = new Cookies();
                cookies.set("Secure-1CBL", response.jwt, {
                  path: "/",
                  expire: jsonParse.data.exp,
                });
                if (jsonParse.data.verified === false) {
                  window.open(process.env.REACT_APP_MAIL_AUTH, "_self");
                } else {
                  setloading(false);                  
                  localStorage.removeItem("code");
                  window.open(process.env.REACT_APP_SUCCESS_AUTH, "_self");
                }
              }).catch((err) => {
                setloading(false);
                console.log(err);
              });
          
          }
        })
        .catch((err) => {
          setloading(false);
          console.log(err);
        });
    }
  };

  const { width } = getWindowDimensions();

  return (
    <>
      <Grid
        container
        item
        xs={12}
        md={12}
        lg={12}
        justifyContent="center"
        mb={1}
        sx={{
          "& 	.MuiButton-text .MuiTypography-root": {
            fontFamily: "Work Sans, sans-serif",
            lineHeight: "18.77px",
            fontSize: "16px",
          },
        }}
      >
        <Grid
          className={classes.googleLogin}
          sx={{
            "& 	.MuiButton-text .MuiTypography-root": {
              fontFamily: "Work Sans, sans-serif",
              lineHeight: "18.77px",
              fontSize: "16px",
            },
          }}
          // onKeyDown={handleEnter}
        >
          <StyledReactInputVerificationCode isInvalid={isInvalid}>
            <ReactInputVerificationCode
              length={6}
              placeholder=""
              autoFocus={true}
              onChange={(newValue) => {
                setCode(newValue);
                localStorage.setItem("code", newValue);
                if (newValue.length > 5) {
                  //setIsInvalid(false);
                  setHandleErrCh(true);
                  setCompleted(true);
                } else {
                  setHandleErrCh(false);
                  setCompleted(false);
                }
              }}
            />
          </StyledReactInputVerificationCode>
          {isInvalid ? (
            <Typography className={classes.errorText}>
              {i18n.t("errCodeApi")}
            </Typography>
          ) : null}

          {loading && (
            <Dialog open={loading}>
              <div
                style={{
                  padding: "20px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  backgroundColor: "transparent",
                }}
              >
                <CircularProgress
                  size={30}
                  style={{ backgroundColor: "transparent" }}
                />
              </div>
            </Dialog>
          )}
        </Grid>
      </Grid>

      <Grid
        container
        item
        justifyContent="center"
        direction="row"
        spacing="5px"
        className={classes.BaseGrid}
      >
        <Typography
          xs={width >= 600 ? 6 : 12}
          md={width >= 600 ? 6 : 12}
          lg={width >= 600 ? 6 : 12}
          maxWidth="md"
          noWrap={true}
          className={classes.labels}
        >
          {i18n.t("codeLabel")}{" "}
        </Typography>
        <Typography
          xs={width >= 600 ? 6 : 12}
          md={width >= 600 ? 6 : 12}
          lg={width >= 600 ? 6 : 12}
          maxWidth="md"
          noWrap={true}
        >
          <CountDown />
        </Typography>
      </Grid>

      <Grid
        container
        item
        className={classes.BaseGrid}
        xs={12}
        md={12}
        lg={12}
        justifyContent="center"
      >
        {completed === false ? (
          <Button className={classes.loginBtn2} disabled={true}>
            <Typography className={classes.typography}>
              {i18n.t(`${"confirm"}`)}
            </Typography>
          </Button>
        ) : (
          <Button
            className={classes.loginBtn}
            disabled={false}
            onClick={handleValidateCode}
          >
            <Typography className={classes.typography}>
              {i18n.t(`${"confirm"}`)}
            </Typography>
          </Button>
        )}
      </Grid>
    </>
  );
}
export { VerificationForm };
