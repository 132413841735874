import React, { Component } from "react";
import { Grid, Typography } from "@material-ui/core";
import Link from "@mui/material/Link";
import { withStyles } from "@material-ui/core/styles";
import { ReactComponent as LogoHorizontal } from "../assets/images/Logo_Horizontal.svg";
import { ReactComponent as Line } from "../assets/images/Line.svg";
import i18n from "./../locale/index";
import LoginForm from "../components/LoginForm";

//import BtnGoogleComponent from "../components/BtnGoogle";
//import MenuLanguageComponent from "../components/MenuLanguage";

import { Box } from "@mui/material";
import Cookies from "universal-cookie";
import CanonicalLink from "../components/CanonicalLink";
import { sendAmplitudeData } from "../helpers/amplitude";
//import IframeLanding from "../components/IframeLanding";
const IMAGE_LOGIN_PATH = "login_bg.png";

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    height: "100vh",
    backgroundColor: "#FFFFFF",
  },
  grid1: {
    backgroundColor: "#FFFFFF",
    alignContent: "center",
  },
  googleLogin: {
    marginBottom: 10,
  },
  mainTitle: {
    marginTop: "0%",
    marginBottom: "30px",
  },
  line: {
    width: 41,
    alignSelf: "center",
    "@media (min-width: 780px)": {
      width: 72,
    },
  },
  lineText: {
    fontFamily: "Work Sans, sans-serif",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "15px",
    lineHeight: "18.77px",
    alignItems: "center",
    textAlign: "center",
    color: "#131313",
  },
  registerFont: {
    marginRight: "5px",
    fontFamily: "Work Sans, sans-serif",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "1.4",
    color: "#131313",
  },
  loginBtn: {
    marginTop: theme.spacing(1),
    height: 50,
    width: 380,
    textTransform: "none",
    border: "2px solid",
    borderColor: "#00C7AF",
    backgroundColor: "#00C7AF",
    borderRadius: 30,
    color: "white",
    autoComplete: "off",
    fontFamily: "Work Sans, sans-serif",
    fontSize: "16px/22px",
    fontWeight: "normal",
  },
  formCheck: {
    fontFamily: "Work Sans, sans-serif",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "18.77px",
    alignItems: "center",
    textAlign: "center",
    color: "#131313",
    marginRight: 5,
  },
  password: {
    fontFamily: "Work Sans, sans-serif",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "18.77px",
    alignItems: "center",
    textAlign: "right",
    color: "#00C7AF",
    cursor: "pointer",
  },
  registerColorFont: {
    fontFamily: "Work Sans, sans-serif",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: 1.4,
    alignItems: "center",
    textAlign: "center",
    color: "#007868",
    cursor:"pointer",
    "&:hover": {
      color: "#007868",
    },
  },
  BaseGrid: {
    margin: 10,
  },
  checkGrid: {
    alignItems: "center",
  },
  topButton: {
    backgroundColor: "#FFFFFF",
    alignItems: "center",
    borderRadius: 50,
    height: 60,
    width: 140,
  },
  topMenuItem: {
    backgroundColor: "#FFFFFF",
    alignItems: "center",
    borderRadius: 50,
    height: 50,
    width: 140,
  },
  topButtonText: {
    color: "#131313",
    fontFamily: "Work Sans, sans-serif",
    fontSize: "16px",
    marginTop: 15,
    fontWeight: 400,
    textAlign: "center",
    width: 96,
  },
  topdiv: {
    position: "absolute",
    top: 20,
    right: 30,
  },
  BaseGridLine: {
    justifyContent: "space-between",
    width: 320,
    marginBottom: "10px",
    "@media (min-width: 780px)": {
      width: 380,
    },
  },
  backgroundImg: {
    objectFit: "cover",
    zIndex: 0,
    width: "100%",
    height: "100%",
    overFlowY: "hidden",
    borderWidth: "0px",
  },
  imgContainer: {
    position: "fixed",
    top: 0,
    right: 0,
    height: "100vh",
  },
});

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      windowWidth: window.innerWidth,
      session: "",
      hostUrl: "",
      textMultiCountry: i18n.t("useEmail"),
      textMultiCountry2: i18n.t("account"),
      pageLogo:"",
      register:""
    };
  }
  handleResize = (e) => {
    this.setState({ windowWidth: window.innerWidth });
  };
  handleLogout = (e) => {
    const cookies = new Cookies();
    const sessionCookie = cookies.get("Secure-1CBL");
    if (sessionCookie) {
      this.setState({
        session: sessionCookie,
      });
      return sessionCookie;
    } else {
      return null;
    }
  };
  useQuery() {
    return new URLSearchParams(window.location.search);
  }
  capturedHost = (host) => {
    let countrySend = "";
    switch (host) {
      case "com":
        countrySend = "Argentina";
        break;
      case "ar":
        countrySend = "Argentina";
        break;
      case "cl":
        countrySend = "Chile";
        break;
      case "uy":
        countrySend = "Uruguay";
        break;
      default:
        countrySend = "Argentina";
        break;
    }
    return countrySend;
  };
  compareDate = (now, exp) => {
    return now > new Date(exp * 1000);
  };

  desencripJwt = (jwt) => {
    let base64Url = jwt.split(".")[1];
    let base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    let jsonPayload = decodeURIComponent(
      atob(base64)
        .split("")
        .map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );
    return jsonPayload;
  };
  validateDateUser = (jwtCookie) => {
    let jsonParse = JSON.parse(this.desencripJwt(jwtCookie));
    const expired = this.compareDate(
      Date.now(),
      jsonParse.data.verification_exp
    );
    if (expired === true && jsonParse.data.verified === false) {
      window.open(process.env.REACT_APP_MAIL_AUTH, "_self");
    } else {
      window.open(process.env.REACT_APP_LINK, "_self");
    }
  };

  handleCloseSession = (e) => {
    const token = this.handleLogout();
    const cookies = new Cookies();
    const logOut = this.useQuery().get("logOut");
    if (logOut) {
      if (token) {
        fetch(process.env.REACT_APP_LOGOUT, {
          method: "post",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        })
          .then((response) => {
            cookies.remove("Secure-1CBL", {
              path: "/",
            });
            localStorage.removeItem("validated");
            localStorage.removeItem("Company");
            // localStorage.removeItem("email");
            localStorage.removeItem("userAdd");
            localStorage.removeItem("parent");
            localStorage.removeItem("country_prod");
            localStorage.removeItem("sign_up_date");
            localStorage.removeItem("promotion_code");
            localStorage.removeItem("userID_Product");
          })
          .catch((err) => {
            console.log(err);
          });
      }
    } else {
      const jwtCookie = cookies.get("Secure-1CBL");
      if (jwtCookie) {
        this.validateDateUser(jwtCookie);
      } else {
        return null;
      }
    }
  };

  handleTextMulticountry = (host) => {
    let region = i18n.t("useEmail");
    switch (host) {
      case "com":
        region = i18n.t("useEmail");
        break;
      case "ar":
        region = i18n.t("useEmail");
        break;
      case "cl":
        region = i18n.t("useEmailChUy");
        break;
      case "uy":
        region = i18n.t("useEmailChUy");
        break;
      default:
        region = i18n.t("useEmail");
        break;
    }
    return region;
  };
  handleTextMulticountry2 = (host) => {
    let region = i18n.t("account");
    switch (host) {
      case "com":
        region = i18n.t("account");
        break;
      case "ar":
        region = i18n.t("account");
        break;
      case "cl":
        region = i18n.t("accountChUy");
        break;
      case "uy":
        region = i18n.t("accountChUy");
        break;
      default:
        region = i18n.t("account");
        break;
    }
    return region;
  };
  componentDidMount() {
    //localStorage.clear();
    this.handleCloseSession();
    const extUrl = window.location.host.replace(/^.*\./, "");
    const hostUrl = this.capturedHost(extUrl);
    
      if (extUrl === "com" || extUrl ==="ar") {
        this.setState({ pageLogo :process.env.REACT_APP_LANDING_AR});
      }
      if (extUrl === "cl") {
        this.setState({ pageLogo :process.env.REACT_APP_LANDING_CL});
      }
      if (extUrl === "uy") {
        this.setState({ pageLogo :process.env.REACT_APP_LANDING_UY});
      }
   
    this.setState({
      textMultiCountry: this.handleTextMulticountry(extUrl),
      textMultiCountry2: this.handleTextMulticountry2(extUrl),
      hostUrl: hostUrl,
    });
    localStorage.setItem("host", hostUrl);
    localStorage.setItem("ext", extUrl);
    window.addEventListener("resize", this.handleResize);
    console.clear();
  }

  linkRegister =()=>{
    sendAmplitudeData("Login - Registrate")
    const extUrl = localStorage.getItem("country"); 
      if (extUrl ==="Argentina") {
        window.open(process.env.REACT_APP_LANDING_REGISTER_AR, "_self");
      }
      if (extUrl === "Chile") {
        window.open(process.env.REACT_APP_LANDING_REGISTER_CL, "_self");
      }
      if (extUrl === "Uruguay") {
        window.open(process.env.REACT_APP_LANDING_REGISTER_UY, "_self");
      }
  }
  
  render() {
    function getWindowDimensions() {
      const { innerWidth: width } = window;
      return {
        width,
      };
    }

    const { classes } = this.props;
    const { width } = getWindowDimensions();

    const urlByCountry = () => {
      const country = localStorage.getItem("country") || "";
      const countryUrls = {
        "Argentina": "https://app.contabilium.com",
        "Chile": "https://app.contabilium.cl",
        "Uruguay": "https://app.contabilium.uy"
      };
      return countryUrls[country] || "https://app.contabilium.com";
    };

    return (
      <div style={{ backgroundColor: "#E5E5E5" }}>
        <CanonicalLink position={5} href={urlByCountry() + "/v2/login"} />
        <Grid
          container
          className={classes.root}
          component="main"
          maxWidth="xl"
          alignContent="middle"
        >
          <Grid
            container
            item
            className={classes.grid1}
            xs={width >= 600 ? 6 : 12}
            md={width >= 600 ? 6 : 12}
            lg={width >= 600 ? 6 : 12}
            maxWidth="md"
          >
            <Grid
              container
              item
              className={classes.mainTitle}
              xs={12}
              md={12}
              lg={12}
              justifyContent="center"
            >
              <LogoHorizontal />
            </Grid>
            {/*           <Grid
              container
              item
              className={classes.googleLogin}
              xs={12}
              md={12}
              lg={12}
              justifyContent="center"
              maxWidth="sm"
            >
                          <Link href={process.env.REACT_APP_ACCOUNT_REGISTERSSO} underline="none">
                <BtnGoogleComponent text="textBtnGoogle" />
    </Link>
    </Grid>*/}
            <Grid
              container
              item
              sx={{
                width: "90vw",
                maxWidth: "380px",
                margin: "0",
              }}
              justifyContent="center"
            >
              <Box className={classes.BaseGridLine} sx={{ display: "flex" }}>
                <Line className={classes.line} />
                <Typography className={classes.lineText}>
                  {this.state.textMultiCountry}
                </Typography>
                <Line className={classes.line} />
              </Box>
            </Grid>
            <Grid
              container
              item
              className={classes.BaseGrid}
              xs={12}
              md={12}
              lg={12}
              justifyContent="center"
            >
              <LoginForm urlhost={this.state.hostUrl} />
            </Grid>

            <Grid
              container
              item
              className={classes.BaseGrid}
              justifyContent="center"
            >
              <Typography className={classes.registerFont}>
                {this.state.textMultiCountry2}
              </Typography>
              <Link
                underline="none"
                color="#00C7AF"
                sx={{cursor:"pointer"}}
                onClick={()=>this.linkRegister()}
              >
                <Typography className={classes.registerColorFont}>
                  {i18n.t("register")}
                </Typography>
            </Link>
            </Grid>
          </Grid>
          {width >= 600 ? (
            <Grid
              container
              item
              xs={6}
              md={6}
              lg={6}
              className={classes.imgContainer}
            >
              {/* <img
                src={process.env.REACT_APP_BASE_URL_IMAGES + IMAGE_LOGIN_PATH}
                alt="computer_man"
                className={classes.backgroundImg}
              />
              <IframeLanding backgroundImg={classes.backgroundImg} country={this.state.hostUrl}/>*/}
              <iframe
                id="inlineFrameExample"
                title="Inline Frame Example"
                className={classes.backgroundImg}
                src={this.state.pageLogo}
              ></iframe>

              <div className={classes.topdiv}>
                {/*<MenuLanguageComponent />*/}
              </div>
            </Grid>
          ) : (
            <Grid
              container
              item
              xs={12}
              md={12}
              lg={12}
              justifyContent="center"
            >
              {/*<MenuLanguageComponent />*/}
            </Grid>
          )}
        </Grid>
      </div>
    );
  }
}
export default withStyles(styles, { withTheme: true })(Login);
