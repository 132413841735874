import React from "react";
import { Grid, Typography, } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Link } from "@mui/material";

import i18n from "./../locale/index";

const useStyles = makeStyles({
  registerFont: {
    fontFamily: "Work Sans, sans-serif",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "16.42px",
    color: "#131313",
    marginLeft: "5px",
    marginRight: "15px",
  },
  registerGrid: {
    marginTop: 2,
    alignItems: "center",
    "&:hover": {
      color: "#131313",
    },
  },
  goBackLink: {
    "&:hover": {
      color: "#131313",
      fontWeight: 600,
    },
  },
  goBackGrid:{
    alignSelf:"center"
  }

});

const GoBack = (props) => {
  const classes = useStyles();
  const texto = props.text;
  const link = props.link;

  return (
    <>
      <Grid
        container
        item
        className={classes.registerGrid}
        xs={12}
        md={12}
        lg={12}
        justifyContent="center"
      >
        <Link
          href={link}
          underline="none"
          color="inherit"
          className={classes.goBackLink}
        >
          <Grid item container         xs={12}
        md={12}
        lg={12}
        justifyContent="center" >
            <ArrowBackIcon sx={{ color: "#131313" }} />{" "}
            <Grid item className={classes.goBackGrid}>
            <Typography className={classes.registerFont}>{i18n.t(`${texto}`)}</Typography>
            </Grid>
          </Grid>
        </Link>
      </Grid>
    </>
  );
};

export default GoBack;
