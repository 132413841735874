import * as React from "react";
import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import { makeStyles } from "@material-ui/core/styles";
//import TextField from "@mui/material/TextField";
import i18n from "./../locale/index";
import {
  Typography,
  CircularProgress,
  Dialog,
  Button,
} from "@material-ui/core";
import { InputBase } from "@mui/material";
import { Grid } from "@material-ui/core";
import { createTheme, ThemeProvider } from "@mui/material/styles";
//import { ChangeEmail } from "../configHost";

const useStyles = makeStyles((theme) => ({
  formButton: {
    color: "#131313",
    fontFamily: "Work Sans, sans-serif",
    fontSize: "16px",
    fontWeight: 400,
    textAlign: "left",
    border: "1px solid rgba(19, 19, 19, 0.3)",
    borderRadius: 3,
    height: 45,
    padding: "5px 16px",
  },
  labelText: {
    marginBottom: theme.spacing(2),
    color: "#131313",
    fontFamily: "Work Sans, sans-serif",
    fontSize: "15px",
    fontWeight: 400,
    lineHeight: 1.5,
    textAlign: "left",
  },
  loginBtn: {
    marginTop: "30px",
    height: 50,
    width: 380,
    textTransform: "none",
    border: "2px solid",
    borderColor: "#00A692",
    backgroundColor: "#00A692",
    borderRadius: 50,
    color: "#fff",
    autoComplete: "off",
    fontFamily: "Work Sans, sans-serif",
    fontSize: "16px/22px",
    fontWeight: "normal",
    "&:hover": {
      backgroundColor: "#007868",
      borderColor: "#007868",
      color: "#fff",
    },
    "@media (min-width: 780px)": {
      width: 380,
    },
  },
  loginBtn2: {
    marginTop: "30px",
    height: 50,
    width: 320,
    textTransform: "none",
    border: "2px solid",
    borderColor: "#C6C6C6",
    backgroundColor: "#C6C6C6",
    borderRadius: 50,
    color: " #131313",
    autoComplete: "off",
    fontFamily: "Work Sans, sans-serif",
    fontSize: "16px",
    fontWeight: "normal",
    "&:hover": {
      backgroundColor: "#00A692",
      borderColor: "#00A692",
      color: "#fff",
    },
    "@media (min-width: 780px)": {
      width: 380,
    },
  },
  typography: {
    fontFamily: "Work Sans, sans-serif",
    fontSize: "16px/22px",
    fontWeight: "normal",
  },
  validationText: {
    color: "#ff2569",
    fontSize: "11px",
    margin: "5px 0",
    fontFamily: "Work Sans, sans-serif",
  },
}));

const theme = createTheme({
  palette: {
    error: {
      main: "#ff2569",
    },
  },
});

function ConfirmEmail(props) {
  const classes = useStyles();
  const [email, setEmail] = useState("");
  const [error, setError] = useState(false);
  const [enabledBtn, setEnabledBtn] = useState(false);
  const [loading, setloading] = useState(false);
  // const [completed, setCompleted] = useState(false);

  const [errorMessage, setErrorMessage] = useState(false);
  const regEx = /\S+@\S+\.\S+/;

  function handleEmail(event) {
    setEmail(event.target.value);
    if (regEx.test(email)) {
      setError(false);
      setErrorMessage(false);
    } else {
      setError(true);
      setErrorMessage(true);
    }
  }

  useEffect(() => {
    if (email) {
      if (regEx.test(email)) {
        setEnabledBtn(true);
        setErrorMessage(false);
        setError(false);
      } else {
        setEnabledBtn(false);
        setErrorMessage(true);
        setError(true);
      }
    }
  }, [email, regEx]);

  const handleChangeEmail = (e) => {
    // e.preventDefault();
    setloading(true);
    const bearerJWT = localStorage.getItem("jwt");
    fetch(process.env.REACT_APP_CHANGE_EMAIL, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${bearerJWT}`,
      },
      body: JSON.stringify({
        email: email,
      }),
    }).then((response) => {
      if (response.code === "email_modify") {
        setloading(false);
      } else {
        setloading(false);
       // localStorage.removeItem("email");
        localStorage.removeItem("code");
        window.open(process.env.REACT_APP_MAIL_AUTH, "_self");
      }
    });
  };

  //Enter
  useEffect(
    (event) => {
      if (enabledBtn === true) {
        const handleEnter = (event) => {
          if (event.key === "Enter") {
            handleChangeEmail();
          }
        };

        window.addEventListener("keydown", handleEnter);
        return () => {
          window.removeEventListener("keydown", handleEnter);
        };
      }
    },
    [enabledBtn]
  );

  return (
    <ThemeProvider theme={theme}>
      <Box
        component="form"
        sx={{
          "& .MuiTextField-root": { m: 0, width: "90vw", maxWidth: "380px" },
          ".css-1f3cabt-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
            { border: "1px solid rgba(0, 0, 0, 0.23)" },
          ".css-1f3cabt-MuiInputBase-root-MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
            { border: "1px solid rgba(0, 0, 0, 0.23)" },
          "& 	fieldset": {
            height: "45px",
            marginTop: "5px",
          },
        }}
        noValidate
        autoComplete="off"
      >
        <Grid container item xs={12} md={12} lg={12}>
          <Box
            sx={{
              width: "90vw",
              maxWidth: "380px",
              margin: "0",
            }}
          >
            <Typography className={classes.labelText}>
              {i18n.t(`${props.texto}`)}
            </Typography>
          </Box>
        </Grid>
        <Box
          component="form"
          sx={{
            "& .MuiGrid-item": {
              mt: 1.5,
              width: "90vw",
              maxWidth: "320px",
              "@media (min-width: 780px)": {
                maxWidth: "380px",
              },
            },
            legend: { display: "none" },
            "& 	.MuiInputBase-input": {
              fontFamily: "Work Sans, sans-serif",
              lineHeight: "17.6px",
              fontSize: "15px",
            },
            "& 	.MuiFormControlLabel-label": {
              fontFamily: "Work Sans, sans-serif",
              lineHeight: "16.42px",
              fontSize: "14px",
            },
            "& 	.MuiButton-text .MuiTypography-root": {
              fontFamily: "Work Sans, sans-serif",
              lineHeight: "18.77px",
              fontSize: "15px",
            },
            "& 	fieldset": {
              height: "45px",
              marginTop: "5px",
            },
            "& 	input": {
              height: "45px",
              paddingBottom: 0,
              paddingTop: 0,
            },
            ".css-md26zr-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
              { border: "1px solid rgba(0, 0, 0, 0.23)" },
            ".css-md26zr-MuiInputBase-root-MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
              { border: "1px solid rgba(0, 0, 0, 0.23)" },
            "& 	.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input":
              {
                padding: "0",
              },
            "& 	.css-7dqvty-MuiInputBase-input": {
              paddingTop: 0,
            },
            "& .css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root": {
              paddingLeft: "0px!important",
            },
          }}
          noValidate
          autoComplete="off"
        >
          <Grid container item xs={12} md={12} lg={12} /*Email*/>
            <InputBase
              placeholder="demo@contabilium.com"
              fullWidth
              // error={error}
              helperText={errorMessage}
              id="email"
              name="name"
              InputProps={{
                classes: {
                  input: classes.formButton,
                },
              }}
              autocomplete="off"
              className={classes.formButton}
              onClick={(e) => {}}
              onChange={handleEmail}
              sx={
                error === true
                  ? {
                      border: "1px solid #ff2569",
                      height: "45px",
                      borderRadius: 1,
                    }
                  : { className: classes.input2 }
              }
            />
            {errorMessage === true && (
              <Grid>
                <Typography className={classes.validationText}>
                  {i18n.t("errorMessage")}
                </Typography>
              </Grid>
            )}
          </Grid>
        </Box>
        <Grid container items xs={12} md={12} lg={12}>
          {loading && (
            <Dialog open={loading}>
              <div
                style={{
                  padding: "20px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  backgroundColor: "transparent",
                }}
              >
                <CircularProgress
                  size={30}
                  style={{ backgroundColor: "transparent" }}
                />
              </div>
            </Dialog>
          )}
        </Grid>
        <Grid /*Button*/
          container
          item
          className={classes.BaseGrid}
          xs={12}
          md={12}
          lg={12}
          justifyContent="center"
        >
          {enabledBtn === true ? (
            <Button
              className={classes.loginBtn}
              disabled={false}
              onClick={handleChangeEmail}
            >
              <Typography className={classes.typography}>
                {i18n.t(`${"continue"}`)}
              </Typography>
            </Button>
          ) : (
            <Button className={classes.loginBtn2} disabled={true}>
              <Typography className={classes.typography}>
                {i18n.t(`${"continue"}`)}
              </Typography>
            </Button>
          )}
        </Grid>
      </Box>
    </ThemeProvider>
  );
}

export default ConfirmEmail;
