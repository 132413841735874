import React, { Component } from "react";
import { Grid, Typography, Box } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { ReactComponent as Logo } from "../assets/images/logo.svg";
import i18n from "./../locale/index";
import RecoverPass from "../components/RecoverPassForm";
//import MenuLanguageComponent from "../components/MenuLanguage";
import GoBack from "../components/GoBack";
import CanonicalLink from "../components/CanonicalLink";


const IMAGE_LOGIN_PATH = "login_bg.png";

const styles = (theme) => ({
  root: {
    justifyContent: "center",
    flexGrow: 1,
    height: "100vh",
    backgroundColor: "#FFFFFF",
  },
  grid1: {
    backgroundColor: "#FFFFFF",
    alignContent: "center",
  },
  googleLogin: {
    marginBottom: 10,
  },
  mainTitle: {
    marginTop: "4%",
  },
  line: {
    margin: 15,
  },
  lineText: {
    fontFamily: "Work Sans, sans-serif",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "15px",
    lineHeight: "18.77px",
    alignItems: "center",
    textAlign: "center",
    color: "#131313",
    marginTop: 5,
  },
  googleFont: {
    fontFamily: "Work Sans, sans-serif",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "18.77px",
    color: "#131313",
  },
  googleBtn: {
    marginTop: theme.spacing(1),
    height: 50,
    width: 380,
    textTransform: "none",
    border: "2px solid",
    borderColor: "#131313",
    borderRadius: 15,
    color: "black",
    autoComplete: "off",
    fontFamily: "Work Sans, sans-serif",
    fontWeight: "normal",
    fontSize: "16px/22px",
  },
  registerFont: {
    fontFamily: "Work Sans, sans-serif",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "16.42px",
    color: "#131313",
  },
  registerGrid: {
    marginTop: theme.spacing(2),
    alignItems: "center",
  },
  loginBtn: {
    marginTop: theme.spacing(1),
    height: 50,
    width: 380,
    textTransform: "none",
    border: "2px solid",
    borderColor: "#00C7AF",
    backgroundColor: "#00C7AF",
    borderRadius: 30,
    color: "white",
    autoComplete: "off",
    fontFamily: "Work Sans, sans-serif",
    fontWeight: "normal",
    fontSize: "16px/22px",
  },
  formCheck: {
    fontFamily: "Work Sans, sans-serif",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "18.77px",
    alignItems: "center",
    textAlign: "center",
    color: "#131313",
    marginRight: 5,
  },
  password: {
    fontFamily: "Work Sans, sans-serif",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "18.77px",
    alignItems: "center",
    textAlign: "center",
    color: "#00A490",
    cursor: "pointer",
  },
  registerColorFont: {
    fontFamily: "Work Sans, sans-serif",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "18.77px",
    alignItems: "center",
    textAlign: "center",
    color: "#00A490",
  },
  BaseGrid: {
    margin: 10,
  },
  checkGrid: {
    alignItems: "center",
  },
  topButton: {
    backgroundColor: "#FFFFFF",
    alignItems: "center",
    borderRadius: 50,
    height: 60,
    width: 140,
  },
  topMenuItem: {
    backgroundColor: "#FFFFFF",
    alignItems: "center",
    borderRadius: 50,
    height: 50,
    width: 140,
  },
  topButtonText: {
    color: "#131313",
    fontFamily: "Work Sans, sans-serif",
    fontSize: "16px",
    marginTop: 15,
    fontWeight: 400,
    textAlign: "center",
    width: 96,
  },
  topdiv: {
    position: "absolute",
    top: 20,
    right: 30,
  },
  recoverTex: {
    marginTop: theme.spacing(2),
    color: "#131313",
    fontFamily: "Work Sans, sans-serif",
    lineHeight: 1.5, 
    fontSize: "31px",
    fontWeight: 600,
    textAlign: "center",
  },
  goBack: {
    marginTop: "30px",
  },
  labelText: {
    margin: "20px 0 0px 0",
    color: "#131313",
    fontFamily: "Work Sans, sans-serif",
    fontSize: "15px",
    fontWeight: 400,
    lineHeight: 1.5,
    textAlign: "left",
  },
  backgroundImg: {
    objectFit: "cover",
    zIndex: 0,
    width: "100%",
    height: "100%",
    overFlowY: "hidden",
    left: "40px",
  },
  imgContainer:{
   // position: "fixed",
    top: 0,
    right:0,
    height: "100vh"
  }
});

class RecoverPassView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      windowWidth: window.innerWidth,
      recoverText:i18n.t("recoverText"),
    };
  }

  handleTextMulticountry = (host) => {
    let region = i18n.t("recoverText");
    switch (host) {
      case "com":
        region = i18n.t("recoverText");
        break;
      case "ar":
        region = i18n.t("recoverText");
        break;
      case "cl":
        region = i18n.t("recoverTextChUy");
        break;
      case "uy":
        region = i18n.t("recoverTextChUy");
        break;
      default:
        region =i18n.t("recoverText");
        break;
    }
    return region
  }

  handleResize = (e) => {
    this.setState({ windowWidth: window.innerWidth });
  };
  componentDidMount() {
    window.addEventListener("resize", this.handleResize);
    const extUrl = window.location.host.replace(/^.*\./, "");
    this.setState({
      recoverText:this.handleTextMulticountry(extUrl),

    })
    console.clear();
  }
  render() {
    function getWindowDimensions() {
      const { innerWidth: width } = window;
      return {
        width,
      };
    }

    const { classes } = this.props;
    const { width } = getWindowDimensions();

    const urlByCountry = () => {
      const country = localStorage.getItem("country") || "";
      const countryUrls = {
        "Argentina": "https://app.contabilium.com",
        "Chile": "https://app.contabilium.cl",
        "Uruguay": "https://app.contabilium.uy"
      };
      return countryUrls[country] || "https://app.contabilium.com";
    };

    return (
      <div style={{ backgroundColor: "#E5E5E5" }}>
        <CanonicalLink position={5} href={urlByCountry() + "/v2/login/recoverpass"} />
        <Grid
          container
          className={classes.root}
          component="main"
          maxWidth="sm"
          alignContent="middle"
        >
          <Grid
            container
            item
            className={classes.grid1}
            xs={width >= 600 ? 6 : 12}
            md={width >= 600 ? 6 : 12}
            lg={width >= 600 ? 6 : 12}
            maxWidth="md"
          >
            <Grid
              container
              item
              className={classes.mainTitle}
              xs={12}
              md={12}
              lg={12}
              justifyContent="center"
            >
              <Logo />
            </Grid>
            <Grid
              container
              item
              className={classes.googleLogin}
              xs={12}
              md={12}
              lg={12}
              justifyContent="center"
              maxWidth="sm"
            >
              <Box
                sx={{
                  width: "90vw",
                  maxWidth: "380px",
                  margin: "0",
                }}
              >
                <Typography className={classes.recoverTex}>
                  {i18n.t("recoverPass")}
                </Typography>
                <Typography className={classes.labelText}>
                  {this.state.recoverText}
                </Typography>
              </Box>
            </Grid>

            <Grid
              container
              item
              className={classes.BaseGrid}
              xs={12}
              md={12}
              lg={12}
              justifyContent="center"
            >
              <RecoverPass texto="recoverText" />
            </Grid>

            <Grid
              container
              item
              className={classes.goBack}
              justifyContent="center"
            >
              <GoBack text="goBack" link={process.env.REACT_APP_LOGIN} />
            </Grid>
          </Grid>
          {width >= 600 ? (
            <Grid
              container
              item
              xs={6}
              md={6}
              lg={6}
              className={classes.imgContainer}
            >
              <img
                src={process.env.REACT_APP_BASE_URL_IMAGES + IMAGE_LOGIN_PATH}
                alt="computer_man"
                className={classes.backgroundImg}
              />

              <div className={classes.topdiv}>
                {/*<MenuLanguageComponent />*/}
              </div>
            </Grid>
          ) : (
            <Grid
              container
              item
              xs={12}
              md={12}
              lg={12}
              justifyContent="center"
            >
              {/*<MenuLanguageComponent />*/}
            </Grid>
          )}
        </Grid>
      </div>
    );
  }
}
export default withStyles(styles, { withTheme: true })(RecoverPassView);
